/*
*** AUTO-GENERATED ***

This file has been generated by a code generator. Please do not manually modify this file.
To make changes, modify the templates in the code generator.

*/

import { Box, LinearProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useAppState } from 'src/hooks/useAppState'
import { useGetInspectionsCherryByIdsQuery } from 'src/utils/__generated__/graphql'

interface Props {
  inspectionIds: number[]
}

export const InspectionsCherry = ({
  inspectionIds
}: Props) => {
  const { t } = useTranslation()
  const { seasonId } = useAppState()  
  const { loading, error, data } = useGetInspectionsCherryByIdsQuery({
    variables: {
      seasonId: seasonId,
      where: {or:
        inspectionIds.map((id) => ({id: {eq: id}}) )
      }
    }
  })

  const renderDetail = (value, key) => (
    <Box 
      key={key}
      sx={{minHeight:'1rem', 
        overflow: 'hidden',
        borderBottom: '1px solid',
        borderColor: 'rowDivider.main'
      }}
    >
      {value}
    </Box>
  )

  if (loading) return (<div><LinearProgress /></div>)
  if (error) return (<p>Error: {JSON.stringify(error)}</p>)

  return (          
    <Box>            
      <Box 
        sx={{display: 'grid',
          minWidth: '16rem',
          //width: 'max-content',
          width: `${(inspectionIds?.length * 6 ?? 1) + 10}rem`,                            
          gridAutoFlow: 'column',
          gridTemplateRows: `repeat(48, min-content)`,
          gridGap: '0px'
        }}
      >                
        {renderDetail(t('InspGrowerCode', 'InspGrowerCode'), 'InspGrowerCode')}
        {renderDetail(t('InspPackDate', 'InspPackDate'), 'InspPackDate')}
        {renderDetail(t('InspSize', 'InspSize'), 'InspSize')}
        {renderDetail(t('InspPackCode', 'InspPackCode'), 'InspPackCode')}
        {renderDetail(t('Count', 'Count'), 'Count')}
        {renderDetail(t('PLU', 'PLU'), 'PLU')}
        {renderDetail(t('PLUPER', 'PLUPER'), 'PLUPER')}
        {renderDetail(t('CountryOfOrigin', 'CountryOfOrigin'), 'CountryOfOrigin')}
        {renderDetail(t('Weight', 'Weight'), 'Weight')}
        {renderDetail(t('UnderweightMin', 'UnderweightMin'), 'UnderweightMin')}
        {renderDetail(t('UnderweightMax', 'UnderweightMax'), 'UnderweightMax')}
        {renderDetail(t('WeighedUnits', 'WeighedUnits'), 'WeighedUnits')}
        {renderDetail(t('UnderweightUnits', 'UnderweightUnits'), 'UnderweightUnits')}
        {renderDetail(t('UnderweightPER', 'UnderweightPER'), 'UnderweightPER')}
        {renderDetail(t('PulpTemp', 'PulpTemp'), 'PulpTemp')}
        {renderDetail(t('Opening', 'Opening'), 'Opening')}
        {renderDetail(t('SizeMin', 'SizeMin'), 'SizeMin')}
        {renderDetail(t('SizeMax', 'SizeMax'), 'SizeMax')}
        {renderDetail(t('SizeMost', 'SizeMost'), 'SizeMost')}
        {renderDetail(t('Color', 'Color'), 'Color')}
        {renderDetail(t('ScarsMarksPieces', 'ScarsMarksPieces'), 'ScarsMarksPieces')}
        {renderDetail(t('ScarsMarksPER', 'ScarsMarksPER'), 'ScarsMarksPER')}
        {renderDetail(t('ScarsMarksDEG', 'ScarsMarksDEG'), 'ScarsMarksDEG')}
        {renderDetail(t('MisshapenPieces', 'MisshapenPieces'), 'MisshapenPieces')}
        {renderDetail(t('MisshapenPER', 'MisshapenPER'), 'MisshapenPER')}
        {renderDetail(t('StemlessPieces', 'StemlessPieces'), 'StemlessPieces')}
        {renderDetail(t('StemlessPER', 'StemlessPER'), 'StemlessPER')}
        {renderDetail(t('SplitsPieces', 'SplitsPieces'), 'SplitsPieces')}
        {renderDetail(t('SplitsPER', 'SplitsPER'), 'SplitsPER')}
        {renderDetail(t('SplitsDEG', 'SplitsDEG'), 'SplitsDEG')}
        {renderDetail(t('Firmness', 'Firmness'), 'Firmness')}
        {renderDetail(t('PittingBruisingPieces', 'PittingBruisingPieces'), 'PittingBruisingPieces')}
        {renderDetail(t('PittingBruisingPER', 'PittingBruisingPER'), 'PittingBruisingPER')}
        {renderDetail(t('PittingBruisingDEG', 'PittingBruisingDEG'), 'PittingBruisingDEG')}
        {renderDetail(t('StemDehydrationPieces', 'StemDehydrationPieces'), 'StemDehydrationPieces')}
        {renderDetail(t('StemDehydrationPER', 'StemDehydrationPER'), 'StemDehydrationPER')}
        {renderDetail(t('StemDehydrationDEG', 'StemDehydrationDEG'), 'StemDehydrationDEG')}
        {renderDetail(t('DehydrationPieces', 'DehydrationPieces'), 'DehydrationPieces')}
        {renderDetail(t('DehydrationPER', 'DehydrationPER'), 'DehydrationPER')}
        {renderDetail(t('DehydrationDEG', 'DehydrationDEG'), 'DehydrationDEG')}
        {renderDetail(t('DecayPieces', 'DecayPieces'), 'DecayPieces')}
        {renderDetail(t('DecayPER', 'DecayPER'), 'DecayPER')}
        {renderDetail(t('DecayDEG', 'DecayDEG'), 'DecayDEG')}
        {renderDetail(t('MoldPieces', 'MoldPieces'), 'MoldPieces')}
        {renderDetail(t('MoldPER', 'MoldPER'), 'MoldPER')}
        {renderDetail(t('Brix', 'Brix'), 'Brix')}
        {renderDetail(t('OverallQ', 'OverallQ'), 'OverallQ')}
        {renderDetail(t('OverallC', 'OverallC'), 'OverallC')}

        {data?.inspections?.nodes?.map((inspection, i) => {
          return (
            <>
              {renderDetail(inspection.inspectionDetail['inspGrowerCode'], `${inspection.id}-inspGrowerCode`)}
              {renderDetail(inspection.inspectionDetail['inspPackDate'], `${inspection.id}-inspPackDate`)}
              {renderDetail(inspection.inspectionDetail['inspSize'], `${inspection.id}-inspSize`)}
              {renderDetail(inspection.inspectionDetail['inspPackCode'], `${inspection.id}-inspPackCode`)}
              {renderDetail(inspection.inspectionDetail['count'], `${inspection.id}-count`)}
              {renderDetail(inspection.inspectionDetail['pLU'], `${inspection.id}-pLU`)}
              {renderDetail(inspection.inspectionDetail['pLUPER'], `${inspection.id}-pLUPER`)}
              {renderDetail(inspection.inspectionDetail['countryOfOrigin'], `${inspection.id}-countryOfOrigin`)}
              {renderDetail(inspection.inspectionDetail['weight'], `${inspection.id}-weight`)}
              {renderDetail(inspection.inspectionDetail['underweightMin'], `${inspection.id}-underweightMin`)}
              {renderDetail(inspection.inspectionDetail['underweightMax'], `${inspection.id}-underweightMax`)}
              {renderDetail(inspection.inspectionDetail['weighedUnits'], `${inspection.id}-weighedUnits`)}
              {renderDetail(inspection.inspectionDetail['underweightUnits'], `${inspection.id}-underweightUnits`)}
              {renderDetail(inspection.inspectionDetail['underweightPER'], `${inspection.id}-underweightPER`)}
              {renderDetail(inspection.inspectionDetail['pulpTemp'], `${inspection.id}-pulpTemp`)}
              {renderDetail(inspection.inspectionDetail['opening'], `${inspection.id}-opening`)}
              {renderDetail(inspection.inspectionDetail['sizeMin'], `${inspection.id}-sizeMin`)}
              {renderDetail(inspection.inspectionDetail['sizeMax'], `${inspection.id}-sizeMax`)}
              {renderDetail(inspection.inspectionDetail['sizeMost'], `${inspection.id}-sizeMost`)}
              {renderDetail(inspection.inspectionDetail['color'], `${inspection.id}-color`)}
              {renderDetail(inspection.inspectionDetail['scarsMarksPieces'], `${inspection.id}-scarsMarksPieces`)}
              {renderDetail(inspection.inspectionDetail['scarsMarksPER'], `${inspection.id}-scarsMarksPER`)}
              {renderDetail(inspection.inspectionDetail['scarsMarksDEG'], `${inspection.id}-scarsMarksDEG`)}
              {renderDetail(inspection.inspectionDetail['misshapenPieces'], `${inspection.id}-misshapenPieces`)}
              {renderDetail(inspection.inspectionDetail['misshapenPER'], `${inspection.id}-misshapenPER`)}
              {renderDetail(inspection.inspectionDetail['stemlessPieces'], `${inspection.id}-stemlessPieces`)}
              {renderDetail(inspection.inspectionDetail['stemlessPER'], `${inspection.id}-stemlessPER`)}
              {renderDetail(inspection.inspectionDetail['splitsPieces'], `${inspection.id}-splitsPieces`)}
              {renderDetail(inspection.inspectionDetail['splitsPER'], `${inspection.id}-splitsPER`)}
              {renderDetail(inspection.inspectionDetail['splitsDEG'], `${inspection.id}-splitsDEG`)}
              {renderDetail(inspection.inspectionDetail['firmness'], `${inspection.id}-firmness`)}
              {renderDetail(inspection.inspectionDetail['pittingBruisingPieces'], `${inspection.id}-pittingBruisingPieces`)}
              {renderDetail(inspection.inspectionDetail['pittingBruisingPER'], `${inspection.id}-pittingBruisingPER`)}
              {renderDetail(inspection.inspectionDetail['pittingBruisingDEG'], `${inspection.id}-pittingBruisingDEG`)}
              {renderDetail(inspection.inspectionDetail['stemDehydrationPieces'], `${inspection.id}-stemDehydrationPieces`)}
              {renderDetail(inspection.inspectionDetail['stemDehydrationPER'], `${inspection.id}-stemDehydrationPER`)}
              {renderDetail(inspection.inspectionDetail['stemDehydrationDEG'], `${inspection.id}-stemDehydrationDEG`)}
              {renderDetail(inspection.inspectionDetail['dehydrationPieces'], `${inspection.id}-dehydrationPieces`)}
              {renderDetail(inspection.inspectionDetail['dehydrationPER'], `${inspection.id}-dehydrationPER`)}
              {renderDetail(inspection.inspectionDetail['dehydrationDEG'], `${inspection.id}-dehydrationDEG`)}
              {renderDetail(inspection.inspectionDetail['decayPieces'], `${inspection.id}-decayPieces`)}
              {renderDetail(inspection.inspectionDetail['decayPER'], `${inspection.id}-decayPER`)}
              {renderDetail(inspection.inspectionDetail['decayDEG'], `${inspection.id}-decayDEG`)}
              {renderDetail(inspection.inspectionDetail['moldPieces'], `${inspection.id}-moldPieces`)}
              {renderDetail(inspection.inspectionDetail['moldPER'], `${inspection.id}-moldPER`)}
              {renderDetail(inspection.inspectionDetail['brix'], `${inspection.id}-brix`)}
              {renderDetail(inspection.inspectionDetail['overallQ'], `${inspection.id}-overallQ`)}
              {renderDetail(inspection.inspectionDetail['overallC'], `${inspection.id}-overallC`)}
            </>
          )
        })}
      </Box>

    </Box>    
  )
}