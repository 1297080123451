import * as React from 'react'
import { Alert, Box, Button, Card, CardActions, CardContent, Divider, Grid, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Download } from '@mui/icons-material'
import { Link as RouterLink } from 'react-router-dom'

import { SelectedFilters } from 'src/typeDefinitions/appTypes'
import { Filters } from 'src/components/Filters'
import { Pagination } from 'src/components/Pagination'
import { useGetHostedReportFilterOptionsQuery, useGetAllHostedReportsQuery, useDownloadFileMutation, FileType } from 'src/utils/__generated__/graphql'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAppState } from 'src/hooks/useAppState'
import { useHostedReportQueryFilter } from './useHostedReportQueryFilter'
import { useConvertRawHostedReportFiltersToFilterOptions } from './useConvertRawHostedReportFiltersToFilterOptions'
import { ErrorAlert } from 'src/components/ErrorAlert'


export const HostedReportsRoute = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { seasonId } = useAppState()
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters>({
    locations: [],
    importers: [],
    exporters: [],
    arrivalNames: [],
    containers: [],
    lotCodes: [],
    pos: [],
    growers: [],
    statuses: [],
    programs: [],
  })   
  const { variables, filterOptionsVariables, maxPageSize, setPageFilter } = useHostedReportQueryFilter(selectedFilters, seasonId)
  const { loading, error, data } = useGetAllHostedReportsQuery({
    variables: variables
  })
  const { loading: loadingFilters, error: filterError, data: filterData } = useGetHostedReportFilterOptionsQuery({
    variables: filterOptionsVariables
  })
  const filtersOptions = useConvertRawHostedReportFiltersToFilterOptions(filterData)  
  const [downloadFile, {loading: loadingDownloadFile, error: downloadFileError}] = useDownloadFileMutation()

  const handleFiltersSelected = (newSelectedFilters) => {
    setPageFilter({
      last: null,
      before: null,
      first: maxPageSize,
      after: null
    })
    setSelectedFilters(newSelectedFilters)
  }

  const handleDownloadSpreadsheetClick = async (fileType: FileType) => {
    const result = 
      await downloadFile({variables: {
        input: {
          fileType,
          filter: { hostedReportFilters: filterOptionsVariables.filterInput }
        }
      }})
      
      if (!result?.data?.downloadFile?.downloadRequest?.id) {
        // Request failed, we don't need to do anything here as error will be available on hook.
      }
      else {
        navigate('/account/my-downloads')
      }
  }
  
  return (
    <>
    
      <Paper
        elevation={0}
        sx={{ p: '1rem' }}
      >
        <Stack sx={{background: 'mainBackground.main'}}>
          <Stack spacing={2} sx={{paddingBottom: 2}}>
            <Typography variant='h6' sx={{flexGrow: 2}}>{ t('HostedReports', 'Hosted reports') }</Typography>
          </Stack>

          <Filters 
            loading={loadingFilters}
            filtersOptions={filtersOptions} 
            filterUiData={[{
              title: t('Location', 'Location'),
              filterType: 'locations',
              filterComponentType: 'Standard'
            }, {
              title: t('Importer', 'Importer'),
              filterType: 'importers',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Exporter', 'Exporter'),
              filterType: 'exporters',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Arrival', 'Arrival'),
              filterType: 'arrivalNames',
              filterComponentType: 'Standard'
            }, {
              title: t('Container', 'Container'),
              filterType: 'containers',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('LotCodeFilter', 'LotCodeFilter'),
              filterType: 'lotCodes',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: 'PO',
              filterType: 'pos',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Grower', 'Grower'),
              filterType: 'growers',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: 'Status',
              filterType: 'statuses',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: 'Program',
              filterType: 'programs',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }]}        
            selectedFilters={selectedFilters}
            onFiltersSelected={handleFiltersSelected}
          />
        </Stack>

      </Paper>
      
      <Paper
        elevation={0}
        sx={{ p: '1rem', backgroundColor: 'baseBackground.main', minHeight: '100vh' }}
      >
        
        { loading ? <LinearProgress />
        : !!error ? <ErrorAlert error={error} /> :
        <>
          <Typography variant='caption'> { t('Results', 'Results') }: {data?.hostedReports?.totalCount ?? 0}</Typography>

          <Box sx={{display: 'flex', justifyContent: 'center'}}>
          <Stack spacing={2}>
            {data?.hostedReports?.nodes?.map((data) => (
              <Card sx={{ minWidth: 275, maxWidth: 1000 }} key={data?.hostedReportLinkId} >
                <Box sx={{ display: 'flex', flexDirection: {xs: 'column', sm: 'row'} }}>
                  
                  <Box sx={{width: '100%'}}>
                    <CardContent>
                      
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <Box >
                          <Typography component="div" variant="h5">
                            {data?.reference}
                          </Typography>

                          <Typography 
                            component="div" 
                            variant="h6" 
                            sx={{
                              color: 
                                data?.hostedReportStatus === 'Approved' ? 'success.main' :
                                data?.hostedReportStatus === 'Rejected' ? 'error.main' : 'black'
                            }}
                          >
                            {data?.hostedReportStatus}
                          </Typography>

                          <Typography component="div" mt={2}>
                            <i>{data?.location?.locationName}</i>
                          </Typography>
                          
                          <Typography component="div">
                            {data?.arrivalName}
                          </Typography>

                          <Typography component="div">
                            {data?.hostedReportProgram}
                          </Typography>

                          

                        </Box>
                        <Box ml={2}>
                          <Typography component="div" variant="h6" sx={{textAlign: 'right'}}>
                            Qty. {data?.totalQty}
                          </Typography>

                          <Typography component="div" variant="caption" sx={{textAlign: 'right'}}>
                            {data?.createdOn?.slice(0, 10)}
                          </Typography>

                        </Box>
                      </Box>
                      
                    </CardContent>
                    
                    <Divider/>

                    <CardActions>

                      <Stack sx={{flexGrow: 1, justifyContent: 'center'}} direction='row'>                                         
                        <Button 
                          variant='text' 
                          sx={{fontSize: '1.5rem'}}
                          component={RouterLink}
                          to={`/destination/hosted-report/${data?.websiteId}/${data?.hostedReportLinkId}`}
                        >
                          { t('ViewReport', 'View report') }
                        </Button>
                      </Stack>

                    </CardActions>

                  </Box>
                </Box>
             </Card>
            ))}
          </Stack>
          </Box>


          
          <Pagination 
            maxPageSize={maxPageSize}
            totalPages={Math.ceil((data?.hostedReports?.totalCount ?? 0) / maxPageSize)}
            pageInfo={data?.hostedReports?.pageInfo}
            setPageFilter={setPageFilter}
          />

          { downloadFileError?.graphQLErrors != null &&
            <Alert severity="error">
              { t('Error', 'Error') }: {downloadFileError?.graphQLErrors?.map(e=>e.message)}
            </Alert>
          }

          <Stack mt={6} mb={6} direction={'row'} spacing={2}>
            <Button 
              variant='outlined'                 
              startIcon={<Download />} 
              size='large'
              disabled={loadingDownloadFile  || (data?.hostedReports?.nodes?.length ?? 0) === 0}
              onClick={()=>{handleDownloadSpreadsheetClick(FileType.HostedReportSpreadsheet)}}
            >            
              { t('DownloadSpread', 'Download spreadsheet (XLSX)') }           
            </Button>
          </Stack>
            
        </>
        }  
      </Paper>
    </>
  )
}
