import React from 'react'
import { useNavigate  } from 'react-router-dom'
import { Box, Button, Link, Stack, Tooltip } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { useTranslation } from 'react-i18next'
import { Download } from '@mui/icons-material'

import { OverallQCPieChart, groupOverallQCIntoTotals, getOverallQualityConditionLegend } from 'src/components/OverallQCPieChart'
import { FileType, InspectionFiltersInput, InspectionGroup, useDownloadFileMutation } from 'src/utils/__generated__/graphql'
import { InspectionsDetailsExpanded } from 'src/_generated/InspectionsDetailsExpanded'
import { InspectionGroupPictures } from './InspectionGroupPictures'

interface Props {
  inspectionGroup?: InspectionGroup,
  inspectionFilters: InspectionFiltersInput
}

const headers = [
  'overallQ',
  'overallC'
]

export const InspectionsDetails = ({
  inspectionGroup,
  inspectionFilters
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [downloadFile, {loading: loadingDownloadFile, error: downloadFileError}] = useDownloadFileMutation()
  const [expandedState, setExpandedState] = React.useState<'summary' | 'expanded'>('summary')
  const inspections = inspectionGroup?.inspections

  const handleSeeMoreClick = () => {
    setExpandedState('expanded')    
  }

  const handleCollapseClick = () => {
    setExpandedState('summary')    
  }

  const handleDownloadClick = async (fileType: FileType) => {
    
    const filters = combineFilters()
    
    const result = 
      await downloadFile({variables: {
        input: {
          fileType,
          filter: { inspectionFilters: filters }
        }
      }})
      
      if (!result?.data?.downloadFile?.downloadRequest?.id) {
        // Request failed, we don't need to do anything here as error will be available on hook.
      }
      else {
        navigate('/account/my-downloads')
      }
  }

  const combineFilters = () => {
    let temp = {}
    
    temp.seasonId = inspectionGroup.seasonId
    temp.importerIds = [inspectionGroup.arrivalImpExp.impExp.importerId]   
    temp.exporterIds = [inspectionGroup.arrivalImpExp.impExp.exporterId]
    temp.arrivalFilters = [{ 
      arrivalId: inspectionGroup.arrivalImpExp.arrivalId, 
      arrivalCode: inspectionGroup.arrivalImpExp.arrivalCode 
    }]
    temp.commodityIds = [inspectionGroup.varietyImpExp.variety.commodityId]
    temp.varietyIds = [inspectionGroup.varietyImpExp.varietyId]
    
    if (!!inspectionGroup?.growerImpExp?.growerId)
      temp.growerIds = [inspectionGroup.growerImpExp.growerId]
    
    //temp.importerIds.concat(inspectionGroup.arrivalImpExp.impExp.importerId)    
    //temp = {...inspectionFilters}
    
    return temp
  }

  const renderDownload = () => {
    return ( 
      <Button 
        variant='text'                 
        startIcon={<Download />} 
        size='large'
        disabled={false}
        onClick={() => {
          handleDownloadClick(FileType.InspectionSpreadsheet)
        }}
      >            
        { t('DownloadSpread', 'Download spreadsheet (XLSX)') }
      </Button>    
    )
  }

  return (    
    <Box sx={{  backgroundColor: 'mainBackground.main' }} key={inspectionGroup.id}>
      
      <Box sx={{fontSize: '.8rem', fontWeight: 700, textAlign: 'center', marginTop: '.5rem'}}>
        <Box component='span' sx={{fontSize: '.8rem', fontWeight: 300, textAlign: 'center', marginTop: '.5rem'}}>
          {inspectionGroup?.arrivalImpExp?.arrival?.generalWorkingDate?.substring(0, 10)}
        </Box>
        {' '}
        {inspectionGroup?.arrivalImpExp?.arrival?.arrivalName} {' - '}
        {inspectionGroup?.arrivalImpExp?.impExp?.importer?.importerName} {' - '}
        {inspectionGroup?.arrivalImpExp?.impExp?.exporter?.exporterName}
      </Box>

      <Box sx={{fontSize: '1.2rem', fontWeight: 500, textAlign: 'center', marginTop: '.5rem'}}>
        { 
          t(
            `Commodity-${ inspectionGroup?.varietyImpExp?.variety?.commodity?.commodityName }`,
            inspectionGroup?.varietyImpExp?.variety?.commodity?.commodityName
          )
        } {' - '}
        {inspectionGroup?.varietyImpExp?.variety?.varietyName} 
        
        {inspectionGroup?.growerImpExp?.growerCode &&        
          <Box component='span' sx={{fontSize: '1rem', fontWeight: 300, textAlign: 'center', marginTop: '.5rem'}}>
            {' - '} {inspectionGroup?.growerImpExp?.growerCode}        
          </Box>
        }
      </Box>
      
      <Stack direction='row' justifyContent='center'>
        <OverallQCPieChart totals={ groupOverallQCIntoTotals(inspections?.map(v => v.overallQ), 'OverallQ') } />
        <OverallQCPieChart totals={ groupOverallQCIntoTotals(inspections?.map(v => v.overallC), 'OverallC') } />
      </Stack>

      <Box 
        sx={{              
          overflowX: 'auto',
          marginLeft: '1rem',
          maxHeight: '55vh',
          backgroundColor: 'baseBackground.main'
        }}
      >   

        { expandedState === 'summary' &&          
          <Box 
            sx={{
              display: 'grid',
              minWidth: '16rem',
              //width: 'max-content',
              width: `${(inspections?.length * 6 ?? 1) + 10}rem`,
              gridAutoFlow: 'column',
              gridTemplateRows: `repeat(2, min-content)`,
              gridGap: '0px'
            }}
          >
            <Box 
              sx={{                
                minHeight:'1rem', 
                overflow: 'hidden',
                borderBottom: '1px solid',
                borderColor: 'rowDivider.main'
              }}
            >
              {t('OverallQ', 'OverallQ')}
            </Box>

            <Box 
              sx={{                
                minHeight:'1rem', 
                overflow: 'hidden',
                borderBottom: '1px solid',
                borderColor: 'rowDivider.main'
              }}
            >
              {t('OverallC', 'OverallC')}
            </Box>
            
            {inspections?.map((inspection) => {
              return (
                <>

                  <Box                       
                    sx={{
                      minHeight:'1rem', 
                      overflow: 'hidden',
                      borderBottom: '1px solid',
                      borderColor: 'rowDivider.main'
                    }}
                  >
                    { Boolean(inspection.overallQ) && 
                      <Tooltip 
                        key={ `OverallQ-${inspection.id}` }
                        title={ t( getOverallQualityConditionLegend(inspection.overallQ, 'OverallQ') ) }
                      >    
                        <span>{inspection.overallQ}</span>
                      </Tooltip>
                    }
                  </Box>
                  
                  
                  <Box
                    key={ `OverallC-${inspection.id}` }                     
                    sx={{                    
                      minHeight:'1rem', 
                      overflow: 'hidden',
                      borderBottom: '1px solid',
                      borderColor: 'rowDivider.main'
                    }}
                  >
                    { Boolean(inspection.overallC) && 
                      <Tooltip 
                        key={ `OverallC-${inspection.id}` }
                        title={ t( getOverallQualityConditionLegend(inspection.overallC, 'OverallC') ) }
                      >
                        <span>{inspection.overallC} </span>
                      </Tooltip>
                    }
                  </Box>
                  
                </>
              )
            })}
          </Box>
        }
       
        <Box sx={{paddingTop: '.5rem', paddingBottom: '1rem'}}>
          { expandedState === 'summary' && 
            <>
              <Link 
                sx={{
                  display: 'flex',
                  cursor: 'pointer'
                }}
                onClick={handleSeeMoreClick} 
              >
                <KeyboardArrowDownIcon/>{ t('SeeMore', 'See more') }
              </Link>

              { renderDownload() }
            </>
          }

          { expandedState === 'expanded' &&
            <>              
              <InspectionsDetailsExpanded 
                programName={inspectionGroup?.varietyImpExp?.variety?.commodity?.program?.programName}
                inspectionIds={inspections.map(i => i.id)} 
              />
              
              <Link 
                sx={{
                  display: 'flex',
                  cursor: 'pointer'
                }}
                onClick={handleCollapseClick} 
              >
                <KeyboardArrowUpIcon/>{ t('Collapse', 'Collapse') }
              </Link>

              { renderDownload() }

            </>
          }
        </Box>

      </Box>

      <InspectionGroupPictures palletIds={inspectionGroup?.inspections?.map(i => i.palletId) ?? []}/>
      
    </Box>
  )
}