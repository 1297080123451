import React from 'react'

export type ThemeColorMode = 'light' | 'dark'

type Settings = {
  psaV2ApiUrl,
  authAuthority,
  authClientId,
  authRedirectUri,
  authPostLogoutRedirectUri,
  authResponseType,
  authScope
}

interface AppState {
  themeColorMode: ThemeColorMode,
  seasonId: number | null,
  settings: Settings
}

interface AppStateContext extends AppState {
  toggleThemeColorMode: () => void,
  notifySeasonChanged: (id: number) => void,
  setSettings: (settings: any) => void  
}


const AppStateContext = React.createContext<AppStateContext>(null)

const init = (initialState) => {
  
  let draftState = {...initialState}

  restoreLocalStorage(
    [
      'themeColorMode',
      'seasonId'
    ], 
    draftState
  )
  
  return draftState
}

const restoreLocalStorage = (keys: string[], draftState: AppState) => {
  for (let key of keys) {
    const value = window?.localStorage?.getItem(key)
    if (value)
      draftState[key] = JSON.parse(value)
  }
}

function appStateReducer(state: AppState, action) {  
  switch (action.type) {
    case 'toggle_theme_color_mode': {
      return {...state, themeColorMode: state.themeColorMode === 'light' ? 'dark' : 'light' }
    }
    case 'season_changed': {
      return {...state, seasonId: action.payload}
    }
    case 'set_settings': {
      return {...state, settings: action.payload}
    }    
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

export function AppStateProvider({children}) {
  const [state, dispatch] = React.useReducer(appStateReducer, {
      themeColorMode: 'light',
      seasonId: null,
      settings: null      
    } as AppState, init)
  
  const toggleThemeColorMode = () => {    
    window?.localStorage?.setItem('themeColorMode', JSON.stringify(state.themeColorMode === 'light' ? 'dark' : 'light'))
    dispatch({type: 'toggle_theme_color_mode'})
  }

  const notifySeasonChanged = (id: number) => {    
    window?.localStorage?.setItem('seasonId', JSON.stringify(id))
    dispatch({type: 'season_changed', payload: id})
  }

  const setSettings = (s: any) => {    
    dispatch({type: 'set_settings', payload: s})
  }

  const value = {
    themeColorMode: state.themeColorMode, 
    seasonId: state.seasonId,
    settings: state.settings,
    toggleThemeColorMode,
    notifySeasonChanged,
    setSettings        
  } as AppStateContext
 
  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>
}

export const useAppState = () => {
  const context = React.useContext(AppStateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within an AppStateProvider')
  }
  return context
}