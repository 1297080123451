import React from 'react'
import { AppBar, Box, Button, Divider, Grid, Stack, Theme, Toolbar, useMediaQuery, useTheme } from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { FilterUiData } from 'src/typeDefinitions/appTypes'
import { FilterInline } from './FilterInline'
import { Filter } from './Filter'
import { FilterCommodityVariety } from './FilterCommodityVariety'
import { useWindowDimensions } from 'src/hooks/useWindowDimensions'

interface Props {
  filterUiData: FilterUiData[],
  filterOptions?: string[],
  selectedFilters: any,
  onDone?: (draftFilters: Object) => void,
}

export const FilterMore = ({
  filterUiData,
  filterOptions,
  selectedFilters,
  onDone
}: Props) => {
  const [openFilter, setOpenFilter] = React.useState<FilterUiData | undefined>()
  const theme = useTheme<Theme>()
  const { width }  = useWindowDimensions()
  const [filterUiDataAdjustedForScreen] = React.useState(()=>
    filterUiData.filter(f => theme.breakpoints.values[f.screenSize] > width)
  )
  
  const handleClose = () => {
    //todo cancel
  }
  
  return (
    <Box sx={{maxHeight: '100vh'}}>

      <AppBar color='transparent' sx={{ boxShadow: 2 }}>
        <Toolbar sx={{fontWeight: 500}}>
          { !openFilter 
          ?
            'Select filters'
          :
            openFilter.title
          }
        </Toolbar>
      </AppBar>
      <Toolbar />
        
      { !openFilter && 
        <Stack
          direction="column"
          justifyContent="space-around"
          spacing={2}
          divider={<Divider orientation="horizontal" flexItem />}
          sx={{paddingTop: 2, paddingBottom: 2, paddingLeft: 2}}          
        >          
          {filterUiDataAdjustedForScreen.map(fd => (                        
            <FilterButton
              title={fd.title}
              screenSize={fd.screenSize}
              onClick={()=>{setOpenFilter(fd)}}
            />            
          ))}
        </Stack>
      }

      { !!openFilter && 
        <FilterInline 
          title={openFilter.title} 
          screenSize={openFilter.screenSize}
          filterType={openFilter.filterType}
          filterOptions={
            openFilter.filterComponentType === 'Standard' ? filterOptions[openFilter.filterType]
              : openFilter.filterComponentType === 'Commodity' && filterOptions
          } 
          selectedFilters={selectedFilters}
          onDone={onDone}
          popupComponent={
            openFilter.filterComponentType === 'Standard' ? Filter
              : openFilter.filterComponentType === 'Commodity' && FilterCommodityVariety
          } 
        />        
      }
             
    </Box>
  )
}

const FilterButton = ({title, screenSize, onClick}) => {
  const theme = useTheme<Theme>()
  const visible = useMediaQuery(theme.breakpoints.down(screenSize))  
 
  if (!visible)
    return null    

  return (
    <Button 
      onClick={onClick}
      sx={{justifyContent: 'flex-start'}}
    >
      {title} 
      <KeyboardArrowDownIcon color='primary' />
    </Button>            
  )
}