import React from 'react'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'

import { RequireAuth, Role, routeRoles } from 'src/components/RequireAuth'

import { AppLayout } from 'src/routes/_app/AppLayout'
import { HomeRoute } from 'src/routes/_home/HomeRoute'
import { LoggedInHomeRoute } from 'src/routes/_home/LoggedInHomeRoute'

import { WhyPsaRoute } from 'src/routes/why-psa/WhyPsaRoute'
import { LocationsRoute } from 'src/routes/locations/LocationsRoute'
import { ContactRoute } from 'src/routes/contact/ContactRoute'

import { ArrivalReportsRoute } from 'src/routes/destination/arrival-reports/ArrivalReportsRoute'
import { ArrivalReportRoute } from 'src/routes/destination/arrival-report/ArrivalReportRoute'
import { Inspections } from 'src/routes/destination/inspections/Inspections'
import { PicturesRoute } from 'src/routes/destination/pictures/PicturesRoute'
import { TemperatureRecordersRoute } from 'src/routes/destination/temperature-recorders/TemperatureRecordersRoute'
import { HostedReportsLayoutRoute } from 'src/routes/destination/hosted-reports/HostedReportsLayoutRoute'
import { HostedReportsRoute } from 'src/routes/destination/hosted-reports/HostedReportsRoute'
import { HostedReportsInspectionDetailsRoute } from 'src/routes/destination/hosted-reports/inspection-details/HostedReportsInspectionDetailsRoute'
import { HostedReportRoute } from 'src/routes/destination/hosted-report/HostedReportRoute'
import { WarehouseOrdersRoute } from 'src/routes/destination/warehouse-orders/WarehouseOrdersRoute'
import { MyDownloadsRoute } from 'src/routes/account/my-downloads/MyDownloadsRoute'
import { NoMatch404Route } from 'src/routes/_app/NoMatch404Route'

export const AppRoutes = () => {
  const auth = useAuth()
  
  return (
    <BrowserRouter>
      <ScrollToTopOnRouteChange />

      <Routes>
      
        <Route path='/' element={<AppLayout />} >
          
          <Route index element=
            {
              Boolean(auth?.isAuthenticated) 
                ? <LoggedInHomeRoute /> 
                : <HomeRoute />
            } 
          />
                    
          <Route path='why-psa' element={<WhyPsaRoute />} />
          <Route path='locations' element={<LocationsRoute />} />
          <Route path='contact' element={<ContactRoute />} />
          
          
          <Route path='destination/arrival-reports' element={
            <RequireAuth roles={routeRoles.arrivalReports}>
              <ArrivalReportsRoute />
            </RequireAuth> 
          }/>          
          <Route path='destination/arrival-report/:websiteId/:arrivalReportLinkId' element={
            <RequireAuth roles={routeRoles.arrivalReport}>
              <ArrivalReportRoute />
            </RequireAuth>    
          } />

          <Route path='destination/inspections' element={
            <RequireAuth roles={routeRoles.inspections}>
              <Inspections />
            </RequireAuth>
          }/>

          <Route path='destination/pictures' element={
            <RequireAuth roles={routeRoles.pictures}>
              <PicturesRoute />
            </RequireAuth>
          } />
          
          <Route path='destination/temperature-recorders' element={
            <RequireAuth roles={routeRoles.temperatureRecorders}>
              <TemperatureRecordersRoute />
            </RequireAuth> 
          }/>
          
          <Route path='destination/hosted-reports' element={<HostedReportsLayoutRoute />} >
            <Route index element={
              <RequireAuth roles={routeRoles.hostedReports}>
                <HostedReportsRoute />
              </RequireAuth>
              } />
            <Route path='inspection-details' element={
              <RequireAuth roles={routeRoles.hostedReportsInspectionDetails}>
                <HostedReportsInspectionDetailsRoute />
              </RequireAuth>
            } />
          </Route>
          <Route path='destination/hosted-report/:websiteId/:hostedReportLinkId' element={
            <RequireAuth roles={routeRoles.hostedReport}>
              <HostedReportRoute />
            </RequireAuth>  
          }/>

          <Route path='destination/warehouse-orders' element={
            <RequireAuth roles={routeRoles.warehouseOrders}>
              <WarehouseOrdersRoute />
            </RequireAuth>
          }/>

          <Route path='account/my-downloads' element={
            <RequireAuth roles={routeRoles.myDownloads}>
              <MyDownloadsRoute />
            </RequireAuth>
          }/>

          <Route path="*" element={<NoMatch404Route />} />

        </Route>
      </Routes>
    </BrowserRouter>      
  )
}

const ScrollToTopOnRouteChange = () => {
  const location = useLocation()

  React.useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    })
  }, [location.pathname])

  return null
}