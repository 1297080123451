import React from 'react'
import { AppStateProvider, useAppState } from 'src/hooks/useAppState'
import { AuthProvider, AuthProviderProps } from 'react-oidc-context'
import { AppThemeProvider } from 'src/AppThemeProvider'
import { ApolloClient, createHttpLink, InMemoryCache, ApolloProvider } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { useAuth } from 'react-oidc-context'
import { User } from 'oidc-client-ts'
import axios from 'axios'

/////////////////////////////////////////////////////////////////////////////////////////////
// This file stores all the context providers.
// If a provider needs extra code for configuration, 
// they will be defined in their own wrapper component at bottom of file.
// This will keep the main component clean and easy to understand the order of providers.
// This wrapper pattern also keeps the configuration with that specific provider.
/////////////////////////////////////////////////////////////////////////////////////////////

type AppProvidersProps = {
  children?: React.ReactNode | undefined
}

export const AppProviders = ({
  children
}: AppProvidersProps) => {
  return (
    <AppStateProvider>          
      <SettingsInitializer>
        <AuthProviderWrapper>
          <ApolloProviderWrapper>
            <AppThemeProvider>
              {children}
            </AppThemeProvider>          
          </ApolloProviderWrapper>
        </AuthProviderWrapper>
      </SettingsInitializer>
    </AppStateProvider>
  )
}


type SettingsInitializerProps = {
  children?: React.ReactNode | undefined
}

const SettingsInitializer = ({children}: SettingsInitializerProps) => {
  const { settings, setSettings } = useAppState()

  React.useEffect(() => {
    const f = async ()=> {
      const result = await axios.get("/settings.json")      
      setSettings(result.data)
    }
    f()
  }, [])
  if (!settings) return <div>Please wait while the app loads...</div>

  return <>{children}</>
}

type AuthProviderWrapperProps = {
  children?: React.ReactNode | undefined
}

const AuthProviderWrapper = ({children}: AuthProviderWrapperProps) => {
  const { settings } = useAppState()

  const oidcConfig: AuthProviderProps = {
    authority: settings.authAuthority,
    client_id: settings.authClientId,
    redirect_uri: settings.authRedirectUri,
    post_logout_redirect_uri: settings.authPostLogoutRedirectUri,
    response_type: settings.authResponseType,
    scope: settings.authScope,
  }

  const handleSigninCallback = (_user: User | void): void => {
    
    //todo: refactor with react router navigate
    //     and fetch required global state (season info) before navigating
    
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname
    )
  }
  
  return (
    <AuthProvider {...oidcConfig} onSigninCallback={handleSigninCallback}>
      {children}
    </AuthProvider>
  )
}


type ApolloProviderWrapperProps = {
  children?: React.ReactNode | undefined
}

const ApolloProviderWrapper = ({children}: ApolloProviderWrapperProps) => {
  const auth = useAuth()
  const { settings } = useAppState()

  const httpLink = createHttpLink({
    uri: settings.psaV2ApiUrl + 'graphql/',
  })
  
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = auth.user?.access_token
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  })
  
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  })
   
  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}
