import React from 'react'
import { Button, Stack } from '@mui/material'

import { FiltersSelectedChips, FiltersSelectedChipsProps } from 'src/components/FiltersSelectedChips'

interface Props extends FiltersSelectedChipsProps {
  onResetFilters: () => void
}

export const FiltersSelectedExpanded = ({
  flatSelectedFilters,
  onDeleteFilter,
  onResetFilters
}: Props) => {
  return (
   <Stack direction='row'>   
    <FiltersSelectedChips {...{flatSelectedFilters}} {...{onDeleteFilter}}  />

    <Button onClick={onResetFilters}>Reset filters</Button>
   </Stack>
  )
}