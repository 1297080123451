import * as React from 'react'
import Box from '@mui/material/Box';
import { Alert, Container, IconButton, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Download, Error, InsertDriveFile } from '@mui/icons-material'

import { useAppState } from 'src/hooks/useAppState'
import { Status, useGetMyDownloadsQuery } from 'src/utils/__generated__/graphql'
import { useTranslation } from 'react-i18next'

export const MyDownloadsRoute = () => {
  const { settings } = useAppState()
  const { t } = useTranslation()
  const { loading, error, data } = useGetMyDownloadsQuery({fetchPolicy: 'cache-and-network', pollInterval: 30000,})

  const handleDownload = (filename) => {
    const link = document.createElement("a");
    link.download = filename
    let path = 'destination/file/'    
    link.href = settings.psaV2ApiUrl + path + filename
    link.click();
  }

  return (
    <Container maxWidth='xl'>
    
      <Paper
        elevation={0}
        sx={{ p: '1rem' }}
      >
        <Stack  sx={{background: 'mainBackground.main'}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent='space-between' sx={{paddingBottom: 2}} >
          
            <Typography variant='h6' sx={{flexGrow: 2}}>
              { t('MyDownloads', 'My downloads') }
            </Typography>

          </Stack>    
        </Stack>

      </Paper>
      
      <Paper
        elevation={0}
        sx={{ p: '1rem', backgroundColor: 'baseBackground.main' }}
      >
        { loading ? <LinearProgress />
        : Boolean(error) ? <p>{ t('Error', 'Error') }: {JSON.stringify(error.message)}</p> :
        <>
          <Alert severity="info">
            { t('MyDownloadsLimitedTime', 'These files will only be available for a limited time. Please transfer to your computer as soon as possible.') }
          </Alert>

          <TableContainer component={Paper}>
            <Table sx={{ maxWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>                  
                  <TableCell>{ t('FileType', 'File type') }</TableCell>
                  <TableCell>{ t('Status', 'Status') }</TableCell>                  
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.downloadRequests?.nodes?.map(data => (
                  <TableRow
                    key={data.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{ t(`FileType-${data.fileType}`, data.fileType) }</TableCell>
                    <TableCell>{ t(`DownloadStatus-${data.status}`, data.status) }</TableCell>                    
                    <TableCell align='right'>

                      {data.status == Status.Queued || data.status == Status.Processing ?
                          <LinearProgress />
                      :                  
                        <IconButton aria-label="delete" disabled={data.status != Status.ReadyForDownload}>                      
                          {data.status == Status.Error ?
                            <Error />
                          : data.status == Status.ReadyForDownload ?
                            <Download onClick={()=>handleDownload(data.fileName)} />
                          : 
                            <InsertDriveFile />
                          }                          
                        </IconButton>
                      }

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>                             

        </>
        }  
      </Paper>
      
    </Container>
  )
}
