/*
*** AUTO-GENERATED ***

This file has been generated by a code generator. Please do not manually modify this file.
To make changes, modify the templates in the code generator.

*/

import { Box, LinearProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useAppState } from 'src/hooks/useAppState'
import { useGetInspectionsCitrusByIdsQuery } from 'src/utils/__generated__/graphql'

interface Props {
  inspectionIds: number[]
}

export const InspectionsCitrus = ({
  inspectionIds
}: Props) => {
  const { t } = useTranslation()
  const { seasonId } = useAppState()  
  const { loading, error, data } = useGetInspectionsCitrusByIdsQuery({
    variables: {
      seasonId: seasonId,
      where: {or:
        inspectionIds.map((id) => ({id: {eq: id}}) )
      }
    }
  })

  const renderDetail = (value, key) => (
    <Box 
      key={key}
      sx={{minHeight:'1rem', 
        overflow: 'hidden',
        borderBottom: '1px solid',
        borderColor: 'rowDivider.main'
      }}
    >
      {value}
    </Box>
  )

  if (loading) return (<div><LinearProgress /></div>)
  if (error) return (<p>Error: {JSON.stringify(error)}</p>)

  return (          
    <Box>            
      <Box 
        sx={{display: 'grid',
          minWidth: '16rem',
          //width: 'max-content',
          width: `${(inspectionIds?.length * 6 ?? 1) + 10}rem`,                            
          gridAutoFlow: 'column',
          gridTemplateRows: `repeat(57, min-content)`,
          gridGap: '0px'
        }}
      >                
        {renderDetail(t('InspGrowerCode', 'InspGrowerCode'), 'InspGrowerCode')}
        {renderDetail(t('InspPackDate', 'InspPackDate'), 'InspPackDate')}
        {renderDetail(t('InspSize', 'InspSize'), 'InspSize')}
        {renderDetail(t('InspPackCode', 'InspPackCode'), 'InspPackCode')}
        {renderDetail(t('Count', 'Count'), 'Count')}
        {renderDetail(t('PLU', 'PLU'), 'PLU')}
        {renderDetail(t('PLUPER', 'PLUPER'), 'PLUPER')}
        {renderDetail(t('CountryOfOrigin', 'CountryOfOrigin'), 'CountryOfOrigin')}
        {renderDetail(t('Weight', 'Weight'), 'Weight')}
        {renderDetail(t('UnderweightMin', 'UnderweightMin'), 'UnderweightMin')}
        {renderDetail(t('UnderweightMax', 'UnderweightMax'), 'UnderweightMax')}
        {renderDetail(t('WeighedUnits', 'WeighedUnits'), 'WeighedUnits')}
        {renderDetail(t('UnderweightUnits', 'UnderweightUnits'), 'UnderweightUnits')}
        {renderDetail(t('UnderweightPER', 'UnderweightPER'), 'UnderweightPER')}
        {renderDetail(t('PulpTemp', 'PulpTemp'), 'PulpTemp')}
        {renderDetail(t('Opening', 'Opening'), 'Opening')}
        {renderDetail(t('Color', 'Color'), 'Color')}
        {renderDetail(t('DiameterMinMM', 'DiameterMinMM'), 'DiameterMinMM')}
        {renderDetail(t('DiameterMinInches', 'DiameterMinInches'), 'DiameterMinInches')}
        {renderDetail(t('DiameterMaxMM', 'DiameterMaxMM'), 'DiameterMaxMM')}
        {renderDetail(t('DiameterMaxInches', 'DiameterMaxInches'), 'DiameterMaxInches')}
        {renderDetail(t('DiameterMostMM', 'DiameterMostMM'), 'DiameterMostMM')}
        {renderDetail(t('DiameterMostInches', 'DiameterMostInches'), 'DiameterMostInches')}
        {renderDetail(t('ScarsPieces', 'ScarsPieces'), 'ScarsPieces')}
        {renderDetail(t('ScarsPER', 'ScarsPER'), 'ScarsPER')}
        {renderDetail(t('ScarsDEG', 'ScarsDEG'), 'ScarsDEG')}
        {renderDetail(t('GreenHazePieces', 'GreenHazePieces'), 'GreenHazePieces')}
        {renderDetail(t('GreenHazePER', 'GreenHazePER'), 'GreenHazePER')}
        {renderDetail(t('GreenHazeDEG', 'GreenHazeDEG'), 'GreenHazeDEG')}
        {renderDetail(t('OilSpotsPieces', 'OilSpotsPieces'), 'OilSpotsPieces')}
        {renderDetail(t('OilSpotsPER', 'OilSpotsPER'), 'OilSpotsPER')}
        {renderDetail(t('OilSpotsDEG', 'OilSpotsDEG'), 'OilSpotsDEG')}
        {renderDetail(t('CutCount', 'CutCount'), 'CutCount')}
        {renderDetail(t('DryPulpPieces', 'DryPulpPieces'), 'DryPulpPieces')}
        {renderDetail(t('DryPulpPER', 'DryPulpPER'), 'DryPulpPER')}
        {renderDetail(t('DryPulpDEG', 'DryPulpDEG'), 'DryPulpDEG')}
        {renderDetail(t('SeedsPieces', 'SeedsPieces'), 'SeedsPieces')}
        {renderDetail(t('SeedsPER', 'SeedsPER'), 'SeedsPER')}
        {renderDetail(t('SkinBreakdownPieces', 'SkinBreakdownPieces'), 'SkinBreakdownPieces')}
        {renderDetail(t('SkinBreakdownPER', 'SkinBreakdownPER'), 'SkinBreakdownPER')}
        {renderDetail(t('SkinBreakdownDEG', 'SkinBreakdownDEG'), 'SkinBreakdownDEG')}
        {renderDetail(t('CreasingPieces', 'CreasingPieces'), 'CreasingPieces')}
        {renderDetail(t('CreasingPER', 'CreasingPER'), 'CreasingPER')}
        {renderDetail(t('CreasingDEG', 'CreasingDEG'), 'CreasingDEG')}
        {renderDetail(t('PuffinessPieces', 'PuffinessPieces'), 'PuffinessPieces')}
        {renderDetail(t('PuffinessPER', 'PuffinessPER'), 'PuffinessPER')}
        {renderDetail(t('PuffinessDEG', 'PuffinessDEG'), 'PuffinessDEG')}
        {renderDetail(t('DecayPieces', 'DecayPieces'), 'DecayPieces')}
        {renderDetail(t('DecayPER', 'DecayPER'), 'DecayPER')}
        {renderDetail(t('DecayDEG', 'DecayDEG'), 'DecayDEG')}
        {renderDetail(t('MoldPieces', 'MoldPieces'), 'MoldPieces')}
        {renderDetail(t('MoldPER', 'MoldPER'), 'MoldPER')}
        {renderDetail(t('SporesPieces', 'SporesPieces'), 'SporesPieces')}
        {renderDetail(t('SporesPER', 'SporesPER'), 'SporesPER')}
        {renderDetail(t('Brix', 'Brix'), 'Brix')}
        {renderDetail(t('OverallQ', 'OverallQ'), 'OverallQ')}
        {renderDetail(t('OverallC', 'OverallC'), 'OverallC')}

        {data?.inspections?.nodes?.map((inspection, i) => {
          return (
            <>
              {renderDetail(inspection.inspectionDetail['inspGrowerCode'], `${inspection.id}-inspGrowerCode`)}
              {renderDetail(inspection.inspectionDetail['inspPackDate'], `${inspection.id}-inspPackDate`)}
              {renderDetail(inspection.inspectionDetail['inspSize'], `${inspection.id}-inspSize`)}
              {renderDetail(inspection.inspectionDetail['inspPackCode'], `${inspection.id}-inspPackCode`)}
              {renderDetail(inspection.inspectionDetail['count'], `${inspection.id}-count`)}
              {renderDetail(inspection.inspectionDetail['pLU'], `${inspection.id}-pLU`)}
              {renderDetail(inspection.inspectionDetail['pLUPER'], `${inspection.id}-pLUPER`)}
              {renderDetail(inspection.inspectionDetail['countryOfOrigin'], `${inspection.id}-countryOfOrigin`)}
              {renderDetail(inspection.inspectionDetail['weight'], `${inspection.id}-weight`)}
              {renderDetail(inspection.inspectionDetail['underweightMin'], `${inspection.id}-underweightMin`)}
              {renderDetail(inspection.inspectionDetail['underweightMax'], `${inspection.id}-underweightMax`)}
              {renderDetail(inspection.inspectionDetail['weighedUnits'], `${inspection.id}-weighedUnits`)}
              {renderDetail(inspection.inspectionDetail['underweightUnits'], `${inspection.id}-underweightUnits`)}
              {renderDetail(inspection.inspectionDetail['underweightPER'], `${inspection.id}-underweightPER`)}
              {renderDetail(inspection.inspectionDetail['pulpTemp'], `${inspection.id}-pulpTemp`)}
              {renderDetail(inspection.inspectionDetail['opening'], `${inspection.id}-opening`)}
              {renderDetail(inspection.inspectionDetail['color'], `${inspection.id}-color`)}
              {renderDetail(inspection.inspectionDetail['diameterMinMM'], `${inspection.id}-diameterMinMM`)}
              {renderDetail(inspection.inspectionDetail['diameterMinInches'], `${inspection.id}-diameterMinInches`)}
              {renderDetail(inspection.inspectionDetail['diameterMaxMM'], `${inspection.id}-diameterMaxMM`)}
              {renderDetail(inspection.inspectionDetail['diameterMaxInches'], `${inspection.id}-diameterMaxInches`)}
              {renderDetail(inspection.inspectionDetail['diameterMostMM'], `${inspection.id}-diameterMostMM`)}
              {renderDetail(inspection.inspectionDetail['diameterMostInches'], `${inspection.id}-diameterMostInches`)}
              {renderDetail(inspection.inspectionDetail['scarsPieces'], `${inspection.id}-scarsPieces`)}
              {renderDetail(inspection.inspectionDetail['scarsPER'], `${inspection.id}-scarsPER`)}
              {renderDetail(inspection.inspectionDetail['scarsDEG'], `${inspection.id}-scarsDEG`)}
              {renderDetail(inspection.inspectionDetail['greenHazePieces'], `${inspection.id}-greenHazePieces`)}
              {renderDetail(inspection.inspectionDetail['greenHazePER'], `${inspection.id}-greenHazePER`)}
              {renderDetail(inspection.inspectionDetail['greenHazeDEG'], `${inspection.id}-greenHazeDEG`)}
              {renderDetail(inspection.inspectionDetail['oilSpotsPieces'], `${inspection.id}-oilSpotsPieces`)}
              {renderDetail(inspection.inspectionDetail['oilSpotsPER'], `${inspection.id}-oilSpotsPER`)}
              {renderDetail(inspection.inspectionDetail['oilSpotsDEG'], `${inspection.id}-oilSpotsDEG`)}
              {renderDetail(inspection.inspectionDetail['cutCount'], `${inspection.id}-cutCount`)}
              {renderDetail(inspection.inspectionDetail['dryPulpPieces'], `${inspection.id}-dryPulpPieces`)}
              {renderDetail(inspection.inspectionDetail['dryPulpPER'], `${inspection.id}-dryPulpPER`)}
              {renderDetail(inspection.inspectionDetail['dryPulpDEG'], `${inspection.id}-dryPulpDEG`)}
              {renderDetail(inspection.inspectionDetail['seedsPieces'], `${inspection.id}-seedsPieces`)}
              {renderDetail(inspection.inspectionDetail['seedsPER'], `${inspection.id}-seedsPER`)}
              {renderDetail(inspection.inspectionDetail['skinBreakdownPieces'], `${inspection.id}-skinBreakdownPieces`)}
              {renderDetail(inspection.inspectionDetail['skinBreakdownPER'], `${inspection.id}-skinBreakdownPER`)}
              {renderDetail(inspection.inspectionDetail['skinBreakdownDEG'], `${inspection.id}-skinBreakdownDEG`)}
              {renderDetail(inspection.inspectionDetail['creasingPieces'], `${inspection.id}-creasingPieces`)}
              {renderDetail(inspection.inspectionDetail['creasingPER'], `${inspection.id}-creasingPER`)}
              {renderDetail(inspection.inspectionDetail['creasingDEG'], `${inspection.id}-creasingDEG`)}
              {renderDetail(inspection.inspectionDetail['puffinessPieces'], `${inspection.id}-puffinessPieces`)}
              {renderDetail(inspection.inspectionDetail['puffinessPER'], `${inspection.id}-puffinessPER`)}
              {renderDetail(inspection.inspectionDetail['puffinessDEG'], `${inspection.id}-puffinessDEG`)}
              {renderDetail(inspection.inspectionDetail['decayPieces'], `${inspection.id}-decayPieces`)}
              {renderDetail(inspection.inspectionDetail['decayPER'], `${inspection.id}-decayPER`)}
              {renderDetail(inspection.inspectionDetail['decayDEG'], `${inspection.id}-decayDEG`)}
              {renderDetail(inspection.inspectionDetail['moldPieces'], `${inspection.id}-moldPieces`)}
              {renderDetail(inspection.inspectionDetail['moldPER'], `${inspection.id}-moldPER`)}
              {renderDetail(inspection.inspectionDetail['sporesPieces'], `${inspection.id}-sporesPieces`)}
              {renderDetail(inspection.inspectionDetail['sporesPER'], `${inspection.id}-sporesPER`)}
              {renderDetail(inspection.inspectionDetail['brix'], `${inspection.id}-brix`)}
              {renderDetail(inspection.inspectionDetail['overallQ'], `${inspection.id}-overallQ`)}
              {renderDetail(inspection.inspectionDetail['overallC'], `${inspection.id}-overallC`)}
            </>
          )
        })}
      </Box>

    </Box>    
  )
}