import React from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { Filter } from './Filter'
import { FilterOptionObject, FilterProps } from 'src/typeDefinitions/appTypes'

export const FilterCommodityVariety = ({
  title,
  filterType,
  filterOptions,
  selectedFilters,
  onDone
}: FilterProps) => {
  const { t } = useTranslation()
  const [step, setStep] = React.useState<'commodity' | 'variety'>('commodity')
  const [selectedcommodity, setSelectedCommodity] = React.useState<FilterOptionObject>()  
  
  const handleCommodityChange = (value: FilterOptionObject) => {    
    setSelectedCommodity(value)
    setStep('variety')
  }

  const handleVarietyDone = (draftFilters) => {           
    let commodities = [selectedcommodity]
    if (selectedFilters?.commodities?.length > 0
      && !selectedFilters?.commodities?.some(c => c.value === selectedcommodity.value)) {
      commodities = selectedFilters.commodities.concat(commodities)
    }

    onDone({...draftFilters, commodities})
  }

  if (step === 'commodity') {
    return (
      <Stack p={2}>
        <Filter 
          title={t('Commodity', 'Commodity')}
          filterType='commodities'
          filterOptions={
            // @ts-ignore
            filterOptions?.commodities?.map(commodity => {
            
              const displayText = t(
                `Commodity-${ commodity.displayText }`,
                commodity.displayText
              )

              return { ...commodity, displayText }
                
            })
          }
          selectedFilters={selectedFilters}
          hideDone
          onChange={handleCommodityChange}
        />    
      </Stack>
    )
  }
  else if (step === 'variety') {
    return (
      <>
        <Filter 
          title={ `${ t('VarietiesFor', 'Varieties for') } ${ selectedcommodity.displayText }` }
          filterType='varieties'
          // @ts-ignore
          filterOptions={filterOptions?.varieties?.filter(v=>v.commodityId===selectedcommodity.value)}
          selectedFilters={selectedFilters}
          onDone={handleVarietyDone}
        />
      </>
    )
  }
  
}