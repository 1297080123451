import React from 'react'

import { FilterOptionObject, FiltersOptions } from 'src/typeDefinitions/appTypes'
import { GetWarehouseOrdersFilterOptionsQuery } from 'src/utils/__generated__/graphql'


/**
  * Takes the raw filterOption data from server and converts to a UI friendly format
  * @param filterData raw data from server
  * @returns UI friendly and consistent filterOptions
  */
export const useConvertRawWarehouseOrderFiltersToFilterOptions = (filterData: GetWarehouseOrdersFilterOptionsQuery) => {
  const inspectionGroupVariables = React.useMemo<FiltersOptions>(
    () => {
      
      let locations = filterData?.warehouseOrderFilterOptions?.locationFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.locationId,
          displayText: `${f?.location?.locationName}`
        }
      }) ?? []
      locations.sort((a, b) => a.displayText.localeCompare(b.displayText))
      
      let importers = filterData?.warehouseOrderFilterOptions?.importerFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.importerId,
          displayText: f?.importer?.importerName
        }
      }) ?? []
      importers.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let pickupNumbers = filterData?.warehouseOrderFilterOptions?.pickupNumberFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f,
          displayText: f
        }
      }) ?? []
      pickupNumbers.sort((a, b) => a.displayText.localeCompare(b.displayText))

      const temp: FiltersOptions = {
        locations,
        importers,
        pickupNumbers
      }
        
      return temp
    }, [filterData]
  )
  return inspectionGroupVariables
}