import * as React from 'react'
import { Autocomplete, Box, Button, Card, CardActions, CardContent, Divider, Grid, IconButton, LinearProgress, Paper, Rating, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Download } from '@mui/icons-material'

import { SelectedFilters } from 'src/typeDefinitions/appTypes'
import { Filters } from 'src/components/Filters'
import { Pagination } from 'src/components/Pagination'
import { useGetTemperatureRecordersFilterOptionsQuery, useGetAllTemperatureRecordersQuery, useDownloadFileMutation, FileType } from 'src/utils/__generated__/graphql'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAppState } from 'src/hooks/useAppState'
import { useTemperatureRecorderQueryFilter } from './useTemperatureRecorderQueryFilter'
import { useConvertRawTemperatureRecorderFiltersToFilterOptions } from './useConvertRawTemperatureRecorderFiltersToFilterOptions'
import { ErrorAlert } from 'src/components/ErrorAlert'

export const TemperatureRecordersRoute = () => { 
  const { settings } = useAppState()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { seasonId } = useAppState()
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters>({
    locations: [],
    arrivals: [],
    importers: [],
    exporters: [],
    containers: [],
    lots: [],    
  })   
  const { variables, filterOptionsVariables, maxPageSize, setPageFilter } = useTemperatureRecorderQueryFilter(selectedFilters, seasonId)
  const { loading, error, data } = useGetAllTemperatureRecordersQuery({
    variables: variables
  })
  const { loading: loadingFilters, error: filterError, data: filterData } = useGetTemperatureRecordersFilterOptionsQuery({
    variables: filterOptionsVariables
  })
  const filtersOptions = useConvertRawTemperatureRecorderFiltersToFilterOptions(filterData)
  const [downloadFile, {loading: loadingDownloadFile, error: downloadFileError}] = useDownloadFileMutation()    
  
  const handleFiltersSelected = (newSelectedFilters) => {
    setPageFilter({
      last: null,
      before: null,
      first: maxPageSize,
      after: null
    })
    setSelectedFilters(newSelectedFilters)
  }

  const handleDownload = (id) => {
    const link = document.createElement("a");
    link.download = id + '.pdf'
    let path = 'destination/temperature-recorder/'    
    link.href = settings.psaV2ApiUrl + path + id
    link.click();
  }

  const handleDownloadClick = async (fileType: FileType) => {
    const result = 
      await downloadFile({variables: {
        input: {
          fileType,
          filter: { temperatureRecorderFilters: {...filterOptionsVariables.filterInput} }
        }
      }})
      
      if (!result?.data?.downloadFile?.downloadRequest?.id) {
        // Request failed, we don't need to do anything here as error will be available on hook.
      }
      else {
        navigate('/account/my-downloads')
      }
  }

  return (
    <>
    
      <Paper
        elevation={0}
        sx={{ p: '1rem' }}
      >
        <Stack sx={{background: 'mainBackground.main'}}>
          <Stack spacing={2} sx={{paddingBottom: 2}}>
            <Typography variant='h6' sx={{flexGrow: 2}}>{ t('TemperatureRecorders', 'Temperature recorders') }</Typography>
          </Stack>

          <Filters 
            loading={loadingFilters}
            filtersOptions={filtersOptions} 
            filterUiData={[{
              title: t('Location', 'Location'),
              filterType: 'locations',
              filterComponentType: 'Standard'
            }, {
              title: t('Arrival', 'Arrival'),
              filterType: 'arrivals',
              filterComponentType: 'Standard'
            }, {
              title: t('Importer', 'Importer'),
              filterType: 'importers',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Exporter', 'Exporter'),
              filterType: 'exporters',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Container', 'Container'),
              filterType: 'containers',
              screenSize: 'md',
              filterComponentType: 'Standard'
            }, {
              title: t('LotCodeFilter', 'Lot code'),
              filterType: 'lots',
              screenSize: 'lg',
              filterComponentType: 'Standard'
            }]}        
            selectedFilters={selectedFilters}
            onFiltersSelected={handleFiltersSelected}
          />
        </Stack>

      </Paper>
      <Paper
        elevation={0}
        sx={{ p: '1rem', backgroundColor: 'baseBackground.main', minHeight: '100vh' }}
      >
        { loading ? <LinearProgress />
        : !!(error || filterError) ? <ErrorAlert error={error || filterError} /> :
        <>
          <Box sx={{paddingBottom: '2rem'}}>
            <Typography variant='caption'> { t('Results', 'Results') }: {data?.temperatureRecorders?.totalCount ?? 0}</Typography>
          </Box>

          <TableContainer component={Paper} sx={{display: {xs: 'none', md: 'block'}}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{ t('Location', 'Location') }</TableCell>
                  <TableCell>{ t('Arrival', 'Arrival') }</TableCell>
                  <TableCell>{ t('Importer', 'Importer') }</TableCell>
                  <TableCell>{ t('Exporter', 'Exporter') }</TableCell>
                  <TableCell>{ t('Pallet', 'Pallet') }</TableCell>
                  <TableCell>{ t('HatchDeckSentenseCase', 'Hatch/deck') }</TableCell>
                  <TableCell>{ t('Container', 'Container') }</TableCell>
                  <TableCell>{ t('LotCodeFilter', 'Lot code') }</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.temperatureRecorders?.nodes?.map(data => (
                  <TableRow
                    key={data?.pallet?.palletCode}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{data?.pallet?.location?.locationName}</TableCell>
                    <TableCell>{data?.pallet?.arrivalImpExp?.arrival?.arrivalName}</TableCell>
                    <TableCell>{data?.pallet?.arrivalImpExp?.impExp?.importer?.importerName}</TableCell>
                    <TableCell>{data?.pallet?.arrivalImpExp?.impExp?.exporter?.exporterName}</TableCell>
                    <TableCell>{data?.pallet?.palletCode}</TableCell>
                    <TableCell>{data?.pallet?.hatch}{data?.pallet?.deck}</TableCell>
                    <TableCell>{data?.pallet?.container}</TableCell>                    
                    <TableCell>{data?.pallet?.lotCode}</TableCell>                    
                    <TableCell>
                      <IconButton aria-label="delete">
                        <Download onClick={()=>{handleDownload(data?.websiteId)}}/>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack spacing={2} sx={{display: {xs: 'block', md: 'none'}}}>
            {data.temperatureRecorders?.nodes?.map(data => (
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('Location', 'Location') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.pallet?.location?.locationName}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('Arrival', 'Arrival') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.pallet?.arrivalImpExp?.arrival?.arrivalName}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('Importer', 'Importer') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.pallet?.arrivalImpExp?.impExp?.importer?.importerName}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('Exporter', 'Exporter') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.pallet?.arrivalImpExp?.impExp?.exporter?.exporterName}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('Pallet', 'Pallet') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.pallet?.palletCode}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('HatchDeckSentenseCase', 'Hatch/deck') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.pallet?.hatch}{data?.pallet?.deck}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('Container', 'Container') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.pallet?.container}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('LotCodeFilter', 'Lot code') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.pallet?.lotCode}
                    </Grid>
                  </Grid>
                  
                  
                </CardContent>
                <CardActions>
                  <IconButton aria-label="delete">
                    <Download onClick={()=>{handleDownload(data?.websiteId)}} />
                  </IconButton>
                </CardActions>
             </Card>
            ))}
          </Stack>

          
          <Pagination 
            maxPageSize={maxPageSize}
            totalPages={Math.ceil(data?.temperatureRecorders?.totalCount / maxPageSize)}
            pageInfo={data?.temperatureRecorders?.pageInfo}
            setPageFilter={setPageFilter}
          />

          <Box mt={6} mb={6}>            
            <Button 
              variant='outlined'                 
              startIcon={<Download />} 
              size='large'
              disabled={loadingDownloadFile || (data?.temperatureRecorders?.nodes?.length ?? 0) === 0}
              onClick={() => {
                handleDownloadClick(FileType.TemperatureRecorderZip)
              }}
            >            
              { t('DownloadAllTemperatureRecorders', 'Download all temperature recorders (ZIP)') }
            </Button>
          </Box>
          

        </>
        }  
      </Paper>
    </>
  )
}
