import React from 'react'
import { Button, useMediaQuery, useTheme, Theme } from '@mui/material'

import { FiltersSelectedChips, FiltersSelectedChipsProps } from 'src/components/FiltersSelectedChips'

interface Props extends FiltersSelectedChipsProps {  
  displayingExpandedFilters: boolean
  onToggleDisplayingExpandedFilters: () => void
}

export const FiltersSelectedSummary = ({
  flatSelectedFilters,    
  displayingExpandedFilters,
  onDeleteFilter,
  onToggleDisplayingExpandedFilters
}: Props) => {
  const theme = useTheme<Theme>()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const md = useMediaQuery(theme.breakpoints.down('md'))

  let displayedFiltersMax = 3
  
  if (sm)
    displayedFiltersMax = 0
  else if (md)
    displayedFiltersMax = 2

  const totalFiltersExceedingMax = flatSelectedFilters.length - displayedFiltersMax 
  
  return (
    <>   
      { !displayingExpandedFilters &&
        <FiltersSelectedChips 
          flatSelectedFilters={flatSelectedFilters.slice(0, displayedFiltersMax)} 
          {...{onDeleteFilter}}  
        />
      }

      { totalFiltersExceedingMax > 0 && !displayingExpandedFilters && 
        <Button 
          variant='outlined'
          onClick={onToggleDisplayingExpandedFilters}
        >
          +{totalFiltersExceedingMax} More
        </Button>
      }

      { displayingExpandedFilters &&
        <Button 
          variant='outlined'
          onClick={onToggleDisplayingExpandedFilters}
        >
          Hide
        </Button>
      }
    </>
  )
}