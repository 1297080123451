import React from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { AppBar, Box, Link, Toolbar, useMediaQuery, useTheme, Theme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import { useDraftFilters } from 'src/hooks/useDraftFilters'
import { FilterDoneBox } from './FilterDoneBox'
import { FilterProps, SelectedFilters, FilterOptionObject } from 'src/typeDefinitions/appTypes'

export const Filter = ({
  title,
  hideTitle = false,
  filterType,
  filterOptions,
  selectedFilters,
  hideDone = false,
  onChange,
  onDone
}: FilterProps) => {
  const {draftFilters, handleToggleFilter} = useDraftFilters(selectedFilters as SelectedFilters)
  
  const theme = useTheme<Theme>()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const md = useMediaQuery(theme.breakpoints.down('md'))
  
  let columnCount = 3

  if (sm)
    columnCount = 1
  else if (filterOptions?.length < 6)
    columnCount = 1
  else if (filterOptions?.length < 15)
    columnCount = 2
  else if (md)
    columnCount = 2

  let maxHeight = '400px'
  if (sm)
    maxHeight = '60vh'  
  else if (md)
    maxHeight = '60vh'

  const handleClick = (selectedFilterOption: FilterOptionObject) => {        
    handleToggleFilter(filterType, selectedFilterOption)
    if (onChange)
      onChange(selectedFilterOption)  
  }

  const handleDone = () => {        
    onDone(draftFilters)
  }

  return (
    <Box sx={{maxHeight: '100vh'}}>

      { !hideTitle &&
        <>
          <AppBar color='transparent' sx={{ boxShadow: 2 }}>
            <Toolbar sx={{fontWeight: 500}}>
              {title}
            </Toolbar>
          </AppBar>
          <Toolbar />
        </>      
      }

      <Box sx={{ maxHeight, overflowY: 'auto'}}>
        <List sx={{ maxWidth: 800, bgcolor: 'background.paper', columnCount }}>
        {filterOptions?.map((filterOption) => {
          return (        
            <ListItem
              key={`${filterOption.value}${filterOption.displayText}`}
              sx={{display: 'inline-block', width: '100%'}}               
            >
              <Link
                sx={{ cursor: 'pointer' }} 
                underline="hover" 
                color="text.primary"
                onClick={()=>{handleClick(filterOption)}}
              >
                {filterOption.displayText}
                { draftFilters[filterType]?.some(d => d.value === filterOption.value) &&
                  <CheckIcon color='primary' sx={{ fontSize: 18, marginLeft: '2px', marginBottom: '-2px' }} />              
                }
              </Link>
            </ListItem>              
          )
        })}
        </List>
      </Box>    

      { !hideDone &&               
        <FilterDoneBox onDone={handleDone}/>
      }
    
    </Box>
  )
}