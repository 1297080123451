import * as React from 'react'
import { Alert, Box, Button, Card, CardActions, CardContent, Divider, Grid, ImageList, ImageListItem, LinearProgress, Paper, Stack, Theme, Typography, useMediaQuery } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { useAppState } from 'src/hooks/useAppState'
import { ErrorAlert } from 'src/components/ErrorAlert'
import { Download } from '@mui/icons-material'
import { PictureCard } from 'src/components/PictureCard'
import { useTranslation } from 'react-i18next'
import { Picture, useDownloadFileMutation, useGetHostedReportQuery } from 'src/utils/__generated__/graphql'

export const HostedReportRoute = () => {
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { settings } = useAppState()
  const { t } = useTranslation()  
  const navigate = useNavigate()
  const { loading, error, data } = useGetHostedReportQuery({
    variables: {
      websiteId: params.websiteId,
      hostedReportLinkId: parseInt(params.hostedReportLinkId),      
      preview: searchParams.get('preview') === '1' ? true : null
    }
  })
  const [open, setOpen] = React.useState(false)
  const [selectedPicture, setSelectedPicture] = React.useState<Picture>()
  const [downloadFile, {loading: loadingDownloadFile, error: downloadFileError}] = useDownloadFileMutation()
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  
  const handleReportClick = (folder, filename, fetchByWebsiteId) => {
    const link = document.createElement("a")
    link.download = filename
    let path = fetchByWebsiteId
      ? `hostedReportFileByWebsiteId/${ data.hostedReport.websiteId }/`
      : `hostedReportFile/${ folder }/${ encodeURIComponent(filename) }`
    link.href = settings.psaV2ApiUrl + path
    link.click()
  }
  
  const handleDownloadPictureZipClick = (folder) => {
    const link = document.createElement("a")
    link.download = data?.hostedReport?.websiteId + '.zip'
    let path = `hostedReportPictureZip/${ folder }`
    link.href = settings.psaV2ApiUrl + path
    link.click()
  }

  return (
    <>
      <Paper
        elevation={0}
        sx={{ p: '1rem' }}
      >
        <Stack sx={{background: 'mainBackground.main'}}>
                  
          <Typography variant='h6' sx={{flexGrow: 2}}>Hosted report</Typography>
        
        </Stack>

      </Paper>
      
      { loading ? <LinearProgress />
      : !!error ? <ErrorAlert error={error} /> :
      <>

        <Paper
          elevation={0}
          sx={{ p: '1rem', backgroundColor: 'baseBackground.main' }}
        >
          
          <Box sx={{display: 'flex', justifyContent: 'center'}} mb={4}>
            <Stack spacing={2}>
              
                <Card sx={{ minWidth: 275, maxWidth: 1000 }}>
                  <Box sx={{ display: 'flex', flexDirection: {xs: 'column', sm: 'row'} }}>                  
                    <Box sx={{width: '100%'}}>
                      <CardContent>
                        
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }} >
                          <Box >
                            <Typography component="div" variant="h5">
                              {data?.hostedReport?.reference}
                            </Typography>

                            <Typography 
                              component="div" 
                              variant="h6" 
                              sx={{
                                color: 
                                  data?.hostedReport?.hostedReportStatus === 'Approved' ? 'success.main' :
                                  data?.hostedReport?.hostedReportStatus === 'Rejected' ? 'error.main' : 'black'
                              }}
                            >
                              {data?.hostedReport?.hostedReportStatus}
                            </Typography>

                            <Typography component="div" mt={2}>
                              <i>{data?.hostedReport?.location?.locationName}</i>
                            </Typography>
                            
                            <Typography component="div">
                              {data?.hostedReport?.arrivalName}
                            </Typography>

                            <Typography component="div">
                              {data?.hostedReport?.hostedReportProgram}
                            </Typography>

                            

                          </Box>
                          <Box ml={2}>
                            <Typography component="div" variant="h6" sx={{textAlign: 'right'}}>
                              Qty. {data?.hostedReport?.totalQty}
                            </Typography>

                            <Typography component="div" variant="caption" sx={{textAlign: 'right'}}>
                              { data?.hostedReport?.createdOn?.slice(0, 10) }
                            </Typography>

                          </Box>
                        </Box>
                        
                      </CardContent>
                      
                      <Divider/>

                      <CardActions>

                        <Stack sx={{flexGrow: 1, justifyContent: 'center'}} direction='row'>
                          { data?.hostedReport?.reportFiles?.map(file => (                          
                            <Button 
                              key={file.filename}
                              variant='text' 
                              sx={{fontSize: '1.2rem'}}
                              startIcon={<Download />} 
                              onClick={()=>{handleReportClick(file.folder, file.filename, file.fetchByWebsiteId)} }
                            >
                              Download {file.filename}
                            </Button>
                          ))}
                        </Stack>

                      </CardActions>

                    </Box>
                  </Box>
              </Card>
              
            </Stack>
          </Box>


          <ImageList cols={isMedium ? 1 : 2 }>
            {data?.hostedReport?.pictureFiles?.map((pic) => (
              <ImageListItem key={pic.filename}>
                <img 
                  src={`${settings.psaV2ApiUrl}hostedReportPicture/${pic.folder}/${pic.filename}`}
                  alt={pic.filename}                  
                />
              </ImageListItem>
            ))}
          </ImageList>

          <Stack mt={6} mb={6} direction={'row'} spacing={2}>
            <Button 
              variant='outlined' 
              startIcon={<Download />} 
              size='large'
              disabled={(data?.hostedReport?.pictureFiles?.length ?? 0) === 0}
              onClick={()=>{handleDownloadPictureZipClick(data?.hostedReport?.pictureFiles[0]?.folder)} }                        
            >            
              { t('DownloadAllPictures', 'Download all pictures (ZIP)' ) }
            </Button>
                        
          </Stack>  
          
        </Paper>
      </>
      }

    </>
  )
}