import Box from '@mui/material/Box'
import { Container, Grid, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import locations1 from 'src/assets/images/locations1.jpg'

export const LocationsRoute = () => {
  const { t } = useTranslation()
  
  return (
    <>    
      <Paper
        elevation={0}
        sx={{ 
          p: '1rem',
          minHeight: '100vh',
          backgroundImage: `url(${locations1})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
 
        <Container maxWidth='xl'>
          
          <Box
            sx={{
              backgroundColor: 'mainBackground.main',
              opacity: .9,
              borderRadius: 5,
              padding: '1rem'
            }}
          >
            
            <Typography variant='h3' mb={6}>
              { t('Locations', 'Locations') }
            </Typography>
                                  
            <Grid container rowSpacing={4}>
              <Grid item xs={12} md={6}>
                
                  <Box sx={{fontWeight: 700}}>
                    U.S. East Coast
                  </Box>
                  PO Box 182<br />
                  King &amp; Essex Streets<br />
                  Gloucester City, NJ 08030<br />
                  U.S.A.<br />

                  { t('Phone', 'Phone') }: <a href="tel:1-856-456-1580">1-856-456-1580</a><br />
                  Fax: 1-856-456-7447
               
              </Grid>

              <Grid item xs={12} md={6}>
                
                  <Box sx={{fontWeight: 700}}>
                    U. S. West Coast
                  </Box>
                  4100 Bandini Boulevard<br />
                  Vernon, California 90023<br />
                  U.S.A.<br />

                  { t('Phone', 'Phone') }: <a href="tel:1-323-780-1050">1-323-780-1050</a><br />
                  Fax: 1-323-780-1027
               
              </Grid>

              <Grid item xs={12} md={6}>
                
                  <Box sx={{fontWeight: 700}}>
                    The Netherlands
                  </Box>
                  Finlandweg 10<br />
                  Port No. 4444<br />
                  4445 TE Nieuwdrop<br />
                  Vlissingen-Oost<br />
                  Holland<br />

                  { t('Phone', 'Phone') }: <a href="tel:31-118-486-256">31-118-486-256</a><br />
                  Fax: 31-118-486-258
               
              </Grid>

              <Grid item xs={12} md={6}>
                
                  <Box sx={{fontWeight: 700}}>
                    Chile
                  </Box>
                  Av. Los Leones # 2589, of. 1<br />
                  Providencia, Santiago<br />
                  Chile<br />

                  { t('Phone', 'Phone') }: <a href="tel:56-22-898-4996">56-22-898-4996</a><br />
                  { t('Phone', 'Phone') }2: <a href="tel:56-22-898-4997">56-22-898-4997</a><br />
                  Fax: 56-2-341-7974
               
              </Grid>
            </Grid>
          </Box>


        </Container>
      </Paper>
    </>
  )
}