import React from 'react'

import { PageFilter, SelectedFilters } from 'src/typeDefinitions/appTypes'
import { InputMaybe, HostedReportFilterInput, HostedReportFiltersInput } from 'src/utils/__generated__/graphql'

type Variables = {
  seasonId,
  filter
}

type FilterOptionsVariables = {
  filterInput: HostedReportFiltersInput
}

/**
  * Takes the selected filters and converts them into friendly GraphQL variables.
  * @param selectedFilters filters that the user has selected
  * @param seasonId seasonId filter
  * @returns object \{ variables \} to use in GraphQL query to filter the data and filterOptions
  */
export const useHostedReportQueryFilter = (
  selectedFilters: SelectedFilters, 
  seasonId: number,
  maxPageSize: number = 50,
  isCutieReport: boolean = null
) => {  
  const [pageFilter, setPageFilter] = React.useState<PageFilter>({
    last: null,
    before: null,
    first: maxPageSize,
    after: null
  })

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto"
    })
  }, [[selectedFilters, seasonId, pageFilter]])
  
  const filterOptionsVariables = React.useMemo<FilterOptionsVariables>(
    () => {
      // Prepare reusable local variables.      
      let locationIds: number[] = selectedFilters?.locations?.map(f => f.value as number)
      let importerIds: number[] = selectedFilters?.importers?.map(f => f.value as number)
      let exporterIds: number[] = selectedFilters?.exporters?.map(f => f.value as number)      
      let arrivalNames: string[] = selectedFilters?.arrivalNames?.map(f => f.value as string)
      let containers: string[] = selectedFilters?.containers?.map(f => f.value as string)
      let lotCodes: string[] = selectedFilters?.lotCodes?.map(f => f.value as string)
      let pos: string[] = selectedFilters?.pos?.map(f => f.value as string)
      let growers: string[] = selectedFilters?.growers?.map(f => f.value as string)
      let statuses: string[] = selectedFilters?.statuses?.map(f => f.value as string)
      let programs: string[] = selectedFilters?.programs?.map(f => f.value as string)
      

      // FilterInput *****************************************************************
      let filterInput = {
        seasonId,                
      }

      // IsCutieReport
      if (isCutieReport !== null)
        filterInput['isCutieReport'] = isCutieReport
      
      // Locations
      if (!!locationIds && locationIds.length > 0)
        filterInput['locationIds'] = locationIds      
    
      // Importers
      if (!!importerIds && importerIds.length > 0)
        filterInput['importerIds'] = importerIds

      // Exporters
      if (!!exporterIds && exporterIds.length > 0)
        filterInput['exporterIds'] = exporterIds

      // Arrival names
      if (!!arrivalNames && arrivalNames.length > 0)
        filterInput['arrivalNames'] = arrivalNames

      // Containers
      if (!!containers && containers.length > 0)
        filterInput['containers'] = containers

      // Lot codes
      if (!!lotCodes && lotCodes.length > 0)
        filterInput['lotCodes'] = lotCodes

      // PO's
      if (!!pos && pos.length > 0)
        filterInput['pos'] = pos

      // Growers
      if (!!growers && growers.length > 0)
        filterInput['growers'] = growers

      // Statuses
      if (!!statuses && statuses.length > 0)
        filterInput['hostedReportStatuses'] = statuses

      // Programs
      if (!!programs && programs.length > 0)
        filterInput['hostedReportPrograms'] = programs

      
      return {        
        filterInput: filterInput        
      }
    }, 
    [selectedFilters, seasonId]
  )

  const variables = React.useMemo<Variables>(
    () => {
      // Prepare reusable local variables.      
      let locationIds: number[] = selectedFilters?.locations?.map(f => f.value as number)
      let importerIds: number[] = selectedFilters?.importers?.map(f => f.value as number)
      let exporterIds: number[] = selectedFilters?.exporters?.map(f => f.value as number)      
      let arrivalNames: string[] = selectedFilters?.arrivalNames?.map(f => f.value as string)
      let containers: string[] = selectedFilters?.containers?.map(f => f.value as string)
      let lotCodes: string[] = selectedFilters?.lotCodes?.map(f => f.value as string)
      let pos: string[] = selectedFilters?.pos?.map(f => f.value as string)
      let growers: string[] = selectedFilters?.growers?.map(f => f.value as string)
      let statuses: string[] = selectedFilters?.statuses?.map(f => f.value as string)
      let programs: string[] = selectedFilters?.programs?.map(f => f.value as string)    
      

      // GroupFilter *****************************************************************
      let filterDraft: InputMaybe<Array<HostedReportFilterInput>> = []
   
      if (Boolean(seasonId))
        filterDraft.push({seasonId: {eq: seasonId}})

      // IsCutieReport
      if (isCutieReport !== null)
        filterDraft.push({isCutieReport: {eq: isCutieReport}})        

      // Locations
      if (locationIds?.length > 0) {
        let locationFilter: HostedReportFilterInput = 
        { locationId: {in:
          locationIds
        }}
        filterDraft.push(locationFilter)
      }      

      // Importers
      if (importerIds?.length > 0) {
        let importerFilter: HostedReportFilterInput = 
          {impExp: {importerId: {in:
            importerIds
          }}}
        filterDraft.push(importerFilter)
      }

      // Exporters
      if (exporterIds?.length > 0) {
        let exporterFilter: HostedReportFilterInput = 
          {impExp: {exporterId: {in:
            exporterIds
          }}}
        filterDraft.push(exporterFilter)
      }

      // Arrival names
      if (arrivalNames?.length > 0) {
        let arrivalNameFilter: HostedReportFilterInput = 
          {arrivalName: {in:
            arrivalNames
          }}
        filterDraft.push(arrivalNameFilter)
      }

      // Containers
      if (containers?.length > 0) {
        let containerFilter: HostedReportFilterInput = 
          {container: {in:
            containers
          }}
        filterDraft.push(containerFilter)
      }

      // Lots codes
      if (lotCodes?.length > 0) {
        let lotCodeFilter: HostedReportFilterInput = 
          {lotCode: {in:
            lotCodes
          }}
        filterDraft.push(lotCodeFilter)
      }

      // PO's
      if (pos?.length > 0) {
        let poFilter: HostedReportFilterInput = 
          {po: {in:
            pos
          }}
        filterDraft.push(poFilter)
      }

      // Growers
      if (growers?.length > 0) {
        let growerFilter: HostedReportFilterInput = 
          {grower: {in:
            growers
          }}
        filterDraft.push(growerFilter)
      }

      // Statuses
      if (statuses?.length > 0) {
        let statusFilter: HostedReportFilterInput = 
          {hostedReportStatus: {in:
            statuses
          }}
        filterDraft.push(statusFilter)
      }

      // Programs
      if (programs?.length > 0) {
        let programFilter: HostedReportFilterInput = 
          {hostedReportProgram: {in:
            programs
          }}
        filterDraft.push(programFilter)
      }

                  
      const filter: HostedReportFilterInput = {      
        and: filterDraft                    
      }

      
      return {
        last: pageFilter.last,
        before: pageFilter.before,
        first: pageFilter.first,
        after: pageFilter.after,
        seasonId,
        filter
      }
    }, 
    [selectedFilters, seasonId, pageFilter]
  )

  return {
    variables,
    filterOptionsVariables,
    maxPageSize,
    setPageFilter
  }
}