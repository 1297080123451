import * as React from 'react'
import { Box, Card, CardActions, CardContent, Grid, IconButton, LinearProgress, Paper, Stack, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Download } from '@mui/icons-material'

import { useAppState } from 'src/hooks/useAppState'
import { SelectedFilters } from 'src/typeDefinitions/appTypes'
import { Filters } from 'src/components/Filters'
import { Pagination } from 'src/components/Pagination'
import { useGetWarehouseOrdersFilterOptionsQuery, useGetAllWarehouseOrdersQuery, useDownloadFileMutation, FileType } from 'src/utils/__generated__/graphql'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useWarehouseOrderQueryFilter } from './useWarehouseOrderQueryFilter'
import { useConvertRawWarehouseOrderFiltersToFilterOptions } from './useConvertRawWarehouseOrderFiltersToFilterOptions'
import { ErrorAlert } from 'src/components/ErrorAlert'

export const WarehouseOrdersRoute = () => {
  const { seasonId, settings } = useAppState()
  const { t } = useTranslation()
  const navigate = useNavigate()  
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters>({
    locations: [],
    importers: [],    
    pickupNumbers: [],    
  })   
  const { variables, filterOptionsVariables, maxPageSize, setPageFilter } = useWarehouseOrderQueryFilter(selectedFilters, seasonId)
  const { loading, error, data } = useGetAllWarehouseOrdersQuery({
    variables: variables
  })
  const { loading: loadingFilters, error: filterError, data: filterData } = useGetWarehouseOrdersFilterOptionsQuery({
    variables: filterOptionsVariables
  })
  const filtersOptions = useConvertRawWarehouseOrderFiltersToFilterOptions(filterData)
  
  const handleFiltersSelected = (newSelectedFilters) => {
    setPageFilter({
      last: null,
      before: null,
      first: maxPageSize,
      after: null
    })
    setSelectedFilters(newSelectedFilters)
  }

  const handleDownload = (id) => {
    const link = document.createElement("a");
    link.download = id + '.pdf'
    let path = 'api/destination/warehouse-orders/'    
    link.href = settings.psaV2ApiUrl + path + id
    link.click();
  }  

  return (
    <>
    
      <Paper
        elevation={0}
        sx={{ p: '1rem' }}
      >
        <Stack sx={{background: 'mainBackground.main'}}>
          <Stack spacing={2} sx={{paddingBottom: 2}}>
            <Typography variant='h6' sx={{flexGrow: 2}}>
              {t('WarehouseOrders', 'Warehouse orders')}
            </Typography>            
          </Stack>

          <Filters 
            loading={loadingFilters}
            filtersOptions={filtersOptions} 
            filterUiData={[{
              title: t('Location', 'Location'),
              filterType: 'locations',
              filterComponentType: 'Standard'            
            }, {
              title: t('Importer', 'Importer'),
              filterType: 'importers',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('PickupNumber', 'Pickup number'),
              filterType: 'pickupNumbers',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }]}        
            selectedFilters={selectedFilters}        
            onFiltersSelected={handleFiltersSelected}
          />
        </Stack>

      </Paper>
      
      <Paper
        elevation={0}
        sx={{ p: '1rem', backgroundColor: 'baseBackground.main', minHeight: '100vh' }}
      >
        
        { loading ? <LinearProgress />
        : !!(error || filterError) ? <ErrorAlert error={error || filterError} /> :
        
        <>
          <Box sx={{paddingBottom: '2rem'}}><Typography variant='caption'> { t('Results', 'Results') }: {data?.warehouseOrders?.totalCount ?? 0}</Typography></Box>

          <TableContainer component={Paper} sx={{display: {xs: 'none', md: 'block'}}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{ t('Location', 'Location') }</TableCell>
                  <TableCell>{ t('Importer', 'Importer') }</TableCell>
                  <TableCell>{ t('PickupNumber', 'Pickup number') }</TableCell>
                  <TableCell>{ t('Notes', 'Notes') }</TableCell>                  
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.warehouseOrders?.nodes?.map(data => (
                  <TableRow
                    key={data.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{data.location.locationName}</TableCell>
                    <TableCell>{data.importer.importerName}</TableCell>
                    <TableCell>{data.pickupNumber}</TableCell>
                    <TableCell>{data.notes}</TableCell>                    
                    <TableCell align='right'>
                      <IconButton aria-label="delete">
                        <Download onClick={()=>{handleDownload(data?.websiteId)}} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack spacing={2} sx={{display: {xs: 'block', md: 'none'}}}>
            {data?.warehouseOrders?.nodes?.map(data => (
              <Card sx={{ minWidth: 275 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('Location', 'Location') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data.location.locationName}
                    </Grid>
                                        
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('Importer', 'Importer') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data.importer.importerName}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('PickupNumber', 'Pickup number') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data.pickupNumber}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        { t('Notes', 'Notes') }:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data.notes}
                    </Grid>
                    
                  </Grid>
                  
                  
                </CardContent>
                <CardActions>

                  <IconButton aria-label="delete">
                    <Download onClick={()=>{handleDownload(data?.websiteId)}} />
                  </IconButton>

                </CardActions>
             </Card>
            ))}
          </Stack>

          
          <Pagination 
            maxPageSize={maxPageSize}
            totalPages={Math.ceil(data?.warehouseOrders?.totalCount / maxPageSize)}
            pageInfo={data?.warehouseOrders?.pageInfo}            
            setPageFilter={setPageFilter}
          />
          
        </>
        }  
      </Paper>
    </>
  )
}
