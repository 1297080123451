import React from 'react'

import { SelectedFilters, FilterOptionObject } from 'src/typeDefinitions/appTypes'

export const useDraftFilters = (initialDraftFilters: SelectedFilters) => {
  // These are filters that have been selected in the popup.
  const [draftFilters, setDraftFilters] = React.useState<SelectedFilters>(initialDraftFilters)

  const handleToggleFilter = (filterType: string, selectedFilterOption: FilterOptionObject) => {
        
    let temp: FilterOptionObject[] = []
    if (draftFilters?.[filterType]?.some(d => d.value === selectedFilterOption.value))
      temp = draftFilters?.[filterType]?.filter(d => d.value !== selectedFilterOption.value)
    else {
      if (!draftFilters?.[filterType])
        temp = [selectedFilterOption]
      else
        temp = draftFilters?.[filterType]?.concat(selectedFilterOption)        
    }
    
    setDraftFilters({ [filterType]: temp})
  }

  return { draftFilters, handleToggleFilter }
}