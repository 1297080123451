import { Card, CardActionArea, CardContent, CardMedia, Chip, Typography } from "@mui/material"

interface Props {
  url: string, 
  alt: string,
  tags: string[],
  onClick: ()=>void
}

export const PictureCard = ({
  url, 
  alt,
  tags,
  onClick
}: Props) => {
  return (
    <Card sx={{ maxWidth: 150 }} onClick={onClick}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="150"
          image={url}
          alt={alt || ''}
        />
        <CardContent>  
          {tags?.map(tag => (
            <Chip 
              label={tag} 
                             
            />
          ))}
        </CardContent>
      </CardActionArea>
    </Card>
  )
}