import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Box, Button, Container, Grid, Paper, Stack, Typography } from '@mui/material'
import { useAuth } from 'react-oidc-context'
import { useTranslation } from 'react-i18next'
import { RequireAuth, routeRoles } from 'src/components/RequireAuth'


export const LoggedInHomeRoute = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  
  return (
    <Container maxWidth='xl'>
    
      <Paper
        elevation={0}
        sx={{ p: '1rem' }}
      >
        <Stack  sx={{background: 'mainBackground.main'}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent='space-between' sx={{paddingBottom: 2}} >
          
            <Typography variant='h6' sx={{flexGrow: 2}}>
              { t('Welcome', 'Welcome') }
            </Typography>

          </Stack>    
        </Stack>

      </Paper>
      
      <Paper
        elevation={0}
        sx={{ p: '1rem', backgroundColor: 'baseBackground.main' }}
      >
        <Paper 
          sx={{
            p: {md: 12, xs: 1}, 
            minHeight: '80vh', 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center' 
          }}
        >

          <Typography            
            sx={{
              fontSize: {md: '4rem', sm: '2rem', xs: '1rem'},
              pb: 2,               
              fontWeight: 900,
              color: 'rgba(0, 0, 0, 0)',
              backgroundClip: 'text',
              backgroundImage: 'linear-gradient(to right,#69A501,#4A7701)'
            }}
          >
            { t('WelcomeMessage', 'Welcome to the website of Produce Services of America, Inc.') }
          </Typography>

          <Stack direction={{ xs: 'column', md: 'column' }} spacing={3} sx={{pt: 4, alignItems: 'stretch'}}>

            <RequireAuth roles={routeRoles.arrivalReports} justHideWhenWrongRole>
              <Box>
                <Button 
                  variant='outlined' 
                  size='large'
                  fullWidth
                  component={RouterLink}
                  to={'destination/arrival-reports'}                                
                >
                  {t('ArrivalReports', 'Arrival reports')}
                </Button>
              </Box>
            </RequireAuth>

            <RequireAuth roles={routeRoles.inspections} justHideWhenWrongRole>
              <Box>
                <Button 
                  variant='outlined' 
                  size='large'
                  fullWidth
                  component={RouterLink}
                  to={'destination/inspections'}
                >
                  {t('Inspections', 'Inspections')}
                </Button>
              </Box>
            </RequireAuth>

            <RequireAuth roles={routeRoles.pictures} justHideWhenWrongRole>
              <Box>
                <Button 
                  variant='outlined' 
                  size='large'
                  fullWidth
                  component={RouterLink}
                  to={'destination/pictures'}              
                >
                  {t('Pictures', 'Pictures')}
                </Button>
              </Box>
            </RequireAuth>

            <RequireAuth roles={routeRoles.temperatureRecorders} justHideWhenWrongRole>
              <Box>
                <Button 
                  variant='outlined' 
                  size='large'
                  fullWidth
                  component={RouterLink}
                  to={'destination/temperature-recorders'}              
                >
                  {t('TempRecorders', 'Temp. recorders')}
                </Button>
              </Box>
            </RequireAuth>

            <RequireAuth roles={routeRoles.hostedReports} justHideWhenWrongRole>
              <Box>
                <Button 
                  variant='outlined' 
                  size='large'
                  fullWidth
                  component={RouterLink}
                  to={'destination/hosted-reports'}              
                >
                  {t('HostedReports', 'Hosted reports')}
                </Button>
              </Box>
            </RequireAuth>

            <RequireAuth roles={routeRoles.warehouseOrders} justHideWhenWrongRole>
              <Box>
                <Button 
                  variant='outlined' 
                  size='large'
                  fullWidth
                  component={RouterLink}
                  to={'destination/warehouse-orders'}              
                >
                  {t('WarehouseOrders', 'Warehouse orders')}
                </Button>
              </Box>
            </RequireAuth>

          </Stack>

          <Typography sx={{flexGrow: 2, fontStyle: 'italic', pt: 6}}>
            *{ t('NavigationLinkInstructions', 'Use the links above to navigate to the information needed.') }
          </Typography>

        </Paper>

      </Paper>
      
    </Container>
  )
}