import * as React from 'react'
import { useNavigate  } from 'react-router-dom'
import { Grid, Alert, Autocomplete, Box, Button, Divider, LinearProgress, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useAppState } from 'src/hooks/useAppState'
import { SelectedFilters } from 'src/typeDefinitions/appTypes'
import { Filters } from 'src/components/Filters'
import { Pagination } from 'src/components/Pagination'
import { Download } from '@mui/icons-material'
import { PictureCard } from 'src/components/PictureCard'
import { useDownloadFileMutation, FileType, useGetAllPicturesQuery, useGetPictureFilterOptionsQuery, Picture } from 'src/utils/__generated__/graphql'
import { usePictureQueryFilter } from './usePictureQueryFilter'
import { useConvertRawPictureFiltersToFilterOptions } from './useConvertRawPictureFiltersToFilterOptions'
import { PictureViewerDialog } from 'src/components/PictureViewerDialog'
import { ErrorAlert } from 'src/components/ErrorAlert'

export const PicturesRoute = () => {
  const { settings } = useAppState()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { seasonId } = useAppState()
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters>({
    locations: [],
    importers: [],
    exporters: [],
    commodities: [],
    varieties: [],
    growers: [],
    sizes: [],
    labels: [],
    packCodes: []
  })
  const { variables, filterOptionsVariables, maxPageSize, setPageFilter } = usePictureQueryFilter(selectedFilters, seasonId)
  const { loading, error, data } = useGetAllPicturesQuery({
    variables: variables
  })
  const dataGrouped = GroupPictures(data?.pictures?.nodes as Picture[])
  const { loading: loadingFilters, error: filterError, data: filterData } = useGetPictureFilterOptionsQuery({
    variables: filterOptionsVariables
  })
  const filtersOptions = useConvertRawPictureFiltersToFilterOptions(filterData)
  const [open, setOpen] = React.useState(false)
  const [selectedPicture, setSelectedPicture] = React.useState<Picture>()
  const [downloadFile, {loading: loadingDownloadFile, error: downloadFileError}] = useDownloadFileMutation()

  const handleFiltersSelected = (newSelectedFilters) => {
    setPageFilter({
      last: null,
      before: null,
      first: maxPageSize,
      after: null
    })
    setSelectedFilters(newSelectedFilters)
  }  

  const handleClick = (picture) => {
    setSelectedPicture(picture)
    setOpen(true)
  }

  const handleDownloadClick = async (fileType: FileType) => {
    const result = 
      await downloadFile({variables: {
        input: {
          fileType,
          filter: { pictureFilters: {...filterOptionsVariables.filterInput} }
        }
      }})
      
      if (!result?.data?.downloadFile?.downloadRequest?.id) {
        // Request failed, we don't need to do anything here as error will be available on hook.
      }
      else {
        navigate('/account/my-downloads')
      }
  }

  return (
    <>
    
      <Paper
        elevation={0}
        sx={{ p: '1rem' }}
      >
        <Stack sx={{background: 'mainBackground.main'}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent='space-between' sx={{paddingBottom: 2}} >
            <Typography variant='h6' sx={{flexGrow: 2}}>
                {t('Pictures', 'Pictures')}
            </Typography>                            
          </Stack>

          <Filters 
            loading={loadingFilters}
            filtersOptions={filtersOptions} 
            filterUiData={[{
              title: t('Location', 'Location'),
              filterType: 'locations',
              filterComponentType: 'Standard'
            }, {
              title: t('Arrival', 'Arrival'),
              filterType: 'arrivals',
              filterComponentType: 'Standard'
            }, {
              title: t('Importer', 'Importer'),
              filterType: 'importers',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Exporter', 'Exporter'),
              filterType: 'exporters',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: `${t('Commodity', 'Commodity')} & ${t('Variety', 'Variety')}`,
              filterType: 'commodities',
              screenSize: 'md',
              filterComponentType: 'Commodity'
            }, {
              title: t('Grower', 'Grower'),
              filterType: 'growers',
              screenSize: 'lg',
              filterComponentType: 'Standard'
            }, {
              title: t('Size', 'Size'),
              filterType: 'sizes',
              screenSize: 'lg',
              filterComponentType: 'Standard'
            }, {
              title: t('Label', 'Label'),
              filterType: 'labels',
              screenSize: 'lg',
              filterComponentType: 'Standard'
            }, {
              title: t('PackCode', 'Pack code'),
              filterType: 'packCodes',
              screenSize: 'lg',
              filterComponentType: 'Standard'
            }]}
            selectedFilters={selectedFilters}        
            onFiltersSelected={handleFiltersSelected} 
          />
        </Stack>

      </Paper>
      <Paper
        elevation={0}
        sx={{ p: '1rem', backgroundColor: 'baseBackground.main', minHeight: '100vh' }}
      >
        { loading ? <LinearProgress />
        : !!(error || filterError) ? <ErrorAlert error={error || filterError} /> :
        <>
          <Box sx={{paddingBottom: '2rem'}}>
            <Typography variant='caption'> { t('Results', 'Results') }: {data?.pictures?.totalCount ?? 0}</Typography>
          </Box>

          <Stack
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            spacing={2}          
          >

          <Box display='grid' gridTemplateColumns={{xs: 'minmax(0, 1fr)', lg: 'repeat(2, minmax(0, 1fr))'}} gap={2} >
            
            { dataGrouped?.map(dataGroup => (
              <>
                
                
                <Box sx={{  backgroundColor: 'mainBackground.main' }}>                  

                  <Box sx={{fontSize: '.8rem', fontWeight: 700, textAlign: 'center', marginTop: '.5rem'}}>
                    <Box component='span' sx={{fontSize: '.8rem', fontWeight: 300, textAlign: 'center', marginTop: '.5rem'}}>
                      {dataGroup[0].pallet?.arrivalImpExp?.arrival?.generalWorkingDate?.substring(0, 10)}
                    </Box>
                    {' '}
                    {dataGroup[0].pallet?.arrivalImpExp?.arrival?.arrivalName} {' - '}
                    {dataGroup[0].pallet?.arrivalImpExp?.impExp?.importer?.importerName} {' - '}
                    {dataGroup[0].pallet?.arrivalImpExp?.impExp?.exporter?.exporterName}
                  </Box>

                  <Box sx={{fontSize: '1.2rem', fontWeight: 500, textAlign: 'center', marginTop: '.5rem'}}>
                    { 
                      t(
                        `Commodity-${ dataGroup[0].pallet?.varietyImpExp?.variety?.commodity?.commodityName }`,
                        dataGroup[0].pallet?.varietyImpExp?.variety?.commodity?.commodityName
                      )
                    } {' - '}
                    {dataGroup[0].pallet?.varietyImpExp?.variety?.varietyName} 
                    
                    {dataGroup[0].pallet?.growerImpExp?.growerCode &&        
                      <Box component='span' sx={{fontSize: '1rem', fontWeight: 300, textAlign: 'center', marginTop: '.5rem'}}>
                        {' - '} {dataGroup[0].pallet?.growerImpExp?.growerCode}        
                      </Box>
                    }
                  </Box>

                  
                  <Grid container spacing={1}>
                    {dataGroup.map(picture => (
                      <Grid item>
                        <PictureCard 
                          url={`${settings.psaV2ApiUrl}picture/${picture.websiteId}?thumbnail=true`}
                          alt={picture.description}
                          tags={[picture.description]}
                          onClick={()=>{handleClick(picture)}}
                        />
                      </Grid>
                    ))}
                  </Grid>

            
                  
                </Box>
              </>
            ))}
                

          </Box>

          </Stack>
          
          <Pagination 
            maxPageSize={maxPageSize}
            totalPages={Math.ceil(data?.pictures?.totalCount / maxPageSize)}
            pageInfo={data?.pictures?.pageInfo}            
            setPageFilter={setPageFilter}
          />

          <Box mt={6} mb={6}>            
            <Button 
              variant='outlined'                 
              startIcon={<Download />} 
              size='large'
              disabled={loadingDownloadFile || (data?.pictures?.nodes?.length ?? 0) === 0}
              onClick={() => {
                handleDownloadClick(FileType.PalletPictureZip)
              }}
            >            
              { t('DownloadAllPictures', 'Download all pictures (ZIP)') }
            </Button>
          </Box>          
          
        </>
        }  
      </Paper>

      { open &&
        <PictureViewerDialog 
          title={''}
          open={open} 
          initialPicture={selectedPicture} 
          pictures={(data.pictures?.nodes ?? []) as Picture[]} 
          onClose={()=>{setOpen(false)}}
        />
      }
      
    </>
  )
}

// Group data into array of arrays of records. Group child arrays by keys passed.
// original code from https://stackoverflow.com/questions/46794232/group-objects-by-multiple-properties-in-array-then-sum-up-their-values
function GroupPictures(data: Picture[]) {
  if (!data || data.length === 0)
    return
  
  const result = [...data.reduce((r, o) => {
    const key =  
      `${o.pallet.arrivalImpExp.impExp.importerId}-`
      + `${o.pallet.arrivalImpExp.impExp.exporterId}-`
      + `${o.pallet.arrivalImpExp.arrivalId}-`
      + `${o.pallet.varietyImpExp.variety.commodityId}-`
      + `${o.pallet.varietyImpExpId}-`
      + `${o.pallet.growerImpExpId}`
    
    const item = r.get(key) || []
    item.push(o)

    return r.set(key, item)
  }, new Map).values()]

  return result as Picture[][]
}

