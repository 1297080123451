import { Box, LinearProgress, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'

import { useAppState } from 'src/hooks/useAppState'
import { Picture, useGetInspectionGroupPicturesQuery } from 'src/utils/__generated__/graphql'
import { PictureViewerDialog } from 'src/components/PictureViewerDialog'
import { useTranslation } from 'react-i18next'

interface Props {
  palletIds: number[]
}

export const InspectionGroupPictures = ({
  palletIds
}: Props) => {
  const { settings } = useAppState()
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const [selectedPicture, setSelectedPicture] = React.useState<Picture>()
  const {data, loading, error} = useGetInspectionGroupPicturesQuery({
    variables: {
      where: {or:
        palletIds.map( (id) => ({id: {eq: id}}) )
      }
    }
  })
  const pictures = React.useMemo(() => (
    Array.prototype.concat.apply(
      [],
      data?.pallets?.nodes?.map(pallet => pallet.pictures)
    )
  ), [data])  

  const handleClick = (picture) => {
    setSelectedPicture(picture)
    setOpen(true)
  }

  if (loading) return (<div><LinearProgress /></div>)
  if (error) return (<p>{ t('Error', 'Error') }: {JSON.stringify(error)}</p>)
  
  return (
    <>

      <Box>
        {data?.pallets?.nodes?.map(pallet => pallet.pictures?.map(picture => {
          return (
            <span 
              key={picture.websiteId}
              style={{cursor: 'pointer'}}              
            >
              <img 
                src={`${settings.psaV2ApiUrl}picture/${picture.websiteId}?thumbnail=true`}
                alt={picture.description}
                onClick={()=>{handleClick(picture)}}
              />
            </span>
          )
        }))}
      </Box>

      { open &&
        <PictureViewerDialog 
          title={''}
          open={open} 
          initialPicture={selectedPicture} 
          pictures={pictures} 
          onClose={()=>{setOpen(false)}}
        />
      }

    </>
  )
}