import React from 'react'
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { Box, Breakpoint, Drawer, Toolbar, useMediaQuery, useTheme } from '@mui/material';

import { FilterProps } from 'src/typeDefinitions/appTypes'

interface Props extends FilterProps {
  screenSize?: Breakpoint,
  popupComponent: any,
}

export const FilterInline = ({
  title,
  screenSize = 'xs',
  popupComponent,
  onDone,
  ...restOfProps
}: Props) => {
  
  const PopupComponent = popupComponent
  
  return (    
    <>            
      { 
        <PopupComponent 
          title={title} 
          hideTitle 
          {...restOfProps} 
          onDone={onDone} 
        />     
      }
    </>
  )
}