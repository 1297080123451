import React from 'react'

import { PageFilter, SelectedFilters } from 'src/typeDefinitions/appTypes'
import { InputMaybe, WarehouseOrderFilterInput, WarehouseOrderFiltersInput } from 'src/utils/__generated__/graphql'

type Variables = {
  seasonId,
  filter
}

type FilterOptionsVariables = {
  filterInput: WarehouseOrderFiltersInput
}

/**
  * Takes the selected filters and converts them into friendly GraphQL variables.
  * @param selectedFilters filters that the user has selected
  * @param seasonId seasonId filter
  * @returns object \{ variables \} to use in GraphQL query to filter the data and filterOptions
  */
export const useWarehouseOrderQueryFilter = (selectedFilters: SelectedFilters, seasonId: number) => {
  const maxPageSize = 30
  const [pageFilter, setPageFilter] = React.useState<PageFilter>({
    last: null,
    before: null,
    first: maxPageSize,
    after: null
  })

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto"
    })
  }, [[selectedFilters, seasonId, pageFilter]])
  
  const filterOptionsVariables = React.useMemo<FilterOptionsVariables>(
    () => {
      // Prepare reusable local variables.      
      let locationIds: number[] = selectedFilters?.locations?.map(f => f.value as number)
      let importerIds: number[] = selectedFilters?.importers?.map(f => f.value as number)      
      let pickupNumbers: string[] = selectedFilters?.pickupNumbers?.map(f => f.value as string)
      

      // FilterInput *****************************************************************
      let filterInput = {
        seasonId,
      }
      
      // Locations
      if (!!locationIds && locationIds.length > 0)
        filterInput['locationIds'] = locationIds              

      // Importers
      if (!!importerIds && importerIds.length > 0)
        filterInput['importerIds'] = importerIds

      // Pickup Numbers
      if (!!pickupNumbers && pickupNumbers.length > 0)
        filterInput['pickupNumbers'] = pickupNumbers
      
      return {        
        filterInput: filterInput        
      }
    }, 
    [selectedFilters, seasonId]
  )

  const variables = React.useMemo<Variables>(
    () => {
      // Prepare reusable local variables.      
      let locationIds: number[] = selectedFilters?.locations?.map(f => f.value as number)
      let importerIds: number[] = selectedFilters?.importers?.map(f => f.value as number)
      let pickupNumbers: string[] = selectedFilters?.pickupNumbers?.map(f => f.value as string)
      

      // GroupFilter *****************************************************************
      let groupFilterDraft: InputMaybe<Array<WarehouseOrderFilterInput>> = []
   
      if (Boolean(seasonId))
        groupFilterDraft.push({seasonId: {eq: seasonId}})

      // Locations
      if (locationIds?.length > 0) {
        let locationFilter: WarehouseOrderFilterInput = 
        { locationId: {in:
          locationIds
        }}
        groupFilterDraft.push(locationFilter)
      }

      // Importers
      if (importerIds?.length > 0) {
        let importerFilter: WarehouseOrderFilterInput = 
          {importerId: {in:
            importerIds
          }}
        groupFilterDraft.push(importerFilter)
      }

      // PickupNumbers
      if (pickupNumbers?.length > 0) {
        let pickupNumberFilter: WarehouseOrderFilterInput =         
          {pickupNumber: {in: 
            pickupNumbers
          }}
        groupFilterDraft.push(pickupNumberFilter)
      }
            
      const filter: WarehouseOrderFilterInput = {      
        and: groupFilterDraft                    
      }


      return {
        last: pageFilter.last,
        before: pageFilter.before,
        first: pageFilter.first,
        after: pageFilter.after,
        seasonId,
        filter
      }
    }, 
    [selectedFilters, seasonId, pageFilter]
  )

  return {
    variables,
    filterOptionsVariables,
    maxPageSize,
    setPageFilter
  }
}