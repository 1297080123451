import { Alert, Box, Button, Container, Paper, Stack, TextField, Typography } from '@mui/material'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate  } from 'react-router-dom'

import contact from 'src/assets/images/contact.jpg'
import { useContactPsaMutation } from 'src/utils/__generated__/graphql';

export const ContactRoute = () => {
  const [contactPsa, {loading: contactPsaLoading, error: contactPsaError}] = useContactPsaMutation()
  const navigate = useNavigate()
  const { t } = useTranslation()  
  const {
    control,
    handleSubmit,    
    formState: { errors }
  } = useForm({
    defaultValues: {
      firstName: null,
      lastName: null,    
      companyName: null,
      address1: null,
      address2: null,
      city: null,
      state: null,
      zipCode: null,
      country: null,
      phoneNumber: null,
      faxNumber: null,
      email: null,
      comments: null
    },
    mode: 'onBlur'
  })

  const onSubmit = async (data) => {
    await contactPsa({variables:
      { inquiry: data }
    })
    navigate('/')
  }

  return (
    <>    
      <Paper
        elevation={0}
        sx={{ 
          p: '1rem',
          minHeight: '100vh',
          backgroundImage: `url(${contact})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container maxWidth='xl'>
            
            <Box
              sx={{
                backgroundColor: 'mainBackground.main',
                opacity: .975,
                borderRadius: 5,
                padding: '1rem'
              }}
            >              
              <Typography variant='h3' mb={6}>
                { t('ContactPsa', 'Contact PSA') }
              </Typography>
              
              <p>{ t('ContactPsaMessage1', 'All messages are usually answered within 2 business days during normal business hours.') }</p>

              <p>{ t('ContactPsaMessage2', 'Thank you for visiting our web site.') }</p>

              <Stack spacing={2}>
                
                <Controller
                  name='firstName'
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 50,                    
                  }}
                  render={({ field }) => (
                    <TextField label={ t('FirstName', 'First name') } variant="outlined" fullWidth 
                      {...field}
                      error={Boolean(errors?.firstName)}
                      helperText={
                        errors?.firstName?.type === 'required' ? 'This field is required!' :
                        errors?.firstName?.type === 'maxLength' ? 'Cannot exceed 50 characters!' : ''
                      }
                    />
                  )}
                />
                
                <Controller
                  name='lastName'
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 50,                    
                  }}
                  render={({ field }) => (
                    <TextField label={ t('LastName', 'Last name') } variant="outlined" fullWidth 
                      {...field}
                      error={Boolean(errors?.lastName)}
                      helperText={
                        errors?.lastName?.type === 'required' ? 'This field is required!' :
                        errors?.lastName?.type === 'maxLength' ? 'Cannot exceed 50 characters!' : ''
                      }
                    />
                  )}
                />

                <Controller
                  name='companyName'
                  control={control}
                  rules={{
                    required: false,
                    maxLength: 100,                    
                  }}
                  render={({ field }) => (
                    <TextField label={ t('Company', 'Company') } variant="outlined" fullWidth 
                      {...field}
                      error={Boolean(errors?.companyName)}
                      helperText={
                        errors?.companyName?.type === 'maxLength' ? 'Cannot exceed 100 characters!' : ''
                      }
                    />
                  )}
                />

                <Controller
                  name='address1'
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 300,                    
                  }}
                  render={({ field }) => (
                    <TextField label={`${ t('Address', 'Address') }1`} variant="outlined" fullWidth 
                      {...field}
                      error={Boolean(errors?.address1)}
                      helperText={
                        errors?.address1?.type === 'required' ? 'This field is required!' :
                        errors?.address1?.type === 'maxLength' ? 'Cannot exceed 300 characters!' : ''
                      }
                    />
                  )}
                />

                <Controller
                  name='address2'
                  control={control}
                  rules={{
                    required: false,
                    maxLength: 300,                    
                  }}
                  render={({ field }) => (
                    <TextField label={`${ t('Address', 'Address') }2`} variant="outlined" fullWidth 
                      {...field}
                      error={Boolean(errors?.address2)}
                      helperText={
                        errors?.address2?.type === 'maxLength' ? 'Cannot exceed 300 characters!' : ''
                      }
                    />
                  )}
                />
                
                <Box>
                  <Controller
                    name='city'
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 100,                      
                    }}
                    render={({ field }) => (
                      <TextField label={ t('City', 'City') } variant="outlined" 
                        {...field}
                        error={Boolean(errors?.city)}
                        helperText={
                          errors?.city?.type === 'required' ? 'This field is required!' :
                          errors?.city?.type === 'maxLength' ? 'Cannot exceed 100 characters!' : ''
                        }
                      />
                    )}
                  />

                  <Controller
                    name='state'
                    control={control}
                    rules={{
                      required: true,
                      maxLength: 20,                      
                    }}
                    render={({ field }) => (
                      <TextField label={ t('State', 'State') } variant="outlined" 
                        {...field}
                        error={Boolean(errors?.state)}
                        helperText={
                          errors?.state?.type === 'required' ? 'This field is required!' :
                          errors?.state?.type === 'maxLength' ? 'Cannot exceed 20 characters!' : ''
                        }
                      />
                    )}
                  />

                  <Controller
                    name='zipCode'
                    control={control}
                    rules={{
                      required: false,
                      minLength: 1,
                      maxLength: 5
                    }}
                    render={({ field }) => (
                      <TextField label={ t('Zip', 'Zip') } variant="outlined" 
                        {...field}
                        type='number'
                        error={Boolean(errors?.zipCode)}
                        helperText={
                          errors?.zipCode?.type === 'required' ? 'This field is required!' :
                          errors?.zipCode?.type === 'minLength' ? 'Cannot exceed 5 characters!' :
                          errors?.zipCode?.type === 'maxLength' ? 'Cannot exceed 5 characters!' : ''
                        }
                      />
                    )}
                  />                  
                </Box>
                
                <Controller
                  name='country'
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 100,                    
                  }}
                  render={({ field }) => (
                    <TextField label={ t('Country', 'Country') } variant="outlined" fullWidth 
                      {...field}
                      error={Boolean(errors?.country)}
                      helperText={
                        errors?.country?.type === 'required' ? 'This field is required!' :
                        errors?.country?.type === 'maxLength' ? 'Cannot exceed 100 characters!' : ''
                      }
                    />
                  )}
                />

                <Controller
                  name='phoneNumber'
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 15,                    
                  }}
                  render={({ field }) => (
                    <TextField label={ t('Phone', 'Phone') } variant="outlined" fullWidth 
                      {...field}
                      error={Boolean(errors?.phoneNumber)}
                      helperText={
                        errors?.phoneNumber?.type === 'required' ? 'This field is required!' :
                        errors?.phoneNumber?.type === 'maxLength' ? 'Cannot exceed 15 characters!' : ''
                      }
                    />
                  )}
                />

                <Controller
                  name='faxNumber'
                  control={control}
                  rules={{
                    required: false,
                    maxLength: 15,                    
                  }}
                  render={({ field }) => (
                    <TextField label={ t('Fax', 'Fax') } variant="outlined" fullWidth 
                      {...field}
                      error={Boolean(errors?.faxNumber)}
                      helperText={
                        errors?.faxNumber?.type === 'required' ? 'This field is required!' :
                        errors?.faxNumber?.type === 'maxLength' ? 'Cannot exceed 15 characters!' : ''
                      }
                    />
                  )}
                />

                <Controller
                  name='email'
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 100,                    
                  }}
                  render={({ field }) => (
                    <TextField label={ t('Email', 'Email') } variant="outlined" fullWidth 
                      {...field}
                      error={Boolean(errors?.email)}
                      helperText={
                        errors?.email?.type === 'required' ? 'This field is required!' :
                        errors?.email?.type === 'maxLength' ? 'Cannot exceed 100 characters!' : ''
                      }
                    />
                  )}
                />

                <Controller
                  name='comments'
                  control={control}
                  rules={{
                    required: true,
                    maxLength: 4000,                    
                  }}
                  render={({ field }) => (
                    <TextField label={ t('Comments', 'Comments') } variant="outlined" fullWidth multiline 
                      {...field}
                      error={Boolean(errors?.comments)}
                      helperText={
                        errors?.comments?.type === 'required' ? 'This field is required!' :
                        errors?.comments?.type === 'maxLength' ? 'Cannot exceed 4000 characters!' : ''
                      }
                    />
                  )}
                />
                                
                { contactPsaError?.graphQLErrors != null &&
                  <Alert severity="error">
                    { t('Error', 'Error') }: {contactPsaError?.graphQLErrors?.map(e=>e.message)}
                  </Alert>
                }

                <Box>
                  <Button 
                    variant='contained' 
                    disabled={contactPsaLoading}
                    type='submit'
                  >
                    { t('Send', 'Send asdfasdf') }
                  </Button>
                </Box>
              </Stack>
            </Box>


          </Container>
        </form>
      </Paper>
    </>
  )
}
