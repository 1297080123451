import React from 'react'

import { FilterOptionObject, FiltersOptions } from 'src/typeDefinitions/appTypes'
import { FilterOptionsQuery } from 'src/utils/__generated__/graphql'


/**
  * Takes the raw filterOption data from server and converts to a UI friendly format
  * @param filterData raw data from server
  * @returns UI friendly and consistent filterOptions
  */
export const useConvertRawFiltersToFilterOptions = (filterData: FilterOptionsQuery) => {
  const inspectionGroupVariables = React.useMemo<FiltersOptions>(
    () => {
      
      let locations = filterData?.inspectionFilterOptions?.locationFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.locationId,
          displayText: `${f?.location?.locationName}`
        }
      }) ?? []
      locations.sort((a, b) => a.displayText.localeCompare(b.displayText))
      
      let arrivals = filterData?.inspectionFilterOptions?.arrivalFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: `${f.arrivalId} ${f.arrivalId}`,          
          displayText: `${f.arrival.generalWorkingDate.slice(0, 10)} - ${f.arrival.arrivalName} - ${f.arrivalCode}`,
          arrivalId: f.arrivalId,
          arrivalCode: f.arrivalCode
        }
      }) ?? []
      arrivals.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let importers = filterData?.inspectionFilterOptions?.importerFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.importerId,
          displayText: f?.importer?.importerName
        }
      }) ?? []
      importers.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let exporters = filterData?.inspectionFilterOptions?.exporterFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.exporterId,
          displayText: f?.exporter?.exporterName
        }
      }) ?? []
      exporters.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let commodities = filterData?.inspectionFilterOptions?.commodityFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.commodityId,
          displayText: f?.commodity?.commodityName,          
        }
      }) ?? []
      commodities.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let varieties = filterData?.inspectionFilterOptions?.varietyFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.varietyId,
          displayText: f?.variety?.varietyName,
          commodityId: f?.variety?.commodityId,
        }
      }) ?? []
      varieties.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let growers = filterData?.inspectionFilterOptions?.growerFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.growerId,
          displayText: f?.grower?.growerName
        }
      }) ?? []
      growers.sort((a, b) => a.displayText.localeCompare(b.displayText))
      
      let sizes = filterData?.inspectionFilterOptions?.sizeFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f,
          displayText: f
        }
      }) ?? []
      sizes.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let labels = filterData?.inspectionFilterOptions?.labelFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.labelCode,
          displayText: `${f?.labelCode} ${f?.labelName ?? ''}`
        }
      }) ?? []
      labels.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let packCodes = filterData?.inspectionFilterOptions?.packTypeFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.packCode,
          displayText: `${f?.packCode} ${f?.packDescription ?? ''}`
        }
      }) ?? []
      packCodes.sort((a, b) => a.displayText.localeCompare(b.displayText))
      
      const temp: FiltersOptions = {
        locations,
        arrivals,
        importers,
        exporters,
        commodities,
        varieties,
        growers,        
        sizes,
        labels,
        packCodes
      }
        
      return temp
    }, [filterData]
  )
  return inspectionGroupVariables
}