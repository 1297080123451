import React from 'react'
import { Autocomplete, AutocompleteValue, TextField } from "@mui/material"
import { useTranslation } from 'react-i18next'

import { useGetSeasonsQuery } from 'src/utils/__generated__/graphql'
import { useAppState } from 'src/hooks/useAppState'

type Lookup = {
  label: string,
  id: number
}
export const SeasonSelector = () => {
  const { t } = useTranslation()
  const {data, loading, error} = useGetSeasonsQuery()
  const { seasonId, notifySeasonChanged } = useAppState()

  const seasonsLookup = React.useMemo<Lookup[]>(() => {
    if (!Boolean(data)) {
      return []
    }
    else {
      return data.seasons.map(d => ( { label: d.seasonName, id: d.id } ))
    }
  }, [data])

  const handleChange = (event: React.SyntheticEvent, value: Lookup) => {
    notifySeasonChanged(value.id)
  }

  if (loading) return null

  return (
    <Autocomplete
      disablePortal
      id="season-selection"
      options={seasonsLookup}
      value={seasonsLookup.find(s => s.id === seasonId) ?? seasonsLookup[0]}
      size='small'
      sx={{ width: 140 }}
      selectOnFocus
      clearOnBlur
      disableClearable            
      renderInput={({InputProps, ...params}) => (              
        <TextField 
          {...params} 
          label={`${t('Season', 'Season')} `}
          InputProps={{ ...InputProps, style: { fontSize: 12 } }}                          
        />
      )}
      onChange={handleChange}
    />
  )  
}