import React from 'react'

import { FilterOptionObject, FiltersOptions } from 'src/typeDefinitions/appTypes'
import { GetHostedReportFilterOptionsQuery } from 'src/utils/__generated__/graphql'


/**
  * Takes the raw filterOption data from server and converts to a UI friendly format
  * @param filterData raw data from server
  * @returns UI friendly and consistent filterOptions
  */
export const useConvertRawHostedReportFiltersToFilterOptions = (filterData: GetHostedReportFilterOptionsQuery) => {
  const groupVariables = React.useMemo<FiltersOptions>(
    () => {
      
      let locations = filterData?.hostedReportFilterOptions?.locationFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.locationId,
          displayText: `${f?.location?.locationName}`
        }
      }) ?? []
      locations.sort((a, b) => a.displayText.localeCompare(b.displayText))          

      let importers = filterData?.hostedReportFilterOptions?.importerFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.importerId,
          displayText: f?.importer?.importerName
        }
      }) ?? []
      importers.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let exporters = filterData?.hostedReportFilterOptions?.exporterFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.exporterId,
          displayText: f?.exporter?.exporterName
        }
      }) ?? []
      exporters.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let arrivalNames = filterData?.hostedReportFilterOptions?.arrivalNameFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f,
          displayText: f
        }
      }) ?? []
      arrivalNames.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let containers = filterData?.hostedReportFilterOptions?.containerFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f,
          displayText: f
        }
      }) ?? []
      containers.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let lotCodes = filterData?.hostedReportFilterOptions?.lotCodeFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f,
          displayText: f
        }
      }) ?? []
      lotCodes.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let pos = filterData?.hostedReportFilterOptions?.poFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f,
          displayText: f
        }
      }) ?? []
      pos.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let growers = filterData?.hostedReportFilterOptions?.growerFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f,
          displayText: f
        }
      }) ?? []
      growers.sort((a, b) => a.displayText.localeCompare(b.displayText))
      
      let statuses = filterData?.hostedReportFilterOptions?.hostedReportStatusFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f,
          displayText: f
        }
      }) ?? []
      statuses.sort((a, b) => a.displayText.localeCompare(b.displayText))
      
      let programs = filterData?.hostedReportFilterOptions?.hostedReportProgramFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f,
          displayText: f
        }
      }) ?? []
      programs.sort((a, b) => a.displayText.localeCompare(b.displayText))
      

      const temp: FiltersOptions = {
        locations,        
        importers,
        exporters,
        arrivalNames,
        containers,
        lotCodes,
        pos,
        growers,
        statuses,
        programs
      }
        
      return temp
    }, [filterData]
  )
  return groupVariables
}