import React from 'react'

import { FilterOptionObject, FiltersOptions } from 'src/typeDefinitions/appTypes'
import { GetTemperatureRecordersFilterOptionsQuery } from 'src/utils/__generated__/graphql'


/**
  * Takes the raw filterOption data from server and converts to a UI friendly format
  * @param filterData raw data from server
  * @returns UI friendly and consistent filterOptions
  */
export const useConvertRawTemperatureRecorderFiltersToFilterOptions = (filterData: GetTemperatureRecordersFilterOptionsQuery) => {
  const groupVariables = React.useMemo<FiltersOptions>(
    () => {
      
      let locations = filterData?.temperatureRecordersFilterOptions?.locationFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.locationId,
          displayText: `${f?.location?.locationName}`
        }
      }) ?? []
      locations.sort((a, b) => a.displayText.localeCompare(b.displayText))
      
      let arrivals = filterData?.temperatureRecordersFilterOptions?.arrivalFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: `${f.arrivalId} ${f.arrivalId}`,          
          displayText: `${f.arrival.generalWorkingDate.slice(0, 10)} - ${f.arrival.arrivalName} - ${f.arrivalCode}`,
          arrivalId: f.arrivalId,
          arrivalCode: f.arrivalCode
        }
      }) ?? []
      arrivals.sort((a, b) => a.displayText.localeCompare(b.displayText)).reverse()

      let importers = filterData?.temperatureRecordersFilterOptions?.importerFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.importerId,
          displayText: f?.importer?.importerName
        }
      }) ?? []
      importers.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let exporters = filterData?.temperatureRecordersFilterOptions?.exporterFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f?.exporterId,
          displayText: f?.exporter?.exporterName
        }
      }) ?? []
      exporters.sort((a, b) => a.displayText.localeCompare(b.displayText))

      let containers = filterData?.temperatureRecordersFilterOptions?.containerFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f,
          displayText: f
        }
      }) ?? []
      containers.sort((a, b) => a.displayText.localeCompare(b.displayText))
      
      let lots = filterData?.temperatureRecordersFilterOptions?.lotFilterOptions?.map((f): FilterOptionObject => {
        return {
          value: f,
          displayText: f
        }
      }) ?? []
      lots.sort((a, b) => a.displayText.localeCompare(b.displayText))

      const temp: FiltersOptions = {
        locations,
        arrivals,
        importers,
        exporters,
        containers,
        lots
      }
        
      return temp
    }, [filterData]
  )
  return groupVariables
}