import Box from '@mui/material/Box';
import { Tab, Tabs } from '@mui/material';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


export const HostedReportsLayoutRoute = () => {
  const { t } = useTranslation()
  const location = useLocation()
  
  return (
    <>
      <Box sx={{ marginTop: '2rem', borderBottom: 2, borderColor: 'divider', flexGrow: 1, display: { xs: 'flex' } }}>
        <Tabs 
          value={ '/destination/hosted-reports/inspection-details' === location.pathname ? 1 : 0} 
          aria-label="tabs"
          variant='scrollable'
          scrollButtons='auto'
        >
          
          <Tab 
            label={ t('HostedReports', 'Hosted reports') } 
            component={RouterLink}
            to='/destination/hosted-reports'
            {...a11yProps(0)} 
            sx={{fontSize: '1.1rem'}}
          />

          <Tab 
            label={ t('InspectionsDetails', 'Inspections details') } 
            component={RouterLink}
            to='/destination/hosted-reports/inspection-details'
            {...a11yProps(0)} 
            sx={{fontSize: '1.1rem'}}
          />
        
        </Tabs>
      </Box>

      <Outlet />

    </>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}