import * as React from 'react'
import { Button, Card, CardActions, CardContent, Grid, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import { Link as RouterLink } from 'react-router-dom'

import { SelectedFilters } from 'src/typeDefinitions/appTypes'
import { Filters } from 'src/components/Filters'
import { Pagination } from 'src/components/Pagination'
import { useGetArrivalReportFilterOptionsQuery, useGetAllArrivalReportsQuery, useDownloadFileMutation, FileType } from 'src/utils/__generated__/graphql'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useAppState } from 'src/hooks/useAppState'
import { useArrivalReportQueryFilter } from './useArrivalReportQueryFilter'
import { useConvertRawArrivalReportFiltersToFilterOptions } from './useConvertRawArrivalReportFiltersToFilterOptions'
import { ErrorAlert } from 'src/components/ErrorAlert'

export const ArrivalReportsRoute = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { seasonId } = useAppState()
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters>({
    locations: [],
    arrivals: [],
    importers: [],
    exporters: [],    
  })   
  const { variables, filterOptionsVariables, maxPageSize, setPageFilter } = useArrivalReportQueryFilter(selectedFilters, seasonId)
  const { loading, error, data } = useGetAllArrivalReportsQuery({
    variables: variables
  })
  const { loading: loadingFilters, error: filterError, data: filterData } = useGetArrivalReportFilterOptionsQuery({
    variables: filterOptionsVariables
  })
  const filtersOptions = useConvertRawArrivalReportFiltersToFilterOptions(filterData)  
  
  const handleFiltersSelected = (newSelectedFilters) => {
    setPageFilter({
      last: null,
      before: null,
      first: maxPageSize,
      after: null
    })
    setSelectedFilters(newSelectedFilters)
  }

  return (
    <>
    
      <Paper
        elevation={0}
        sx={{ p: '1rem' }}
      >
        <Stack sx={{background: 'mainBackground.main'}}>
          <Stack spacing={2} sx={{paddingBottom: 2}}>
            <Typography variant='h6' sx={{flexGrow: 2}}>{ t('ArrivalReports', 'Arrival reports') }</Typography>
          </Stack>
                      

          <Filters 
            loading={loadingFilters}
            filtersOptions={filtersOptions} 
            filterUiData={[{
              title: t('Location', 'Location'),
              filterType: 'locations',
              filterComponentType: 'Standard'
            }, {
              title: t('Arrival', 'Arrival'),
              filterType: 'arrivals',
              filterComponentType: 'Standard'
            }, {
              title: t('Importer', 'Importer'),
              filterType: 'importers',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Exporter', 'Exporter'),
              filterType: 'exporters',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }]}        
            selectedFilters={selectedFilters}
            onFiltersSelected={handleFiltersSelected}
          />
        </Stack>

      </Paper>      
      <Paper
        elevation={0}
        sx={{ p: '1rem', backgroundColor: 'baseBackground.main', minHeight: '100vh' }}
      >
        
        { loading ? <LinearProgress />
        : !!error ? <ErrorAlert error={error} /> :

        <>
          <Typography variant='caption'> { t('Results', 'Results') }: {data?.arrivalReports?.totalCount ?? 0}</Typography>

          <TableContainer component={Paper} sx={{display: {xs: 'none', md: 'block'}}}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('Location', 'Location')}</TableCell>
                  <TableCell>{t('Arrival', 'Arrival')}</TableCell>
                  <TableCell>{t('Importer', 'Importer')}</TableCell>
                  <TableCell>{t('Exporter', 'Exporter')}</TableCell>
                  <TableCell>{t('Date', 'Date')}</TableCell>                  
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.arrivalReports?.nodes?.map(data => (
                  <TableRow
                    key={data.arrivalReportLinkId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>{data?.location?.locationName}</TableCell>
                    <TableCell>{data?.arrivalImpExp?.arrival?.arrivalName}</TableCell>
                    <TableCell>{data?.arrivalImpExp?.impExp?.importer?.importerName}</TableCell>
                    <TableCell>{data?.arrivalImpExp?.impExp?.exporter?.exporterName}</TableCell>
                    <TableCell>{data?.arrivalImpExp?.arrival?.generalWorkingDate?.slice(0, 10)}</TableCell>                    
                    <TableCell>
                      <Button 
                        component={RouterLink}
                        to={`/destination/arrival-report/${data?.websiteId}/${data?.arrivalReportLinkId}`}
                        variant='outlined' 
                        endIcon={<ArrowForward/>}
                      >
                        { t('ViewReport', 'View report') }
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack spacing={2} sx={{display: {xs: 'block', md: 'none'}}}>
            {data?.arrivalReports?.nodes?.map(data => (
              <Card sx={{ minWidth: 275 }} key={data?.arrivalReportLinkId}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        {t('Location', 'Location')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.location?.locationName}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        {t('Arrival', 'Arrival')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.arrivalImpExp?.arrival?.arrivalName}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        {t('Importer', 'Importer')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.arrivalImpExp?.impExp?.importer?.importerName}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        {t('Exporter', 'Exporter')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.arrivalImpExp?.impExp?.exporter?.exporterName}
                    </Grid>
                    
                    <Grid item xs={6}>                      
                      <Typography color="text.secondary">
                        {t('Date', 'Date')}:
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>                      
                      {data?.arrivalImpExp?.arrival?.generalWorkingDate?.slice(0, 10)}
                    </Grid>
                    
                  </Grid>
                  
                  
                </CardContent>
                <CardActions sx={{justifyContent: 'flex-end'}}>

                  <Stack justifyContent='flex-end' direction='row'>                                         
                    
                    <Button 
                      component={RouterLink}
                      to={`/destination/arrival-report/${data?.websiteId}/${data?.arrivalReportLinkId}`}
                      variant='outlined' 
                      endIcon={<ArrowForward/>}
                    >
                      { t('ViewReport', 'View report') }
                    </Button>                    

                  </Stack>

                </CardActions>
             </Card>
            ))}
          </Stack>

          
          <Pagination 
            maxPageSize={maxPageSize}
            totalPages={Math.ceil((data?.arrivalReports?.totalCount ?? 0) / maxPageSize)}
            pageInfo={data?.arrivalReports?.pageInfo}
            setPageFilter={setPageFilter}
          />
          
        </>
        }  
      </Paper>
    </>
  )
}

