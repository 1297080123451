import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { Autocomplete, Box, createFilterOptions, InputAdornment, OutlinedInput } from '@mui/material'

import { FlattenedFilter } from 'src/typeDefinitions/appTypes'

interface Props {
  loading?: boolean,
  flatFiltersOptions: FlattenedFilter[],
  onSearch: (flatFilter: FlattenedFilter) => void
}
export const Search = ({
  loading,
  flatFiltersOptions,
  onSearch
}: Props) => {
  const [resetIndex, setResetIndex] = React.useState(0)

  const handleSearch = (value) => {
    onSearch(value)
    //setSearchValue(null)
    setResetIndex(resetIndex+1)
  }

  const filterOptions = createFilterOptions({
    ignoreCase: true
  })
  
  return (
    
      <Autocomplete        
        key={resetIndex}
        id="free-solo-2-demo"
        disabled={loading}
        sx={{ flex: 1, border: 0 }}    
        autoHighlight
        filterOptions={filterOptions}    
        getOptionLabel={(option: FlattenedFilter) => option.filter.displayText}
        options={flatFiltersOptions}
        renderOption={(props, options: FlattenedFilter)=><Box component='li' {...props}>{options.filter.displayText}</Box>}
        onChange={(e, value: FlattenedFilter)=>{handleSearch(value)}}
        isOptionEqualToValue={(option: FlattenedFilter, value: FlattenedFilter) => (option.filter.localeCompare(value.filter, undefined, { sensitivity: 'accent' }) === 0) }
        noOptionsText='No match found'
        renderInput={(params) => {
          return <OutlinedInput
            {...params}            
            {...params.InputProps}
            startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}            
          />
        }}
      />            
    
  )
}