import { Box, Button, Card, CardMedia, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, LinearProgress, Theme, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { ArrowCircleLeft, ArrowCircleRight, Close, NavigateBefore, NavigateNext } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useAppState } from 'src/hooks/useAppState'

import { Picture } from 'src/utils/__generated__/graphql'

type Props = {
  title?: string,
  open: boolean,
  initialPicture: Picture,
  pictures: Picture[],  
  onClose: () => void
}

export const PictureViewerDialog = ({
  title,
  open,
  initialPicture,
  pictures,  
  onClose
} : Props) => {  
  const { settings } = useAppState()
  const { t } = useTranslation()
  const [currentPicture, setCurrentPicture] = React.useState(initialPicture)
  const theme = useTheme<Theme>()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const handleImageClick = () => {
    nextImage()    
  }

  const handlePreviousClick = () => {
    previousImage()
  }

  const handleNextClick = () => {
    nextImage()
  }

  const previousImage = () => {
    for (let i = 0; i < pictures.length; i++)
    {      
      if (pictures[i].websiteId === currentPicture.websiteId) {
        if (i === 0) {
          onClose()
          return
        }

        setCurrentPicture(pictures[i-1])
        return
      }
    }
  }

  const nextImage = () => {    
    for (let i = 0; i < pictures.length; i++)
    {      
      if (pictures[i].websiteId === currentPicture.websiteId) {
        if (i === pictures.length - 1) {
          onClose()
          return
        }

        setCurrentPicture(pictures[i+1])
        return
      }
    }
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth='lg'        
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >                
        { Boolean(title) &&
          <DialogTitle id="responsive-dialog-title">
            {title}
          </DialogTitle>
        }

        <div style={{ width: '2000px' }}>{/*force this screen to take full width*/}</div>
        <div style={{ position: 'relative', width: '100%' }}>
          <Box>
          
              <Box
                sx={{
                  cursor: 'pointer',
                  backgroundImage: `url(${settings.psaV2ApiUrl}picture/${currentPicture?.websiteId})`,            
                  flex: 2,
                  minHeight: 640,
                  backgroundRepeat: 'no-repeat',              
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',                                          
                }}
                onClick={handleImageClick}
              ></Box>
              {false && <CardMedia
                component='img'
                image={`${settings.psaV2ApiUrl}picture/${currentPicture?.websiteId}`}
              >                
              </CardMedia>}
                      

            <Box
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                width: 80,
                color: 'white',
                padding: '10px',                
              }}              
            >              
              { pictures[0]?.websiteId !== currentPicture.websiteId &&
                <Box sx={{pt: '10rem'}}>
                  <Fab color="primary" aria-label={ t('Previous', 'Previous') } onClick={handlePreviousClick}>
                    <NavigateBefore />
                  </Fab>
                </Box>
              }
            </Box>

            <Box
              sx={{
                cursor: 'pointer',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                width: 80,            
                color: 'white',
                padding: '10px',                
              }}
              onClick={handleNextClick}
            >              

              <Box sx={{height: '2rem', mt: '-1rem', mr: '0.5rem'}}>
                <Fab 
                  aria-label="close" 
                  sx={{
                    fontSize: '2em', 
                    backgroundColor: 'baseBackground.main',
                    '&:hover': {                      
                      backgroundColor: 'mainBackground.main'
                    }
                  }} 
                  onClick={onClose}
                >
                  <Close />
                </Fab>                
              </Box>

              <Box sx={{paddingTop: '9rem'}}>
                <Fab color="primary" aria-label={ t('Next', 'Next') } sx={{fontSize: '2em'}}>
                  <NavigateNext />
                </Fab>                
              </Box>
            </Box>
          
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              {currentPicture.description}
            </Box>

        </Box>

        </div>          
        <Box>
          {pictures?.map(picture => {
            return (
              <img 
                src={`${settings.psaV2ApiUrl}picture/${picture.websiteId}?thumbnail=true`}
                alt={picture.description}
                onClick={()=>{setCurrentPicture(picture)}}
              />
            )
          })}
        </Box>

        <DialogActions>
          <Button onClick={onClose}>
            Close
          </Button>             
        </DialogActions>

      </Dialog>
    </div>
  )
}