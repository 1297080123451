import { ApolloError } from "@apollo/client"
import { Alert } from "@mui/material"
import { useTranslation } from "react-i18next"

interface Props {
  error: ApolloError
}

export const ErrorAlert = ({
  error
}: Props) => {
  const { t } = useTranslation()  
  
  var troubleshooting = false

  if (troubleshooting)
    return <pre>{JSON.stringify(error, null, 2) }</pre>

  return (    
    <>    
    
      { !error ? null

      : !!error?.message ?
          <Alert severity="error">
            { t('Error', 'Error') }: { error?.message }
          </Alert>

        : error?.graphQLErrors?.length > 0 ?
          <Alert severity="error">
            { t('Error', 'Error') }: { error?.graphQLErrors?.map(e=>e.message) }
          </Alert>

        : error?.networkError?.result?.errors?.length > 0 ?
          <Alert severity="error">
            { t('Error', 'Error') }: { error?.networkError?.result?.errors?.map(e=>e.message) }
          </Alert>

        : null
      }
    </>
  )
}