import { Box, CssBaseline, LinearProgress } from "@mui/material"
import { Outlet } from 'react-router-dom'
import { useAuth } from "react-oidc-context"
import { ErrorBoundary } from 'react-error-boundary'

import { useTranslation } from 'react-i18next'
import { AppNavBar } from './AppNavBar'
import { ConstructionOverlay } from './ConstructionOverlay'

export const AppLayout = () => {
  const { t, i18n } = useTranslation()
  const auth = useAuth()

  const renderContent = () => {
    
    switch (auth.activeNavigator) {
      case "signinSilent":
          return <div><LinearProgress /></div>
      case "signoutRedirect":
          return <div><LinearProgress /></div>
    }
  
    if (auth.isLoading) {
      return <div><LinearProgress /></div>;
    }
  
    if (auth.error) {
        return <div>Oops... {auth.error.message}</div>;
    }

    return <Outlet />
  }

  return (
    <>
      <CssBaseline />
      
      <ConstructionOverlay>
        <AppNavBar/>
        
        <Box sx={{minHeight: '100vh'}}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            {renderContent()}
          </ErrorBoundary>       
        </Box>

        <Box
          sx={{
            color: 'white',
            backgroundColor: 'footerBackground.main',
            height: '30rem'
          }}
          p={6}
        >
          {t('PSA')} { t('Copyright', 'Copyright') } {(new Date()).getFullYear()}
        </Box>
      </ConstructionOverlay>
    </>
  )
}

function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}
