import React from 'react'
import { useAuth } from 'react-oidc-context'

export type Role = '?' | 'AdminRole' | 'Importer' | 'Exporter' | 'LocationMod' | 'LocationView' | 'Grower'

const arrivalReports: Role[] = ['AdminRole', 'LocationMod', 'LocationView', 'Importer', 'Exporter']
const arrivalReport: Role[] = ['?', 'AdminRole', 'LocationMod', 'LocationView', 'Importer', 'Exporter']
const inspections: Role[] = ['AdminRole', 'LocationMod', 'LocationView', 'Importer', 'Exporter', 'Grower']
const pictures: Role[] = ['AdminRole', 'LocationMod', 'LocationView', 'Importer', 'Exporter', 'Grower']
const temperatureRecorders: Role[] = ['AdminRole', 'LocationMod', 'LocationView', 'Importer', 'Exporter', 'Grower']
const hostedReports: Role[] = ['AdminRole', 'LocationMod', 'LocationView', 'Importer']
const hostedReport: Role[] = ['?', 'AdminRole', 'LocationMod', 'LocationView', 'Importer']
const hostedReportsInspectionDetails: Role[] = ['AdminRole', 'LocationMod', 'LocationView', 'Importer', 'Exporter']
const warehouseOrders: Role[] = ['AdminRole', 'LocationMod', 'LocationView', 'Importer']
const myDownloads: Role[] = ['AdminRole', 'LocationMod', 'LocationView', 'Importer', 'Exporter', 'Grower']

export const routeRoles = {
  arrivalReports, 
  arrivalReport,
  inspections, 
  pictures, 
  temperatureRecorders,
  hostedReports,
  hostedReport,
  hostedReportsInspectionDetails,
  warehouseOrders,
  myDownloads
}

type RequireAuthProps = {
  roles: Role[],
  justHideWhenWrongRole?: Boolean,
  children?: React.ReactElement | undefined,
}

export const RequireAuth = ({
  roles, 
  justHideWhenWrongRole = false,
  children,
}: RequireAuthProps): JSX.Element => {
  const auth = useAuth()  
  const userRole = auth?.isAuthenticated ? auth?.user?.profile?.role : '?'
  const userHasNeededRole = DoesUserHaveRole(userRole, roles)
  
  if (justHideWhenWrongRole && !userHasNeededRole) {
    return null
  }

  else if (!userHasNeededRole) {
    return <div>404 - Oops something went wrong, please navigate to another page.</div>
  }

  // Everything looks good! Show the children components!
  else {
    return children
  }

}

export const DoesUserHaveRole = (userRole, rolesPermitted) => {
  if (Array.isArray(userRole) && rolesPermitted?.some(r => userRole.includes(r))) {
    return true
  }
  else if (typeof userRole === 'string' && rolesPermitted?.some(r => r === userRole)) {
    return true
  }

  return false 
}