import React from 'react'
import { Stack, Chip, Tooltip } from '@mui/material'

import { FlattenedFilters, FilterOptionObject } from 'src/typeDefinitions/appTypes'

export interface FiltersSelectedChipsProps {
  flatSelectedFilters: FlattenedFilters,
  onDeleteFilter?: (filterType: string, filter: FilterOptionObject) => void
}

export const FiltersSelectedChips = ({
  flatSelectedFilters,
  onDeleteFilter
}: FiltersSelectedChipsProps) => {
  
  if (flatSelectedFilters.length === 0)
    return null
  
  return (
    <Stack direction='row' spacing={.5} flexWrap='wrap'>
      {flatSelectedFilters.map(pillData => {
        return (                              
          <Tooltip 
            key={pillData.filterType + ' ' + pillData.filter}
            title={pillData.filter.displayText}
          >
            <Chip 
              label={pillData.filter.displayText} 
              sx={{maxWidth:'120px', overflowX: 'hidden'}}
              variant="outlined" 
              onDelete={() => onDeleteFilter(pillData.filterType, pillData.filter)} 
            />       
          </Tooltip>                 

        )
      })}
    </Stack>      
  )
}