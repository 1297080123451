import React from 'react'
import { Toolbar, AppBar } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useTranslation } from 'react-i18next'

type Props = {
  onDone: () => void
}

export const FilterDoneBox = ({
  onDone
}: Props) => {
  const { t } = useTranslation()
  const [done, setDone] = React.useState(false)
 
  React.useEffect(() => {
    if (done) {
      onDone() 
    } 
  }, [done])

  return (    
    <>
      <Toolbar />

      <AppBar position="absolute" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar >
          <LoadingButton             
            variant="outlined"            
            sx={{                            
              backgroundColor: 'baseBackground.main', 
              "&:hover":{
                opacity: 1,
                backgroundColor: 'baseBackground.main',                
              },                      
            }}
            loading={done}
            onClick={()=>{setDone(true)}}
          >
            { t('Done', 'Done') }
          </LoadingButton>
        </Toolbar>
      </AppBar>
    </>
  )
}