import React from 'react'

import { PageFilter, SelectedFilters } from 'src/typeDefinitions/appTypes'
import { InputMaybe, TemperatureRecorderFilterInput, TemperatureRecorderFiltersInput } from 'src/utils/__generated__/graphql'

type Variables = {
  seasonId,
  filter
}

type FilterOptionsVariables = {
  filterInput: TemperatureRecorderFiltersInput
}

/**
  * Takes the selected filters and converts them into friendly GraphQL variables.
  * @param selectedFilters filters that the user has selected
  * @param seasonId seasonId filter
  * @returns object \{ variables \} to use in GraphQL query to filter the data and filterOptions
  */
export const useTemperatureRecorderQueryFilter = (selectedFilters: SelectedFilters, seasonId: number) => {
  const maxPageSize = 10
  const [pageFilter, setPageFilter] = React.useState<PageFilter>({
    last: null,
    before: null,
    first: maxPageSize,
    after: null
  })

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto"
    })
  }, [[selectedFilters, seasonId, pageFilter]])
  
  const filterOptionsVariables = React.useMemo<FilterOptionsVariables>(
    () => {
      // Prepare reusable local variables.      
      let locationIds: number[] = selectedFilters?.locations?.map(f => f.value as number)
      let importerIds: number[] = selectedFilters?.importers?.map(f => f.value as number)
      let exporterIds: number[] = selectedFilters?.exporters?.map(f => f.value as number)
      let containers: string[] = selectedFilters?.containers?.map(f => f.value as string)
      let lots: string[] = selectedFilters?.lots?.map(f => f.value as string)
      

      // FilterInput *****************************************************************
      let filterInput = {
        seasonId,
      }
      
      // Locations
      if (!!locationIds && locationIds.length > 0)
        filterInput['locationIds'] = locationIds      
    
      // Arrivals
      if (selectedFilters?.arrivals?.length > 0) {
        filterInput['arrivalFilters'] =          
          selectedFilters.arrivals.map(a => {
            return {
              arrivalId: a.arrivalId,
              arrivalCode: a.arrivalCode
            }
          })          
      }

      // Importers
      if (!!importerIds && importerIds.length > 0)
        filterInput['importerIds'] = importerIds

      // Exporters
      if (!!exporterIds && exporterIds.length > 0)
        filterInput['exporterIds'] = exporterIds

      // Containers
      if (!!containers && containers.length > 0)
        filterInput['containers'] = containers

      // Lots
      if (!!lots && lots.length > 0)
        filterInput['lots'] = lots
      
      return {        
        filterInput: filterInput        
      }
    }, 
    [selectedFilters, seasonId]
  )

  const variables = React.useMemo<Variables>(
    () => {
      // Prepare reusable local variables.      
      let locationIds: number[] = selectedFilters?.locations?.map(f => f.value as number)
      let importerIds: number[] = selectedFilters?.importers?.map(f => f.value as number)
      let exporterIds: number[] = selectedFilters?.exporters?.map(f => f.value as number)
      let containers: string[] = selectedFilters?.containers?.map(f => f.value as string)
      let lots: string[] = selectedFilters?.lots?.map(f => f.value as string)
      

      // GroupFilter *****************************************************************
      let groupFilterDraft: InputMaybe<Array<TemperatureRecorderFilterInput>> = []
   
      if (Boolean(seasonId))
        groupFilterDraft.push({pallet: {seasonId: {eq: seasonId}}})

      // Locations
      if (locationIds?.length > 0) {
        let locationFilter: TemperatureRecorderFilterInput = 
        { pallet: { locationId: {in:
          locationIds
        }}}
        groupFilterDraft.push(locationFilter)
      }

      // Arrivals
      if (selectedFilters?.arrivals?.length > 0) {
        let arrivalFilter: TemperatureRecorderFilterInput =         
          {pallet: {arrivalImpExp: {and: 
            selectedFilters.arrivals.map(a => {
              return {and: [
                {arrivalId: {eq: a.arrivalId}},
                {arrivalCode: {eq: a.arrivalCode}}              
              ]}
            })
          }}}
        groupFilterDraft.push(arrivalFilter)
      }

      // Importers
      if (importerIds?.length > 0) {
        let importerFilter: TemperatureRecorderFilterInput = 
          {pallet: {arrivalImpExp:{impExp: {importerId: {in:
            importerIds
          }}}}}
        groupFilterDraft.push(importerFilter)
      }

      // Exporters
      if (exporterIds?.length > 0) {
        let exporterFilter: TemperatureRecorderFilterInput = 
          {pallet: {arrivalImpExp:{impExp: {exporterId: {in:
            exporterIds
          }}}}}
        groupFilterDraft.push(exporterFilter)
      }

      // Containers
      if (containers?.length > 0) {
        let containerFilter: TemperatureRecorderFilterInput =         
          {pallet: {container: {in: 
            containers
          }}}
        groupFilterDraft.push(containerFilter)
      }

      // Lots
      if (lots?.length > 0) {
        let lotFilter: TemperatureRecorderFilterInput =         
          {pallet: {lotCode: {in: 
            lots
          }}}
        groupFilterDraft.push(lotFilter)
      }
            
      const filter: TemperatureRecorderFilterInput = {      
        and: groupFilterDraft                    
      }


      
      
      return {
        last: pageFilter.last,
        before: pageFilter.before,
        first: pageFilter.first,
        after: pageFilter.after,
        seasonId,
        filter
      }
    }, 
    [selectedFilters, seasonId, pageFilter]
  )

  return {
    variables,
    filterOptionsVariables,
    maxPageSize,
    setPageFilter
  }
}