/*

*** AUTO-GENERATED ***

This file has been generated by a code generator. Please do not manually modify this file.
To make changes, modify the templates in the code generator.

*/

import { InspectionsApple } from 'src/_generated/InspectionsApple'
import { InspectionsAvocado } from 'src/_generated/InspectionsAvocado'
import { InspectionsBerry } from 'src/_generated/InspectionsBerry'
import { InspectionsCherry } from 'src/_generated/InspectionsCherry'
import { InspectionsCitrus } from 'src/_generated/InspectionsCitrus'
import { InspectionsGeneric } from 'src/_generated/InspectionsGeneric'
import { InspectionsGrape } from 'src/_generated/InspectionsGrape'
import { InspectionsKiwi } from 'src/_generated/InspectionsKiwi'
import { InspectionsPear } from 'src/_generated/InspectionsPear'
import { InspectionsStone } from 'src/_generated/InspectionsStone'
import { InspectionsLemon } from 'src/_generated/InspectionsLemon'
import { InspectionsPomegranate } from 'src/_generated/InspectionsPomegranate'
import { InspectionsPersimmon } from 'src/_generated/InspectionsPersimmon'

interface Props {
  programName: string,
  inspectionIds: number[]
}

export const InspectionsDetailsExpanded = ({
  programName,
  inspectionIds
}: Props) => {

  switch (programName)
  {
    case 'Apple':
      return <InspectionsApple inspectionIds={inspectionIds} /> 
    case 'Avocado':
      return <InspectionsAvocado inspectionIds={inspectionIds} /> 
    case 'Berry':
      return <InspectionsBerry inspectionIds={inspectionIds} /> 
    case 'Cherry':
      return <InspectionsCherry inspectionIds={inspectionIds} /> 
    case 'Citrus':
      return <InspectionsCitrus inspectionIds={inspectionIds} /> 
    case 'Generic':
      return <InspectionsGeneric inspectionIds={inspectionIds} /> 
    case 'Grape':
      return <InspectionsGrape inspectionIds={inspectionIds} /> 
    case 'Kiwi':
      return <InspectionsKiwi inspectionIds={inspectionIds} /> 
    case 'Pear':
      return <InspectionsPear inspectionIds={inspectionIds} /> 
    case 'Stone':
      return <InspectionsStone inspectionIds={inspectionIds} /> 
    case 'Lemon':
      return <InspectionsLemon inspectionIds={inspectionIds} /> 
    case 'Pomegranate':
      return <InspectionsPomegranate inspectionIds={inspectionIds} /> 
    case 'Persimmon':
      return <InspectionsPersimmon inspectionIds={inspectionIds} /> 
    default:
      return null
  }    

}