import Box from '@mui/material/Box';
import { Container, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import about1 from 'src/assets/images/about1.jpg'

export const WhyPsaRoute = () => {
  const { t } = useTranslation()

  return (
    <>    
      <Paper
        elevation={0}
        sx={{ 
          p: '1rem',
          minHeight: '100vh',
          backgroundImage: `url(${about1})`,          
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}
      >
 
        <Container maxWidth='xl'>
          
          <Box
            sx={{
              backgroundColor: 'mainBackground.main',
              opacity: .9,
              borderRadius: 5,
              padding: '1rem'
            }}
          >

            <Typography variant='h3' mb={6}>
              { t('WhyPsaTitle', 'Why PSA?') }
            </Typography>
            
            <p>
              { t('WhyPsaParagraph1', 'Produce Services of America, Inc. is a full service operations and quality control company with offices in the U.S.A., Chile and The Netherlands, offering the most professional and comprehensive range of support and assistance to growers, exporters and marketers in the industry today.') }
            </p>

            <p>
              { t('WhyPsaParagraph2', 'Through our hands-on, direct involvement in every aspect of our operations we are able to give our customers security in the knowledge that their specific needs will be handled by a highly personalized and individually tailored approach to business.') }
            </p>

            <p>
              { t('WhyPsaParagraph3', `For more than twenty years, PSA's operations division has been ensuring the smooth and efficient handling of its customers' product at every step: from trucking, cold storage and pier coordination, to interacting with insurance companies, customs brokers and governmental agencies.`) }
            </p>

            <p>
              { t('WhyPsaParagraph4', 'Our quality control division, with its highly qualified and experienced personnel, as well as its state-of-the-art technology, is able to customize its procedures to suit the unique requirements of each of its customers. Whether a client simply needs a one-time inspection of its product, or an ongoing, start-to-finish overseeing of its handling, PSA is always ready and responsive to make certain that total satisfaction with its services are achieved.') }
            </p>

            <p>
              { t('WhyPsaParagraph5', 'Working with produce from countries around the world, including Argentina, Brazil, Chile, China, Mexico, Morocco, New Zealand, Peru, South Africa and Spain among others, PSA takes responsibility in ensuring its customers receive the highest quality service available.') }
            </p>
          
          </Box>


        </Container>
      </Paper>
    </>
  )
}