import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import MenuItem from '@mui/material/MenuItem'
import { Link as RouterLink, useLocation, useNavigate  } from 'react-router-dom'
import { useAuth } from "react-oidc-context"
import { useTranslation } from 'react-i18next'

import { DarkMode } from 'src/components/DarkMode'
import { Button, Divider, Link, Tab, Tabs } from '@mui/material';
import psaLogo from 'src/assets/images/psa-logo2.png'
import { AccountCircle } from '@mui/icons-material'
import { SeasonSelector } from './SeasonSelector'
import { useAppState } from 'src/hooks/useAppState'
import { LanguagePicker } from 'src/components/LanguagePicker'
import { routeRoles } from 'src/components/RequireAuth'
import i18n from 'src/i18n'

export const AppNavBar = () => {
  const { t } = useTranslation()
  const auth = useAuth()
  const appState = useAppState()
  const location = useLocation()
  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
 
  const publicPages = [
    {title: t('WhyPsaMenu', 'Why PSA'), slug: 'why-psa', appBarSx: {display: { xs: 'none', sm: 'block' }}}, 
    {title: t('Locations', 'Locations'), slug: 'locations', appBarSx: {display: { xs: 'none', md: 'block' }}}, 
    {title: t('GetStartedMenu', 'Get started'), slug: 'contact', appBarSx: {display: { xs: 'none', md: 'block' }}}, 
  ]
  const authPages = React.useMemo(() => {    
    const pages = [
      {title: t('ArrivalReports', 'Arrival reports'), slug: 'destination/arrival-reports', appBarSx: {display: { xs: 'none', sm: 'block' }}, roles: routeRoles.arrivalReports}, 
      {title: t('Inspections', 'Inspections'), slug: 'destination/inspections', appBarSx: {display: { xs: 'none', md: 'block' }}, roles: routeRoles.inspections}, 
      {title: t('Pictures', 'Pictures'), slug: 'destination/pictures', appBarSx: {display: { xs: 'none', md: 'block' }}, roles: routeRoles.pictures}, 
      {title: t('TempRecorders', 'Temp. recorders'), slug: 'destination/temperature-recorders', appBarSx: {display: { xs: 'none', md: 'block' }}, roles: routeRoles.temperatureRecorders},
      {title: t('HostedReports', 'Hosted reports'), slug: 'destination/hosted-reports', appBarSx: {display: { xs: 'none', md: 'block' }}, roles: routeRoles.hostedReports},
      {title: t('WarehouseOrders', 'Warehouse orders'), slug: 'destination/warehouse-orders', appBarSx: {display: { xs: 'none', md: 'block' }}, roles: routeRoles.warehouseOrders}
    ]
    let filteredPages = []
    const userRole = auth?.user?.profile?.role
    
    for (const page of pages) {            
      const defaultRolesList = []
      const requiredRoles = !Boolean(page?.roles) ? defaultRolesList : page.roles
      let userHasNeededRole = false
      if (Array.isArray(userRole) && requiredRoles?.some(r => userRole.includes(r))) {
        userHasNeededRole = true
      }
      else if (typeof userRole === 'string' && requiredRoles?.some(r => r === userRole)) {
        userHasNeededRole = true
      }    

      if (userHasNeededRole) {
        filteredPages.push(page)
      }
    }

    return filteredPages    
  }, [auth, i18n.language]) 
    
  const pages = auth.isAuthenticated ? authPages : publicPages
  let activeTabIndex = pages.findIndex(page => '/' + page.slug === location.pathname)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  }

  const handleLogInClick = ()=>{        
    auth.signinRedirect()

    //close menu
    setAnchorElUser(null)
  }

  const handleLogoutClick = () => {    
    auth.signoutRedirect()
            

    //close menu
    setAnchorElUser(null)
  }

  const handleMyDownloadsClick = () => {
    navigate('/account/my-downloads')

    //close menu
    setAnchorElUser(null)
  }
  
  return (
    <>

      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar variant='dense' disableGutters>
            <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', minHeight: '24px', gap: '1rem', display: { xs: 'flex' } }}>          
            
              { !auth.isAuthenticated &&
                <Link component={RouterLink} color='#FFFFFF' to='why-psa'>{ t('WhyPsaMenu', 'Why PSA') }</Link>
              }

              <Link component={RouterLink} color='#FFFFFF' to='locations'>
                {t('Locations', 'Locations')}
              </Link>
              <Link component={RouterLink} color='#FFFFFF' to='contact'>
                {t('Contact', 'Contact')}
              </Link>
            </Box>
          </Toolbar>
        </Container>    
      </AppBar>

      <AppBar position="static" color='transparent' sx={{marginTop: '1rem'}}>
        

        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ flexWrap: 'wrap' }} >
            
            <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' }}}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem 
                    key={page.title} 
                    component={RouterLink}
                    to={page.slug}
                    onClick={handleCloseNavMenu}
                  >
                    <Typography textAlign="center">{page.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            
            <RouterLink to='/'>
              <Box sx={{marginRight: '2rem'}}>
                <img src={psaLogo} width='95' height='40' alt='Produce Services of America, Inc.' />
              </Box>
            </RouterLink>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
              <Tabs 
                value={activeTabIndex === -1 ? false : activeTabIndex} 
                aria-label="tabs"
                variant='scrollable'
                scrollButtons='auto'
              >
                {pages.map((page, index) => (                  
                  <Tab 
                    label={page.title} 
                    key={page.title} 
                    component={RouterLink}
                    to={page.slug}
                    {...a11yProps(index)} 
                    sx={{fontSize: '1.1rem', ...page.appBarSx}}
                  />                                 
                ))} 
              </Tabs>
            </Box>
            
            <LanguagePicker />

            { auth.isAuthenticated &&
              <SeasonSelector />
            }

            <DarkMode />

            { auth.isAuthenticated ?
              <Box sx={{ flexGrow: 0 }}>                
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <AccountCircle />                    
                </IconButton>                
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem>
                    <Typography textAlign="center">{t('Hi', 'Hi')}, {auth?.user?.profile?.given_name} {auth?.user?.profile?.family_name}</Typography>
                  </MenuItem>

                  <MenuItem onClick={handleMyDownloadsClick}>
                    <Typography textAlign="center">
                      { t('MyDownloads', 'My downloads') }
                    </Typography>
                  </MenuItem>

                  <MenuItem onClick={handleLogoutClick}>
                    <Typography textAlign="center">
                      {t('LogOut', 'Log out')}
                    </Typography>
                  </MenuItem>
                  
                </Menu>
              </Box>
            :
              <>                
                <Button onClick={handleLogInClick}>
                  {t('Login', 'Log in')}
                </Button>
              </>
            }
          </Toolbar>
        </Container>
      </AppBar>

      <Divider 
        sx={{ 
          borderBottomWidth: 5, 
          color: 'baseBackground.main', 
          borderColor: 'baseBackground.main'
        }} 
      />

    </>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}