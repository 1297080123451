/*
*** AUTO-GENERATED ***

This file has been generated by a code generator. Please do not manually modify this file.
To make changes, modify the templates in the code generator.

*/

import { Box, LinearProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useAppState } from 'src/hooks/useAppState'
import { useGetInspectionsGrapeByIdsQuery } from 'src/utils/__generated__/graphql'

interface Props {
  inspectionIds: number[]
}

export const InspectionsGrape = ({
  inspectionIds
}: Props) => {
  const { t } = useTranslation()
  const { seasonId } = useAppState()  
  const { loading, error, data } = useGetInspectionsGrapeByIdsQuery({
    variables: {
      seasonId: seasonId,
      where: {or:
        inspectionIds.map((id) => ({id: {eq: id}}) )
      }
    }
  })

  const renderDetail = (value, key) => (
    <Box 
      key={key}
      sx={{minHeight:'1rem', 
        overflow: 'hidden',
        borderBottom: '1px solid',
        borderColor: 'rowDivider.main'
      }}
    >
      {value}
    </Box>
  )

  if (loading) return (<div><LinearProgress /></div>)
  if (error) return (<p>Error: {JSON.stringify(error)}</p>)

  return (          
    <Box>            
      <Box 
        sx={{display: 'grid',
          minWidth: '16rem',
          //width: 'max-content',
          width: `${(inspectionIds?.length * 6 ?? 1) + 10}rem`,                            
          gridAutoFlow: 'column',
          gridTemplateRows: `repeat(63, min-content)`,
          gridGap: '0px'
        }}
      >                
        {renderDetail(t('InspGrowerCode', 'InspGrowerCode'), 'InspGrowerCode')}
        {renderDetail(t('InspPackDate', 'InspPackDate'), 'InspPackDate')}
        {renderDetail(t('InspSize', 'InspSize'), 'InspSize')}
        {renderDetail(t('InspPackCode', 'InspPackCode'), 'InspPackCode')}
        {renderDetail(t('Count', 'Count'), 'Count')}
        {renderDetail(t('PLU', 'PLU'), 'PLU')}
        {renderDetail(t('PLUPER', 'PLUPER'), 'PLUPER')}
        {renderDetail(t('CountryOfOrigin', 'CountryOfOrigin'), 'CountryOfOrigin')}
        {renderDetail(t('Weight', 'Weight'), 'Weight')}
        {renderDetail(t('UnderweightMin', 'UnderweightMin'), 'UnderweightMin')}
        {renderDetail(t('UnderweightMax', 'UnderweightMax'), 'UnderweightMax')}
        {renderDetail(t('WeighedUnits', 'WeighedUnits'), 'WeighedUnits')}
        {renderDetail(t('UnderweightUnits', 'UnderweightUnits'), 'UnderweightUnits')}
        {renderDetail(t('UnderweightPER', 'UnderweightPER'), 'UnderweightPER')}
        {renderDetail(t('PulpTemp', 'PulpTemp'), 'PulpTemp')}
        {renderDetail(t('Opening', 'Opening'), 'Opening')}
        {renderDetail(t('Bunches', 'Bunches'), 'Bunches')}
        {renderDetail(t('SizeMin', 'SizeMin'), 'SizeMin')}
        {renderDetail(t('SizeMax', 'SizeMax'), 'SizeMax')}
        {renderDetail(t('SizeMost', 'SizeMost'), 'SizeMost')}
        {renderDetail(t('UndersizeBerriesBunches', 'UndersizeBerriesBunches'), 'UndersizeBerriesBunches')}
        {renderDetail(t('UndersizeBerriesPER', 'UndersizeBerriesPER'), 'UndersizeBerriesPER')}
        {renderDetail(t('ColorMin', 'ColorMin'), 'ColorMin')}
        {renderDetail(t('ColorMax', 'ColorMax'), 'ColorMax')}
        {renderDetail(t('ColorMost', 'ColorMost'), 'ColorMost')}
        {renderDetail(t('ColorConsistency', 'ColorConsistency'), 'ColorConsistency')}
        {renderDetail(t('SunburnBunches', 'SunburnBunches'), 'SunburnBunches')}
        {renderDetail(t('SunburnPER', 'SunburnPER'), 'SunburnPER')}
        {renderDetail(t('SunburnDEG', 'SunburnDEG'), 'SunburnDEG')}
        {renderDetail(t('BunchConformation', 'BunchConformation'), 'BunchConformation')}
        {renderDetail(t('StragglyBunches', 'StragglyBunches'), 'StragglyBunches')}
        {renderDetail(t('StragglyPER', 'StragglyPER'), 'StragglyPER')}
        {renderDetail(t('SmallBunches', 'SmallBunches'), 'SmallBunches')}
        {renderDetail(t('RussetMarksBunches', 'RussetMarksBunches'), 'RussetMarksBunches')}
        {renderDetail(t('RussetMarksPER', 'RussetMarksPER'), 'RussetMarksPER')}
        {renderDetail(t('DustPER', 'DustPER'), 'DustPER')}
        {renderDetail(t('ResiduesPER', 'ResiduesPER'), 'ResiduesPER')}
        {renderDetail(t('TightBunches', 'TightBunches'), 'TightBunches')}
        {renderDetail(t('BruisingBunches', 'BruisingBunches'), 'BruisingBunches')}
        {renderDetail(t('BruisingPER', 'BruisingPER'), 'BruisingPER')}
        {renderDetail(t('BruisingDEG', 'BruisingDEG'), 'BruisingDEG')}
        {renderDetail(t('StemDehydrationPER', 'StemDehydrationPER'), 'StemDehydrationPER')}
        {renderDetail(t('StemDehydrationDEG', 'StemDehydrationDEG'), 'StemDehydrationDEG')}
        {renderDetail(t('BerryCondition', 'BerryCondition'), 'BerryCondition')}
        {renderDetail(t('H2OBerries', 'H2OBerries'), 'H2OBerries')}
        {renderDetail(t('SO2DamagePER', 'SO2DamagePER'), 'SO2DamagePER')}
        {renderDetail(t('SO2DamageDEG', 'SO2DamageDEG'), 'SO2DamageDEG')}
        {renderDetail(t('WeakBunches', 'WeakBunches'), 'WeakBunches')}
        {renderDetail(t('SplitsHairlinePER', 'SplitsHairlinePER'), 'SplitsHairlinePER')}
        {renderDetail(t('SplitsWetCrushPER', 'SplitsWetCrushPER'), 'SplitsWetCrushPER')}
        {renderDetail(t('SplitsDryPER', 'SplitsDryPER'), 'SplitsDryPER')}
        {renderDetail(t('IntDisc', 'IntDisc'), 'IntDisc')}
        {renderDetail(t('IntDiscDEG', 'IntDiscDEG'), 'IntDiscDEG')}
        {renderDetail(t('DecayMoldBerries', 'DecayMoldBerries'), 'DecayMoldBerries')}
        {renderDetail(t('DecaySlipskinBerries', 'DecaySlipskinBerries'), 'DecaySlipskinBerries')}
        {renderDetail(t('DecayNestBerries', 'DecayNestBerries'), 'DecayNestBerries')}
        {renderDetail(t('DecayNestDEG', 'DecayNestDEG'), 'DecayNestDEG')}
        {renderDetail(t('ShatterPER', 'ShatterPER'), 'ShatterPER')}
        {renderDetail(t('BrixMin', 'BrixMin'), 'BrixMin')}
        {renderDetail(t('BrixMax', 'BrixMax'), 'BrixMax')}
        {renderDetail(t('BrixMost', 'BrixMost'), 'BrixMost')}
        {renderDetail(t('OverallQ', 'OverallQ'), 'OverallQ')}
        {renderDetail(t('OverallC', 'OverallC'), 'OverallC')}

        {data?.inspections?.nodes?.map((inspection, i) => {
          return (
            <>
              {renderDetail(inspection.inspectionDetail['inspGrowerCode'], `${inspection.id}-inspGrowerCode`)}
              {renderDetail(inspection.inspectionDetail['inspPackDate'], `${inspection.id}-inspPackDate`)}
              {renderDetail(inspection.inspectionDetail['inspSize'], `${inspection.id}-inspSize`)}
              {renderDetail(inspection.inspectionDetail['inspPackCode'], `${inspection.id}-inspPackCode`)}
              {renderDetail(inspection.inspectionDetail['count'], `${inspection.id}-count`)}
              {renderDetail(inspection.inspectionDetail['pLU'], `${inspection.id}-pLU`)}
              {renderDetail(inspection.inspectionDetail['pLUPER'], `${inspection.id}-pLUPER`)}
              {renderDetail(inspection.inspectionDetail['countryOfOrigin'], `${inspection.id}-countryOfOrigin`)}
              {renderDetail(inspection.inspectionDetail['weight'], `${inspection.id}-weight`)}
              {renderDetail(inspection.inspectionDetail['underweightMin'], `${inspection.id}-underweightMin`)}
              {renderDetail(inspection.inspectionDetail['underweightMax'], `${inspection.id}-underweightMax`)}
              {renderDetail(inspection.inspectionDetail['weighedUnits'], `${inspection.id}-weighedUnits`)}
              {renderDetail(inspection.inspectionDetail['underweightUnits'], `${inspection.id}-underweightUnits`)}
              {renderDetail(inspection.inspectionDetail['underweightPER'], `${inspection.id}-underweightPER`)}
              {renderDetail(inspection.inspectionDetail['pulpTemp'], `${inspection.id}-pulpTemp`)}
              {renderDetail(inspection.inspectionDetail['opening'], `${inspection.id}-opening`)}
              {renderDetail(inspection.inspectionDetail['bunches'], `${inspection.id}-bunches`)}
              {renderDetail(inspection.inspectionDetail['sizeMin'], `${inspection.id}-sizeMin`)}
              {renderDetail(inspection.inspectionDetail['sizeMax'], `${inspection.id}-sizeMax`)}
              {renderDetail(inspection.inspectionDetail['sizeMost'], `${inspection.id}-sizeMost`)}
              {renderDetail(inspection.inspectionDetail['undersizeBerriesBunches'], `${inspection.id}-undersizeBerriesBunches`)}
              {renderDetail(inspection.inspectionDetail['undersizeBerriesPER'], `${inspection.id}-undersizeBerriesPER`)}
              {renderDetail(inspection.inspectionDetail['colorMin'], `${inspection.id}-colorMin`)}
              {renderDetail(inspection.inspectionDetail['colorMax'], `${inspection.id}-colorMax`)}
              {renderDetail(inspection.inspectionDetail['colorMost'], `${inspection.id}-colorMost`)}
              {renderDetail(inspection.inspectionDetail['colorConsistency'], `${inspection.id}-colorConsistency`)}
              {renderDetail(inspection.inspectionDetail['sunburnBunches'], `${inspection.id}-sunburnBunches`)}
              {renderDetail(inspection.inspectionDetail['sunburnPER'], `${inspection.id}-sunburnPER`)}
              {renderDetail(inspection.inspectionDetail['sunburnDEG'], `${inspection.id}-sunburnDEG`)}
              {renderDetail(inspection.inspectionDetail['bunchConformation'], `${inspection.id}-bunchConformation`)}
              {renderDetail(inspection.inspectionDetail['stragglyBunches'], `${inspection.id}-stragglyBunches`)}
              {renderDetail(inspection.inspectionDetail['stragglyPER'], `${inspection.id}-stragglyPER`)}
              {renderDetail(inspection.inspectionDetail['smallBunches'], `${inspection.id}-smallBunches`)}
              {renderDetail(inspection.inspectionDetail['russetMarksBunches'], `${inspection.id}-russetMarksBunches`)}
              {renderDetail(inspection.inspectionDetail['russetMarksPER'], `${inspection.id}-russetMarksPER`)}
              {renderDetail(inspection.inspectionDetail['dustPER'], `${inspection.id}-dustPER`)}
              {renderDetail(inspection.inspectionDetail['residuesPER'], `${inspection.id}-residuesPER`)}
              {renderDetail(inspection.inspectionDetail['tightBunches'], `${inspection.id}-tightBunches`)}
              {renderDetail(inspection.inspectionDetail['bruisingBunches'], `${inspection.id}-bruisingBunches`)}
              {renderDetail(inspection.inspectionDetail['bruisingPER'], `${inspection.id}-bruisingPER`)}
              {renderDetail(inspection.inspectionDetail['bruisingDEG'], `${inspection.id}-bruisingDEG`)}
              {renderDetail(inspection.inspectionDetail['stemDehydrationPER'], `${inspection.id}-stemDehydrationPER`)}
              {renderDetail(inspection.inspectionDetail['stemDehydrationDEG'], `${inspection.id}-stemDehydrationDEG`)}
              {renderDetail(inspection.inspectionDetail['berryCondition'], `${inspection.id}-berryCondition`)}
              {renderDetail(inspection.inspectionDetail['h2OBerries'], `${inspection.id}-h2OBerries`)}
              {renderDetail(inspection.inspectionDetail['sO2DamagePER'], `${inspection.id}-sO2DamagePER`)}
              {renderDetail(inspection.inspectionDetail['sO2DamageDEG'], `${inspection.id}-sO2DamageDEG`)}
              {renderDetail(inspection.inspectionDetail['weakBunches'], `${inspection.id}-weakBunches`)}
              {renderDetail(inspection.inspectionDetail['splitsHairlinePER'], `${inspection.id}-splitsHairlinePER`)}
              {renderDetail(inspection.inspectionDetail['splitsWetCrushPER'], `${inspection.id}-splitsWetCrushPER`)}
              {renderDetail(inspection.inspectionDetail['splitsDryPER'], `${inspection.id}-splitsDryPER`)}
              {renderDetail(inspection.inspectionDetail['intDisc'], `${inspection.id}-intDisc`)}
              {renderDetail(inspection.inspectionDetail['intDiscDEG'], `${inspection.id}-intDiscDEG`)}
              {renderDetail(inspection.inspectionDetail['decayMoldBerries'], `${inspection.id}-decayMoldBerries`)}
              {renderDetail(inspection.inspectionDetail['decaySlipskinBerries'], `${inspection.id}-decaySlipskinBerries`)}
              {renderDetail(inspection.inspectionDetail['decayNestBerries'], `${inspection.id}-decayNestBerries`)}
              {renderDetail(inspection.inspectionDetail['decayNestDEG'], `${inspection.id}-decayNestDEG`)}
              {renderDetail(inspection.inspectionDetail['shatterPER'], `${inspection.id}-shatterPER`)}
              {renderDetail(inspection.inspectionDetail['brixMin'], `${inspection.id}-brixMin`)}
              {renderDetail(inspection.inspectionDetail['brixMax'], `${inspection.id}-brixMax`)}
              {renderDetail(inspection.inspectionDetail['brixMost'], `${inspection.id}-brixMost`)}
              {renderDetail(inspection.inspectionDetail['overallQ'], `${inspection.id}-overallQ`)}
              {renderDetail(inspection.inspectionDetail['overallC'], `${inspection.id}-overallC`)}
            </>
          )
        })}
      </Box>

    </Box>    
  )
}