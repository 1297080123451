import { Box, Button, Stack, Typography } from "@mui/material"
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

import { PageFilter } from 'src/typeDefinitions/appTypes'
import { PageInfo } from "src/utils/__generated__/graphql"
import { useTranslation } from "react-i18next"

type Props = {
  maxPageSize: number,
  totalPages: number,
  pageInfo: PageInfo,
  setPageFilter: (pageFilter: PageFilter)=>void,
}

export const Pagination = ({
  maxPageSize,
  totalPages,
  pageInfo,
  setPageFilter,
}: Props) => {
  const { t } = useTranslation()
  
  return (
    <Stack 
      direction={{xs: 'column', sm: 'row'}}
      justifyContent='space-between'
      spacing={2}
      mt={6}
      mb={6}
    >
      <Button 
        variant='contained' 
        startIcon={<KeyboardDoubleArrowLeftIcon />} 
        size='large'
        sx={{
          color: 'white'
        }}
        disabled={!pageInfo?.hasPreviousPage}
        onClick={() => setPageFilter({          
          last: null,
          before: null,
          first: null,
          after: null          
        })}
      >
        &nbsp;
      </Button>

      <Button 
        variant='contained' 
        startIcon={<KeyboardArrowLeftIcon />} 
        size='large'
        sx={{
          color: 'white'
        }}
        disabled={!pageInfo?.hasPreviousPage}
        onClick={() => setPageFilter({          
          last: maxPageSize,
          before: pageInfo.startCursor,
          first: null,
          after: null
        })}
      >
        <Typography  sx={{display: {xs: 'none', md: 'block'} }}>
          { t('Previous', 'Previous') }
        </Typography>
      </Button>
      
      <Box 
        sx={{
          flexGrow: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Typography sx={{display: {xs: 'none', md: 'block'} }}>
          { t('TotalPages', 'Total pages') }: { totalPages }
        </Typography>
      </Box>

      <Button 
        variant='contained' 
        endIcon={<KeyboardArrowRightIcon />} 
        size='large'
        sx={{
          color: 'white'
        }}
        disabled={!pageInfo?.hasNextPage}
        onClick={() => setPageFilter({          
          last: null,
          before: null,
          first: maxPageSize,
          after: pageInfo.endCursor          
        })}
      >
        <Typography  sx={{display: {xs: 'none', md: 'block'} }}>
          { t('Next', 'Next') }
        </Typography>
      </Button>

      <Button 
        variant='contained' 
        endIcon={<KeyboardDoubleArrowRightIcon />} 
        size='large'
        sx={{
          color: 'white'          
        }}
        disabled={!pageInfo?.hasNextPage}
        onClick={() => setPageFilter({          
          last: maxPageSize,
          before: null,
          first: null,
          after: null          
        })}
      > 
        &nbsp;
      </Button>

    </Stack>
  )
}