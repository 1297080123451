import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import i18n from 'src/i18n'


export const LanguagePicker = () => {
  const handleChange = (event: SelectChangeEvent) => {    
    window?.localStorage?.setItem('language', JSON.stringify(event.target.value))
    i18n.changeLanguage(event.target.value)
  }

  return (
    <FormControl sx={{ m: 1, minWidth: '4rem' }} size="small">
      <InputLabel id="demo-select-small"></InputLabel>
      <Select
        labelId='demo-select-small'
        id='demo-select-small'           
        defaultValue={i18n.language}
        label=''
        onChange={handleChange}
      >                
        <MenuItem  value='en'>EN</MenuItem>
        <MenuItem value='es'>ES</MenuItem>
      </Select>
    </FormControl>
  )
}