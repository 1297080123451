/*
*** AUTO-GENERATED ***

This file has been generated by a code generator. Please do not manually modify this file.
To make changes, modify the templates in the code generator.

*/

import { Box, LinearProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useAppState } from 'src/hooks/useAppState'
import { useGetInspectionsKiwiByIdsQuery } from 'src/utils/__generated__/graphql'

interface Props {
  inspectionIds: number[]
}

export const InspectionsKiwi = ({
  inspectionIds
}: Props) => {
  const { t } = useTranslation()
  const { seasonId } = useAppState()  
  const { loading, error, data } = useGetInspectionsKiwiByIdsQuery({
    variables: {
      seasonId: seasonId,
      where: {or:
        inspectionIds.map((id) => ({id: {eq: id}}) )
      }
    }
  })

  const renderDetail = (value, key) => (
    <Box 
      key={key}
      sx={{minHeight:'1rem', 
        overflow: 'hidden',
        borderBottom: '1px solid',
        borderColor: 'rowDivider.main'
      }}
    >
      {value}
    </Box>
  )

  if (loading) return (<div><LinearProgress /></div>)
  if (error) return (<p>Error: {JSON.stringify(error)}</p>)

  return (          
    <Box>            
      <Box 
        sx={{display: 'grid',
          minWidth: '16rem',
          //width: 'max-content',
          width: `${(inspectionIds?.length * 6 ?? 1) + 10}rem`,                            
          gridAutoFlow: 'column',
          gridTemplateRows: `repeat(55, min-content)`,
          gridGap: '0px'
        }}
      >                
        {renderDetail(t('InspGrowerCode', 'InspGrowerCode'), 'InspGrowerCode')}
        {renderDetail(t('InspPackDate', 'InspPackDate'), 'InspPackDate')}
        {renderDetail(t('InspSize', 'InspSize'), 'InspSize')}
        {renderDetail(t('InspPackCode', 'InspPackCode'), 'InspPackCode')}
        {renderDetail(t('Count', 'Count'), 'Count')}
        {renderDetail(t('PLU', 'PLU'), 'PLU')}
        {renderDetail(t('PLUPER', 'PLUPER'), 'PLUPER')}
        {renderDetail(t('CountryOfOrigin', 'CountryOfOrigin'), 'CountryOfOrigin')}
        {renderDetail(t('Weight', 'Weight'), 'Weight')}
        {renderDetail(t('UnderweightMin', 'UnderweightMin'), 'UnderweightMin')}
        {renderDetail(t('UnderweightMax', 'UnderweightMax'), 'UnderweightMax')}
        {renderDetail(t('WeighedUnits', 'WeighedUnits'), 'WeighedUnits')}
        {renderDetail(t('UnderweightUnits', 'UnderweightUnits'), 'UnderweightUnits')}
        {renderDetail(t('UnderweightPER', 'UnderweightPER'), 'UnderweightPER')}
        {renderDetail(t('PulpTemp', 'PulpTemp'), 'PulpTemp')}
        {renderDetail(t('Opening', 'Opening'), 'Opening')}
        {renderDetail(t('Color', 'Color'), 'Color')}
        {renderDetail(t('ScarsRussetPieces', 'ScarsRussetPieces'), 'ScarsRussetPieces')}
        {renderDetail(t('ScarsRussetPER', 'ScarsRussetPER'), 'ScarsRussetPER')}
        {renderDetail(t('ScarsRussetDEG', 'ScarsRussetDEG'), 'ScarsRussetDEG')}
        {renderDetail(t('HaywardMarksPieces', 'HaywardMarksPieces'), 'HaywardMarksPieces')}
        {renderDetail(t('HaywardMarksPER', 'HaywardMarksPER'), 'HaywardMarksPER')}
        {renderDetail(t('MisshapenPieces', 'MisshapenPieces'), 'MisshapenPieces')}
        {renderDetail(t('MisshapenPER', 'MisshapenPER'), 'MisshapenPER')}
        {renderDetail(t('MisshapenDEG', 'MisshapenDEG'), 'MisshapenDEG')}
        {renderDetail(t('SpottingPieces', 'SpottingPieces'), 'SpottingPieces')}
        {renderDetail(t('SpottingPER', 'SpottingPER'), 'SpottingPER')}
        {renderDetail(t('SpottingDEG', 'SpottingDEG'), 'SpottingDEG')}
        {renderDetail(t('SunkenAreasPieces', 'SunkenAreasPieces'), 'SunkenAreasPieces')}
        {renderDetail(t('SunkenAreasPER', 'SunkenAreasPER'), 'SunkenAreasPER')}
        {renderDetail(t('SunkenAreasDEG', 'SunkenAreasDEG'), 'SunkenAreasDEG')}
        {renderDetail(t('SunburnPieces', 'SunburnPieces'), 'SunburnPieces')}
        {renderDetail(t('SunburnPER', 'SunburnPER'), 'SunburnPER')}
        {renderDetail(t('SunburnDEG', 'SunburnDEG'), 'SunburnDEG')}
        {renderDetail(t('SoftPiecesPieces', 'SoftPiecesPieces'), 'SoftPiecesPieces')}
        {renderDetail(t('SoftPiecesPER', 'SoftPiecesPER'), 'SoftPiecesPER')}
        {renderDetail(t('DecayPieces', 'DecayPieces'), 'DecayPieces')}
        {renderDetail(t('DecayPER', 'DecayPER'), 'DecayPER')}
        {renderDetail(t('DecayDEG', 'DecayDEG'), 'DecayDEG')}
        {renderDetail(t('MoldPieces', 'MoldPieces'), 'MoldPieces')}
        {renderDetail(t('MoldPER', 'MoldPER'), 'MoldPER')}
        {renderDetail(t('BrixMin', 'BrixMin'), 'BrixMin')}
        {renderDetail(t('BrixMax', 'BrixMax'), 'BrixMax')}
        {renderDetail(t('BrixMost', 'BrixMost'), 'BrixMost')}
        {renderDetail(t('Pressure1', 'Pressure1'), 'Pressure1')}
        {renderDetail(t('Pressure2', 'Pressure2'), 'Pressure2')}
        {renderDetail(t('Pressure3', 'Pressure3'), 'Pressure3')}
        {renderDetail(t('Pressure4', 'Pressure4'), 'Pressure4')}
        {renderDetail(t('Pressure5', 'Pressure5'), 'Pressure5')}
        {renderDetail(t('Pressure6', 'Pressure6'), 'Pressure6')}
        {renderDetail(t('PressuresMin', 'PressuresMin'), 'PressuresMin')}
        {renderDetail(t('PressuresMax', 'PressuresMax'), 'PressuresMax')}
        {renderDetail(t('PressuresAvg', 'PressuresAvg'), 'PressuresAvg')}
        {renderDetail(t('OverallQ', 'OverallQ'), 'OverallQ')}
        {renderDetail(t('OverallC', 'OverallC'), 'OverallC')}

        {data?.inspections?.nodes?.map((inspection, i) => {
          return (
            <>
              {renderDetail(inspection.inspectionDetail['inspGrowerCode'], `${inspection.id}-inspGrowerCode`)}
              {renderDetail(inspection.inspectionDetail['inspPackDate'], `${inspection.id}-inspPackDate`)}
              {renderDetail(inspection.inspectionDetail['inspSize'], `${inspection.id}-inspSize`)}
              {renderDetail(inspection.inspectionDetail['inspPackCode'], `${inspection.id}-inspPackCode`)}
              {renderDetail(inspection.inspectionDetail['count'], `${inspection.id}-count`)}
              {renderDetail(inspection.inspectionDetail['pLU'], `${inspection.id}-pLU`)}
              {renderDetail(inspection.inspectionDetail['pLUPER'], `${inspection.id}-pLUPER`)}
              {renderDetail(inspection.inspectionDetail['countryOfOrigin'], `${inspection.id}-countryOfOrigin`)}
              {renderDetail(inspection.inspectionDetail['weight'], `${inspection.id}-weight`)}
              {renderDetail(inspection.inspectionDetail['underweightMin'], `${inspection.id}-underweightMin`)}
              {renderDetail(inspection.inspectionDetail['underweightMax'], `${inspection.id}-underweightMax`)}
              {renderDetail(inspection.inspectionDetail['weighedUnits'], `${inspection.id}-weighedUnits`)}
              {renderDetail(inspection.inspectionDetail['underweightUnits'], `${inspection.id}-underweightUnits`)}
              {renderDetail(inspection.inspectionDetail['underweightPER'], `${inspection.id}-underweightPER`)}
              {renderDetail(inspection.inspectionDetail['pulpTemp'], `${inspection.id}-pulpTemp`)}
              {renderDetail(inspection.inspectionDetail['opening'], `${inspection.id}-opening`)}
              {renderDetail(inspection.inspectionDetail['color'], `${inspection.id}-color`)}
              {renderDetail(inspection.inspectionDetail['scarsRussetPieces'], `${inspection.id}-scarsRussetPieces`)}
              {renderDetail(inspection.inspectionDetail['scarsRussetPER'], `${inspection.id}-scarsRussetPER`)}
              {renderDetail(inspection.inspectionDetail['scarsRussetDEG'], `${inspection.id}-scarsRussetDEG`)}
              {renderDetail(inspection.inspectionDetail['haywardMarksPieces'], `${inspection.id}-haywardMarksPieces`)}
              {renderDetail(inspection.inspectionDetail['haywardMarksPER'], `${inspection.id}-haywardMarksPER`)}
              {renderDetail(inspection.inspectionDetail['misshapenPieces'], `${inspection.id}-misshapenPieces`)}
              {renderDetail(inspection.inspectionDetail['misshapenPER'], `${inspection.id}-misshapenPER`)}
              {renderDetail(inspection.inspectionDetail['misshapenDEG'], `${inspection.id}-misshapenDEG`)}
              {renderDetail(inspection.inspectionDetail['spottingPieces'], `${inspection.id}-spottingPieces`)}
              {renderDetail(inspection.inspectionDetail['spottingPER'], `${inspection.id}-spottingPER`)}
              {renderDetail(inspection.inspectionDetail['spottingDEG'], `${inspection.id}-spottingDEG`)}
              {renderDetail(inspection.inspectionDetail['sunkenAreasPieces'], `${inspection.id}-sunkenAreasPieces`)}
              {renderDetail(inspection.inspectionDetail['sunkenAreasPER'], `${inspection.id}-sunkenAreasPER`)}
              {renderDetail(inspection.inspectionDetail['sunkenAreasDEG'], `${inspection.id}-sunkenAreasDEG`)}
              {renderDetail(inspection.inspectionDetail['sunburnPieces'], `${inspection.id}-sunburnPieces`)}
              {renderDetail(inspection.inspectionDetail['sunburnPER'], `${inspection.id}-sunburnPER`)}
              {renderDetail(inspection.inspectionDetail['sunburnDEG'], `${inspection.id}-sunburnDEG`)}
              {renderDetail(inspection.inspectionDetail['softPiecesPieces'], `${inspection.id}-softPiecesPieces`)}
              {renderDetail(inspection.inspectionDetail['softPiecesPER'], `${inspection.id}-softPiecesPER`)}
              {renderDetail(inspection.inspectionDetail['decayPieces'], `${inspection.id}-decayPieces`)}
              {renderDetail(inspection.inspectionDetail['decayPER'], `${inspection.id}-decayPER`)}
              {renderDetail(inspection.inspectionDetail['decayDEG'], `${inspection.id}-decayDEG`)}
              {renderDetail(inspection.inspectionDetail['moldPieces'], `${inspection.id}-moldPieces`)}
              {renderDetail(inspection.inspectionDetail['moldPER'], `${inspection.id}-moldPER`)}
              {renderDetail(inspection.inspectionDetail['brixMin'], `${inspection.id}-brixMin`)}
              {renderDetail(inspection.inspectionDetail['brixMax'], `${inspection.id}-brixMax`)}
              {renderDetail(inspection.inspectionDetail['brixMost'], `${inspection.id}-brixMost`)}
              {renderDetail(inspection.inspectionDetail['pressure1'], `${inspection.id}-pressure1`)}
              {renderDetail(inspection.inspectionDetail['pressure2'], `${inspection.id}-pressure2`)}
              {renderDetail(inspection.inspectionDetail['pressure3'], `${inspection.id}-pressure3`)}
              {renderDetail(inspection.inspectionDetail['pressure4'], `${inspection.id}-pressure4`)}
              {renderDetail(inspection.inspectionDetail['pressure5'], `${inspection.id}-pressure5`)}
              {renderDetail(inspection.inspectionDetail['pressure6'], `${inspection.id}-pressure6`)}
              {renderDetail(inspection.inspectionDetail['pressuresMin'], `${inspection.id}-pressuresMin`)}
              {renderDetail(inspection.inspectionDetail['pressuresMax'], `${inspection.id}-pressuresMax`)}
              {renderDetail(inspection.inspectionDetail['pressuresAvg'], `${inspection.id}-pressuresAvg`)}
              {renderDetail(inspection.inspectionDetail['overallQ'], `${inspection.id}-overallQ`)}
              {renderDetail(inspection.inspectionDetail['overallC'], `${inspection.id}-overallC`)}
            </>
          )
        })}
      </Box>

    </Box>    
  )
}