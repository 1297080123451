import React from 'react'
import { Breakpoint, Drawer, useMediaQuery, useTheme } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { FilterProps, FilterUiData } from 'src/typeDefinitions/appTypes'
import { LoadingButton } from '@mui/lab'

interface Props extends FilterProps {
  loading?: boolean,
  screenSize?: Breakpoint,
  popupComponent: any,  
  filterUiData?: FilterUiData[],
}

export const FilterButton = ({
  title,
  loading,
  screenSize = 'xs',
  popupComponent,
  onDone,
  ...restOfProps
}: Props) => {
  const theme = useTheme()
  const visible = useMediaQuery(theme.breakpoints.up(screenSize))  
  const [drawerOpen, setDrawerOpen] = React.useState(false)

  if (!visible)
    return null  

  const handleDone = (selectedFilters, closePopup = true) => {
    if (closePopup) {
      setDrawerOpen(false)
    }
    onDone(selectedFilters)    
  }

  const PopupComponent = popupComponent
  return (    
    <>
      <LoadingButton 
        loading={loading} 
        loadingPosition="start" 
        variant="outlined" 
        disabled={restOfProps?.filterOptions?.length === 0}
        sx={{flexGrow: title === 'More' ? 0 : 1 }} 
        onClick={()=>{setDrawerOpen(true)}}
      >
        {title === 'More' ? <MoreVertIcon /> : title }
      </LoadingButton>
      <Drawer
        anchor={'top'}        
        open={drawerOpen}
        onClose={()=>{setDrawerOpen(false)}}
      >
        <PopupComponent title={title} {...restOfProps} onDone={handleDone} />
      </Drawer>
    </>
  )
}