import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useAuth } from 'react-oidc-context'
import { useTranslation } from 'react-i18next'

import mobileView from 'src/assets/images/mobile-view3.jpg'
import laptopView from 'src/assets/images/laptop-view4.jpg'
import citrus1 from 'src/assets/images/citrus1.jpg'
import fruit1 from 'src/assets/images/fruit1.jpg'

export const HomeRoute = () => {
  const { t } = useTranslation()  
  const auth = useAuth()
  const navigate = useNavigate()

  return (
    <Box>
      
      <Grid container spacing={10} mt={0} mb={6}>
        
        <Grid item xs={12} md={6}>
          <Box pl={6} pr={6} sx={{display: 'flex', flexDirection: 'column'}}>
            <Typography sx={{fontSize: {md: '4rem', xs: '2rem'}, lineHeight: '1', fontWeight: '900', mb: 4}}>
              { t('HighlyPersonalized', 'Highly personalized and individually tailored approach') }
            </Typography>
            <Typography mb={2}>
              { t('OurQualityControlDivision', 'Our quality control division, with its highly qualified and experienced personnel, as well as its state-of-the-art technology, is able to customize its procedures to suit the unique requirements of each of its customers. Whether a client simply needs a one-time inspection of its product, or an ongoing, start-to-finish overseeing of its handling, PSA is always ready and responsive to make certain that total satisfaction with its services are achieved.') }
            </Typography>

            <Button 
              sx={{fontSize: {xs: '1.5rem', lg: '3rem'}}}
              to={`contact`}
              variant='outlined'
              component={RouterLink}
            >
              { t('GetStartedNow', 'Get started now!') }
            </Button>

          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundImage: `url(${citrus1})`,            
              height: '80vh',
              backgroundRepeat: 'no-repeat',              
              backgroundSize: '100% auto',
              backgroundPosition: 'center',                                          
            }}
          >
          </Box>
          
        </Grid>

        <Grid item xs={12} md={6}>
          <img src={fruit1} alt="Fruit" style={{width: '100%'}} />          
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{fontSize: {md: '4rem', xs: '2rem'}, lineHeight: '1', fontWeight: '900', padding: '2rem'}}>
            { t('HandsOn', 'Hands-on, direct involvement in every aspect') }
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography sx={{fontSize: {md: '4rem', xs: '2rem'}, lineHeight: '1', fontWeight: '900', padding: '2rem'}}>
            { t('GetDataOnGo', 'Get your data on the go...') }
          </Typography>          
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundImage: `url(${mobileView})`,            
              height: '80vh',
              backgroundRepeat: 'no-repeat',              
              backgroundSize: '100% auto',
              backgroundPosition: 'center',                                          
            }}
          >
          </Box>          
        </Grid>        

        <Grid item xs={12} md={6}>
          <img src={laptopView} alt="Fruit" style={{width: '100%'}} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography sx={{fontSize: {md: '4rem', xs: '2rem'}, lineHeight: '1', fontWeight: '900', padding: '2rem'}}>
            { t('InTheOfficeOrAnywhere', '...in the office or anywhere') }
          </Typography>

          
        </Grid>
     
      </Grid>
      
      <Box sx={{display: 'flex', justifyContent: 'center'}} mb={6}>
        <Box sx={{p: 2}}>
          <Button 
            sx={{fontSize: {md: '3rem', xs: '2rem'}, mb: 12}}
            to={`contact`}
            variant='outlined'
            component={RouterLink}
          >
            { t('GetStarted2', 'Click here to get started now!') }
          </Button>          

          { t('AlreadyHaveAccount', 'Already have an account with PSA? ') } 
          <Button
            onClick={()=>auth.signinRedirect()}
          >
            { t('LogInHere', 'Log in here') }
          </Button>
        </Box>
      </Box>

      
    </Box>
  )
}