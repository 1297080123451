import * as React from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { Alert, Button, LinearProgress, Paper,  Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { useAppState } from 'src/hooks/useAppState'
import { useGetArrivalReportQuery, useDownloadFileMutation, FileType, Picture } from 'src/utils/__generated__/graphql'
import { Download } from '@mui/icons-material'

import { OverallQCPieChart } from 'src/components/OverallQCPieChart'
import { PictureCard } from 'src/components/PictureCard'
import { PictureViewerDialog } from 'src/components/PictureViewerDialog'

export const ArrivalReportRoute = () => {
  const { settings } = useAppState()
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const { t } = useTranslation()  
  const navigate = useNavigate()
  const { loading, error, data } = useGetArrivalReportQuery({
    variables: {
      websiteId: params.websiteId,
      arrivalReportLinkId: parseInt(params.arrivalReportLinkId),
      preview: searchParams.get('preview') === '1' ? true : null
    }
  })
  const [open, setOpen] = React.useState(false)
  const [selectedPicture, setSelectedPicture] = React.useState<Picture>()
  const [downloadFile, {loading: loadingDownloadFile, error: downloadFileError}] = useDownloadFileMutation()

  const handleClick = (picture) => {
    setSelectedPicture(picture)
    setOpen(true)
  }

  const handleDownloadArrivalReportPdf = () => {
    const link = document.createElement("a")
    let filename = `arrival-report-${data?.arrivalReport?.websiteId}.pdf`
    link.download = filename
    let path = `api/destination/arrival-reports/${data?.arrivalReport?.websiteId}/${data?.arrivalReport?.arrivalReportLinkId}`
    link.href = settings.psaV2ApiUrl + path
    link.click()
  }

  const handleDownloadClick = async (fileType: FileType) => {
    const result = 
      await downloadFile({variables: {
        input: {
          fileType,
          filter: { arrivalReportPictureFilters: { 
            websiteId: params.websiteId, 
            id: parseInt(params.arrivalReportLinkId)
          } }
        }
      }})
      
      if (!result?.data?.downloadFile?.downloadRequest?.id) {
        // Request failed, we don't need to do anything here as error will be available on hook.
      }
      else {
        navigate('/account/my-downloads')
      }
  }

  return (
    <>    
      <Paper
        elevation={0}
        sx={{ p: '1rem' }}
      >
        <Stack sx={{background: 'mainBackground.main'}}>
        
          <Typography variant='h6' sx={{flexGrow: 2}}>{ t('ArrivalReport', 'Arrival report') }</Typography>
        
        </Stack>

      </Paper>
      
      { loading ? <LinearProgress />

      : error?.graphQLErrors != null ?
        <Alert severity="error">
          { t('Error', 'Error') }: {error?.graphQLErrors?.map(e=>e.message)}
        </Alert>
      :

      <>

        <Paper
          elevation={0}
          sx={{ p: '1rem', backgroundColor: 'baseBackground.main' }}
        >
          
          <Stack 
            sx={{
              backgroundColor: 'mainBackground.main',            
            }}
          >
          
            <Box 
              sx={{
                backgroundColor: 'baseBackground.main',
                padding: '0 1rem 1rem 1rem'
              }}
            >
              <Grid container>
                <Grid item xs={12} md={6}>     
                  <b>{ t('Location', 'Location') }:</b> {data?.arrivalReport?.location?.locationName}<br />
                  <b>{ t('Arrival', 'Arrival') }:</b>{' '}
                  {data?.arrivalReport?.arrivalImpExp?.arrival?.arrivalName} - {' '}
                  {data?.arrivalReport?.arrivalImpExp?.arrivalCode}<br />
                  <b>{ t('Importer', 'Importer') }:</b> {data?.arrivalReport?.arrivalImpExp?.impExp?.importer?.importerName}<br />
                  <b>{ t('Exporter', 'Exporter') }:</b> {data?.arrivalReport?.arrivalImpExp?.impExp?.exporter?.exporterName}
                </Grid>

                <Grid item xs={12} md={6}>
                  <Button 
                    variant='outlined' 
                    startIcon={<Download />} 
                    size='large'   
                    sx={{mt: {xs: '1rem', md: 0} }}
                    onClick={handleDownloadArrivalReportPdf}
                  >            
                    { t('DownloadPrinterFriendly', 'Download printer-friendly version (PDF)') }
                  </Button>
                </Grid>
              </Grid>
            
            </Box>
            <Box 
              sx={{              
                padding: '1rem'
              }}
            >

              <Typography variant='h5' sx={{mb: '1rem'}}>
                { t('GeneralComments', 'General comments') }
              </Typography>

              { data?.arrivalReport?.generalComments?.map(comment =>
                <Stack direction='row'>
                  <Box sx={{mr: '3rem'}}>{comment.romanNumeral}</Box>
                  <Box>{comment.generalComment}</Box>
                </Stack>
              )}

            
              <Typography variant='h5' sx={{mt: '2rem', mb: '1rem' }}>
                { t('InspectionResults', 'Inspection results') }
              </Typography>

            </Box>
          </Stack>


          { data?.arrivalReport?.groups?.map(group =>
            <Stack 
              sx={{
                backgroundColor: 'mainBackground.main',            
              }}
            >
              <Box 
                sx={{              
                  padding: '1rem'
                }}
              >
                <Typography variant='h6'>
                  {group?.commodity?.commodityName}
                </Typography>

                <Typography variant='subtitle2'>
                  {group?.groupTitle}
                </Typography>

                { Boolean(group?.subtitle) && 
                  <Typography variant='caption'>
                    {group?.subtitle}
                  </Typography>
                }

                <Grid container>              
                  
                  { group?.qualityCondition.map(qc =>
                    <Grid item xs={12} md={6}>
                      
                      <Stack alignItems='center'>
                        <Typography variant='h6'>
                          { qc.type === 'Q' ? t('Quality', 'Quality') : t('Condition', 'Condition') }
                        </Typography>     

                        <OverallQCPieChart 
                          totals={{good: qc.good, average: qc.average, fair: qc.fair, poor: qc.poor }} 
                          size='large'
                          legendVisible 
                        />
                        
                        <Box sx={{ textAlign:'flex-start', whiteSpace: 'pre-wrap', flexGrow: 4 }}>
                          {qc.inspectionResults}
                        </Box>
                        <span></span>
                      </Stack>

                    </Grid>
                  )}
                  
                </Grid>
              
              </Box>
                        
              { open &&
                <PictureViewerDialog 
                  title={''}
                  open={open} 
                  initialPicture={selectedPicture} 
                  pictures={(group?.pictures ?? []) as Picture[]} 
                  onClose={()=>{setOpen(false)}}
                />
              }

              <Grid container spacing={1}>
                { group?.pictures?.map((picture) => (
                  <Grid item>
                    <PictureCard 
                      url={`${settings.psaV2ApiUrl}picture/${picture.websiteId}?thumbnail=true`}
                      alt={picture.description}
                      tags={[picture.description]}
                      onClick={()=>{handleClick(picture)}}
                    />
                  </Grid>
                ))}
              </Grid>
            </Stack>

          )}

          <Stack mt={6} mb={6} direction={'row'} spacing={2}>
            <Button 
              variant='outlined' 
              startIcon={<Download />} 
              size='large'
              disabled={loadingDownloadFile || !Boolean(data?.arrivalReport?.id)}
              onClick={() => {
                handleDownloadClick(FileType.ArrivalReportPictureZip)
              }}
            >            
              { t('DownloadAllPictures', 'Download all pictures (ZIP)') }            
            </Button>
                        
          </Stack>

        </Paper>
        
      </>  
      }
      
    </>
  )
}
