import * as React from 'react'
import { useNavigate  } from 'react-router-dom'
import { Alert, Autocomplete, Box, Button, Divider, LinearProgress, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useAppState } from 'src/hooks/useAppState'
import { SelectedFilters } from 'src/typeDefinitions/appTypes'
import { useInspectionQueryFilter } from './useInspectionQueryFilter'
import { Filters } from 'src/components/Filters'
import { InspectionsDetails } from './InspectionsDetails'
import { Pagination } from 'src/components/Pagination'
import { Download } from '@mui/icons-material'
import { useConvertRawFiltersToFilterOptions } from './useConvertRawFiltersToFilterOptions'
import { useAllInspectionGroupsQuery, useFilterOptionsQuery, useDownloadFileMutation, FileType, InspectionGroup } from 'src/utils/__generated__/graphql'
import { ErrorAlert } from 'src/components/ErrorAlert'

export const Inspections = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { seasonId } = useAppState()
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters>({
    locations: [],
    importers: [],
    exporters: [],
    commodities: [],
    varieties: [],
    sizes: [],
    packCodes: [],
    growers: [],
    labels: []
  })
  const { filterOptionsVariables, inspectionGroupVariables, maxPageSize, setPageFilter } = useInspectionQueryFilter(selectedFilters, seasonId)
  const { loading, error, data } = useAllInspectionGroupsQuery({
    variables: inspectionGroupVariables
  })
  const { loading: loadingFilters, error: filterError, data: filterData } = useFilterOptionsQuery({
    variables: filterOptionsVariables
  })
  const filtersOptions = useConvertRawFiltersToFilterOptions(filterData)
  const [downloadFile, {loading: loadingDownloadFile, error: downloadFileError}] = useDownloadFileMutation()    

  const handleFiltersSelected = (newSelectedFilters) => {
    setPageFilter({
      last: null,
      before: null,
      first: maxPageSize,
      after: null
    })
    setSelectedFilters(newSelectedFilters)
  }  

  const handleDownloadClick = async (fileType: FileType) => {
    const result = 
      await downloadFile({variables: {
        input: {
          fileType,
          filter: { inspectionFilters: {...filterOptionsVariables.filterInput.filter} }
        }
      }})
      
      if (!result?.data?.downloadFile?.downloadRequest?.id) {
        // Request failed, we don't need to do anything here as error will be available on hook.
      }
      else {
        navigate('/account/my-downloads')
      }
  }  

  return (
    <>
      
      <Paper
        elevation={0}
        sx={{ p: '1rem' }}
      >
        <Stack sx={{background: 'mainBackground.main'}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent='space-between' sx={{paddingBottom: 2}} >
            <Typography variant='h6' sx={{flexGrow: 2}}>
              {t('Inspections', 'Inspections')}
            </Typography>            
          </Stack>
                  
          <Filters 
            loading={loadingFilters}
            filtersOptions={filtersOptions} 
            filterUiData={[{
              title: t('Location', 'Location'),
              filterType: 'locations',
              filterComponentType: 'Standard'
            }, {
              title: t('Arrival', 'Arrival'),
              filterType: 'arrivals',
              filterComponentType: 'Standard'
            }, {
              title: t('Importer', 'Importer'),
              filterType: 'importers',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Exporter', 'Exporter'),
              filterType: 'exporters',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: `${t('Commodity', 'Commodity')} & ${t('Variety', 'Variety')}`,
              filterType: 'commodities',
              screenSize: 'md',
              filterComponentType: 'Commodity'
            }, {
              title: t('Grower', 'Grower'),
              filterType: 'growers',
              screenSize: 'lg',
              filterComponentType: 'Standard'
            }, {
              title: t('Size', 'Size'),
              filterType: 'sizes',
              screenSize: 'lg',
              filterComponentType: 'Standard'
            }, {
              title: t('Label', 'Label'),
              filterType: 'labels',
              screenSize: 'lg',
              filterComponentType: 'Standard'
            }, {
              title: t('PackCode', 'Pack code'),
              filterType: 'packCodes',
              screenSize: 'lg',
              filterComponentType: 'Standard'
            }]}
            selectedFilters={selectedFilters}        
            onFiltersSelected={handleFiltersSelected} 
          />          

        </Stack>

      </Paper>
      <Paper
        elevation={0}
        sx={{ p: '1rem', backgroundColor: 'baseBackground.main', minHeight: '100vh' }}
      >
        { loading ? <LinearProgress />
        : !!(error || filterError) ? <ErrorAlert error={error || filterError} /> :
        <>
          <Box sx={{paddingBottom: '2rem'}}><Typography variant='caption'></Typography></Box>

          <Stack
            direction="column"
            divider={<Divider orientation="horizontal" flexItem />}
            spacing={2}          
          >

            <Box display='grid' gridTemplateColumns={{xs: 'minmax(0, 1fr)', lg: 'repeat(2, minmax(0, 1fr))'}} gap={2} >

              { data?.inspectionGroups?.nodes?.map(dataGroup => (                                            
                  <InspectionsDetails 
                    inspectionGroup={dataGroup as InspectionGroup}
                    inspectionFilters={{...filterOptionsVariables.filterInput.filter}}
                  />                
              ))}                

            </Box>

          </Stack>
          
          <Pagination 
            maxPageSize={maxPageSize}
            totalPages={Math.ceil(data?.inspectionGroups?.totalCount / maxPageSize)}
            pageInfo={data?.inspectionGroups?.pageInfo}            
            setPageFilter={setPageFilter}
          />
          
          { downloadFileError?.graphQLErrors != null &&
            <Alert severity="error">
              { t('Error', 'Error') }: {downloadFileError?.graphQLErrors?.map(e=>e.message)}
            </Alert>
          }
          
          <Stack mt={6} mb={6} direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <Button 
              variant='outlined' 
              startIcon={<Download />} 
              size='large'
              disabled={loadingDownloadFile || data?.inspectionGroups?.nodes?.length === 0}
              onClick={() => {
                handleDownloadClick(FileType.InspectionFormattedReportPdf)
              }}
            >            
              { t('DownloadFormattedReport', 'Download formatted report (PDF)') }
            </Button>
            
            <Button 
              variant='outlined'                 
              startIcon={<Download />} 
              size='large'
              disabled={loadingDownloadFile || data?.inspectionGroups?.nodes?.length === 0}
              onClick={() => {
                handleDownloadClick(FileType.InspectionSpreadsheet)
              }}
            >            
              { t('DownloadSpread', 'Download spreadsheet (XLSX)') }
            </Button>

            <Button 
              variant='outlined'                 
              startIcon={<Download />} 
              size='large'
              disabled={loadingDownloadFile || data?.inspectionGroups?.nodes?.length === 0}
              onClick={() => {
                handleDownloadClick(FileType.PictureZip)
              }}
            >            
              { t('DownloadAllPictures', 'Download all pictures (ZIP)') }
            </Button>
            
          </Stack>
          
        </>
        }  
      </Paper>
    </>
  )
}