import React from 'react'
import { Breakpoint, Stack, Theme, useTheme } from '@mui/material';

import { Search } from './Search'
import { FiltersSelectedSummary } from './FiltersSelectedSummary'
import { FiltersSelectedExpanded } from './FiltersSelectedExpanded'
import { FilterButton } from './FilterButton'
import { FilterCommodityVariety } from './FilterCommodityVariety'
import { Filter } from './Filter'
import { FilterOptionObject, FiltersOptions, FilterUiData, FlattenedFilter, SelectedFilters } from 'src/typeDefinitions/appTypes'
import { FilterMore } from './FilterMore';
import { useWindowDimensions } from 'src/hooks/useWindowDimensions'

interface Props {
  loading?: boolean,
  filtersOptions: FiltersOptions,
  filterUiData: FilterUiData[],
  selectedFilters: SelectedFilters,
  onFiltersSelected: (selectedFilters) => void
}

export const Filters = ({
  loading = false,
  filtersOptions,
  filterUiData,
  selectedFilters,
  onFiltersSelected
}: Props) => {  
  const initialSelectedFilters = React.useMemo<SelectedFilters>(()=> {
    let temp = {}
    filterUiData.forEach( f => temp[f.filterType] = [] )
    return temp
  }, [])
  const [flatFiltersOptions, setFlatFiltersOptions] = React.useState<FlattenedFilter[]>([])
  const [adjustedFiltersOptions, setAdjustedFiltersOptions] = React.useState(initialSelectedFilters)  
  const [flatSelectedFilters, setFlatSelectedFilters] = React.useState<FlattenedFilter[]>([])
  const [displayingExpandedFilters, setDisplayingExpandedFilters] = React.useState(false)
  const theme = useTheme<Theme>()
  const { width }  = useWindowDimensions()
  const [filterUiDataForMoreButton, setFilterUiDataForMoreButton] = React.useState<FilterUiData[]>()
  
  const handleDone = (newFilters: SelectedFilters) => {
    // merge filters ontop of currently selected filters
    let temp = {...selectedFilters, ...newFilters}    
    onFiltersSelected(temp)
  }

  const handleSearch = (flatFilter: FlattenedFilter) => {
    const temp = {
      [flatFilter.filterType]: [flatFilter.filter]
    }    
    // set new selected filters    
    onFiltersSelected(temp)
  }
  
  const handleDeleteFilter = (filterType: string, filter: FilterOptionObject) => {
    // remove filter from that filter type.
    let newFilters = selectedFilters[filterType]
      ?.filter(f => f.value !== filter.value) 

    if (!!newFilters) {
      // merge existing filters with new filters
      let temp = {...selectedFilters, [filterType]: newFilters}            
      onFiltersSelected(temp)
    }
  }
  
  const handleResetFilters = () => {          
      onFiltersSelected(initialSelectedFilters)
  }

  // When window is resized, updated more button filter data.
  React.useEffect(() => {
    setFilterUiDataForMoreButton(filterUiData.filter(f => {
      return theme.breakpoints.values[f.screenSize] > width
    }))
  }, [width])

  // Flatten filters options when changed.
  React.useEffect(() => {
    const temp = Object.entries(filtersOptions)
      .reduce((acc, [filterType, filters]) => {      
        return acc.concat(filters.map(filter => {          
          return { filterType, filter }          
        }))      
      }, [])
    setFlatFiltersOptions(temp) 
    
  }, [filtersOptions])
  
  // Flatten selected filters when selected filters changes.
  React.useEffect(() => {
    const temp = Object.entries(selectedFilters)
      .reduce((acc, [filterType, filters]) => {      
        return acc.concat(filters.map(filter => {
          return {filterType, filter}
        }))      
      }, [])
    setFlatSelectedFilters(temp)
    
  }, [selectedFilters])
  
  // If selected filters change and none are selected, hide expanded filters view.
  React.useEffect(() => {
    if (flatSelectedFilters.length === 0 && displayingExpandedFilters)
      setDisplayingExpandedFilters(false)

  }, [flatSelectedFilters])

  // Cache original filters when new FiltersOptions are fetched.
  // This helps display the complete list of filters in a filter type that was selected.
  // EG
  // Selected Filter - Size
  // [X] SM
  // [] MD
  // [] LG
  // 
  // FiltersOptions - Size  (after fetching new filters)
  // [X] SM
  React.useEffect(() => {
    let temp = {...adjustedFiltersOptions}
    
    let cacheOriginalFilters = false
    
    for (const [filterType] of Object.entries(filtersOptions)) {
      if (cacheOriginalFilters && selectedFilters[filterType]?.length > 0) {
        // filters were selected, so leave the original cached filterOptions. Do nothing here.
      }
      else {
        // no filter was selected for this filter type, so replace those filters with new list.
        temp[filterType] = filtersOptions[filterType]        
      }    
    }
    
    setAdjustedFiltersOptions(temp)
  }, [filtersOptions])

  return (
    <>
      <Stack
        direction="column"
        justifyContent="space-evenly"
        spacing={2}        
      >
        
        <Stack 
          direction='row' 
          alignItems='center'
          justifyContent="space-evenly"
          spacing={2}
        >
          <Search
            loading={loading}
            {...{flatFiltersOptions}} 
            onSearch={handleSearch}
          />
        
          { flatSelectedFilters.length > 0 &&
            <FiltersSelectedSummary
              {...{selectedFilters}}
              {...{flatSelectedFilters}}               
              {...{displayingExpandedFilters}}
              onDeleteFilter={handleDeleteFilter}
              onToggleDisplayingExpandedFilters={()=>setDisplayingExpandedFilters(x=>!x)}
            />
          }

        </Stack>

        { displayingExpandedFilters &&
          <FiltersSelectedExpanded 
            {...{selectedFilters}} 
            {...{flatSelectedFilters}}
            onDeleteFilter={handleDeleteFilter}
            onResetFilters={handleResetFilters}
          />
        }
       
        <Stack spacing={2} direction="row" alignContent='stretch' flexWrap='wrap'>
          { filterUiData.map(fd => (
            <FilterButton
              key={fd.title}               
              title={fd.title} 
              loading={loading}
              screenSize={fd.screenSize}
              filterType={fd.filterType}
              // @ts-ignore
              filterOptions={
                fd.filterComponentType === 'Standard' ? adjustedFiltersOptions[fd.filterType]
                  : fd.filterComponentType === 'Commodity' && adjustedFiltersOptions
              } 
              selectedFilters={selectedFilters}
              onDone={handleDone} 
              popupComponent={
                fd.filterComponentType === 'Standard' ? Filter
                  : fd.filterComponentType === 'Commodity' && FilterCommodityVariety
              } 
            />
          ))}
          
          { filterUiDataForMoreButton?.length > 0 &&
            <FilterButton 
              title='More'             
              // @ts-ignore
              filterOptions={adjustedFiltersOptions}
              selectedFilters={selectedFilters} 
              filterUiData={filterUiDataForMoreButton}
              onDone={handleDone} 
              popupComponent={FilterMore} 
            />
          }
        </Stack>

      </Stack>
    </>
  )
}