import React from 'react'

import { PageFilter, SelectedFilters } from 'src/typeDefinitions/appTypes'
import { InputMaybe, PictureFilterInput, PictureFiltersInput } from 'src/utils/__generated__/graphql'

type Variables = {
  seasonId,
  filter
}

type FilterOptionsVariables = {
  filterInput: PictureFiltersInput
}

/**
  * Takes the selected filters and converts them into friendly GraphQL variables.
  * @param selectedFilters filters that the user has selected
  * @param seasonId seasonId filter
  * @returns object \{ variables \} to use in GraphQL query to filter the data and filterOptions
  */
export const usePictureQueryFilter = (selectedFilters: SelectedFilters, seasonId: number) => {
  const maxPageSize = 50
  const [pageFilter, setPageFilter] = React.useState<PageFilter>({
    last: null,
    before: null,
    first: maxPageSize,
    after: null
  })

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto"
    })
  }, [[selectedFilters, seasonId, pageFilter]])
  
  const filterOptionsVariables = React.useMemo<FilterOptionsVariables>(
    () => {
      // Prepare reusable local variables.      
      let locationIds: number[] = selectedFilters?.locations?.map(f => f.value as number)
      let importerIds: number[] = selectedFilters?.importers?.map(f => f.value as number)
      let exporterIds: number[] = selectedFilters?.exporters?.map(f => f.value as number)
      let commodityIds: number[] = selectedFilters?.commodities?.map(f => f.value as number)
      let varietyIds: number[] = selectedFilters?.varieties?.map(f => f.value as number)
      let growerIds: number[] = selectedFilters?.growers?.map(f => f.value as number)
      let sizes: string[] = selectedFilters?.sizes?.map(f => f.value as string)
      let labelCodes: string[] = selectedFilters?.labels?.map(f => f.value as string)
      let packCodes: string[] = selectedFilters?.packCodes?.map(f => f.value as string)
      

      // FilterInput *****************************************************************
      let filterInput = {
        seasonId,                
      }
      
      // Locations
      if (!!locationIds && locationIds.length > 0)
        filterInput['locationIds'] = locationIds      
    
      // Arrivals
      if (selectedFilters?.arrivals?.length > 0) {
        filterInput['arrivalFilters'] =          
          selectedFilters.arrivals.map(a => {
            return {
              arrivalId: a.arrivalId,
              arrivalCode: a.arrivalCode
            }
          })          
      }

      // Importers
      if (!!importerIds && importerIds.length > 0)
        filterInput['importerIds'] = importerIds

      // Exporters
      if (!!exporterIds && exporterIds.length > 0)
        filterInput['exporterIds'] = exporterIds

      // Commodities
      if (!!commodityIds && commodityIds.length > 0)
        filterInput['commodityIds'] = commodityIds

      // Varieties
      if (!!varietyIds && varietyIds.length > 0)
        filterInput['varietyIds'] = varietyIds
        
      // Growers
      if (!!growerIds && growerIds.length > 0)
        filterInput['growerIds'] = growerIds

      // Sizes
      if (!!sizes && sizes.length > 0)
        filterInput['sizes'] = sizes

      // Labels
      if (!!labelCodes && labelCodes.length > 0)
        filterInput['labelCodes'] = labelCodes

      // PackCodes
      if (!!packCodes && packCodes.length > 0)
        filterInput['packCodes'] = packCodes
      
      return {        
        filterInput: filterInput        
      }
    }, 
    [selectedFilters, seasonId]
  )

  const variables = React.useMemo<Variables>(
    () => {
      // Prepare reusable local variables.      
      let locationIds: number[] = selectedFilters?.locations?.map(f => f.value as number)
      let importerIds: number[] = selectedFilters?.importers?.map(f => f.value as number)
      let exporterIds: number[] = selectedFilters?.exporters?.map(f => f.value as number)
      let commodityIds: number[] = selectedFilters?.commodities?.map(f => f.value as number)
      let varietyIds: number[] = selectedFilters?.varieties?.map(f => f.value as number)
      let sizes: string[] = selectedFilters?.sizes?.map(f => f.value as string)
      let packCodes: string[] = selectedFilters?.packCodes?.map(f => f.value as string)
      let growerIds: number[] = selectedFilters?.growers?.map(f => f.value as number)
      let labelCodes: string[] = selectedFilters?.labels?.map(f => f.value as string)
      

      // GroupFilter *****************************************************************
      let filterDraft: InputMaybe<Array<PictureFilterInput>> = []
   
      if (Boolean(seasonId))
        filterDraft.push({pallet: {seasonId: {eq: seasonId}}})

      // Locations
      if (locationIds?.length > 0) {
        let locationFilter: PictureFilterInput = 
        { pallet: { locationId: {in:
          locationIds
        }}}
        filterDraft.push(locationFilter)
      }

      // Arrivals
      if (selectedFilters?.arrivals?.length > 0) {
        let arrivalFilter: PictureFilterInput =         
          {pallet: {arrivalImpExp: {and: 
            selectedFilters.arrivals.map(a => {
              return {and: [
                {arrivalId: {eq: a.arrivalId}},
                {arrivalCode: {eq: a.arrivalCode}}              
              ]}
            })
          }}}
        filterDraft.push(arrivalFilter)
      }

      // Importers
      if (importerIds?.length > 0) {
        let importerFilter: PictureFilterInput = 
          {pallet: {arrivalImpExp:{impExp: {importerId: {in:
            importerIds
          }}}}}
        filterDraft.push(importerFilter)
      }

      // Exporters
      if (exporterIds?.length > 0) {
        let exporterFilter: PictureFilterInput = 
          {pallet: {arrivalImpExp:{impExp: {exporterId: {in:
            exporterIds
          }}}}}
        filterDraft.push(exporterFilter)
      }

      // Commodities
      if (commodityIds?.length > 0) {
        let commodityFilter: PictureFilterInput =         
        {pallet: {varietyImpExp: {variety: {commodityId: {in: 
          commodityIds
        }}}}}
        filterDraft.push(commodityFilter)
      }

      // Varieties
      if (varietyIds?.length > 0) {        
        let varietyFilter: PictureFilterInput =         
        {pallet: {varietyImpExp: {varietyId: {in: 
          varietyIds
        }}}}
        filterDraft.push(varietyFilter)        
      }
      
      // Growers
      if (growerIds?.length > 0) {
        let growerFilter: PictureFilterInput =         
          {pallet: {growerImpExp: {growerId: {in: 
            growerIds
          }}}}
        filterDraft.push(growerFilter)
      }
      
      
      // Sizes
      if (sizes?.length > 0) {              
        let sizeFilter: PictureFilterInput =         
          {pallet: {size: {in: 
            sizes
          }}}
        filterDraft.push(sizeFilter)
      }

      // Labels
      if (labelCodes?.length > 0) {
        if (labelCodes?.length > 0) {
          let labelFilter: PictureFilterInput =         
            {pallet: {labelImpExp: {labelCode: {in: 
              labelCodes
            }}}}
          filterDraft.push(labelFilter)
        }
      }        

      // PackCodes
      if (packCodes?.length > 0) {
        let packCodeFilter: PictureFilterInput =         
          {pallet: {packTypeImpExp: {packCode: {in: 
            packCodes
          }}}}
        filterDraft.push(packCodeFilter)
      }
            
      const filter: PictureFilterInput = {      
        and: filterDraft                    
      }

      
      return {
        last: pageFilter.last,
        before: pageFilter.before,
        first: pageFilter.first,
        after: pageFilter.after,
        seasonId,
        filter
      }
    }, 
    [selectedFilters, seasonId, pageFilter]
  )

  return {
    variables,
    filterOptionsVariables,
    maxPageSize,
    setPageFilter
  }
}