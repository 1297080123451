import { useTheme } from '@emotion/react'
import ReactApexChart from 'react-apexcharts'
import { useTranslation } from 'react-i18next'

interface OverallQCTotals {
  good: number,
  average: number,
  fair: number,
  poor: number
}

interface Props {
  /**
   * Object literal of totals for each category.   
   */
   totals: OverallQCTotals,
   size?: 'large' | 'small',
   legendVisible?: boolean
}
/**
 *
 * Displays basic pie chart used to display overall Quality or Condition stats.
 * 
 */
export const OverallQCPieChart = ({
  totals,
  size = 'small',
  legendVisible = false
}: Props) => {
  const { t } = useTranslation()
  const data = [
    totals.good * 100,
    totals.average * 100,
    totals.fair * 100,
    totals.poor * 100
  ]
  let theme : any
  theme = useTheme()

  return (
    <ReactApexChart     
      type="pie" 
      width={size === 'large' ? 340 : 120}      
      //height='100%'
      series={data}
      options={ {        
        chart: {
          width: size === 'large' ? 340 : 120 ,
          type: 'pie',
          background: theme.palette.mainBackground.main,
          foreColor: theme.palette.contrastButtonText
        },
        plotOptions: {
          pie: {         
            startAngle: 90,            
            endAngle: 450,
            expandOnClick: false,
            customScale: 1,
          }
        },
        colors: [
          '#4caf50',
          '#cddc39',
          '#ff9800',
          '#f44336'
        ],
        fill: {
          colors: [
            '#4caf50',
            '#cddc39',
            '#ff9800',
            '#f44336'
          ]
        },
        labels: [
          `${ t('Good', 'Good') } - ${ totals.good * 100 }%`, 
          `${ t('Average', 'Average') } - ${ totals.average * 100 }%`,
          `${ t('Fair', 'Fair') } - ${ totals.fair * 100}%`, 
          `${ t('Poor', 'Poor') } - ${ totals.poor * 100 }%`
        ],
        
        tooltip: {fillSeriesColor: false},
        dataLabels: {enabled: false},
        legend: {show: legendVisible},
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 120
            },              
            legend: {show: false},
          }
        }]
      }}
    />
  )
}

/**
 *
 * Converts an array of OverallQ or OverallC values, and converts into a totals object.
 * 
 */
export const groupOverallQCIntoTotals = (data: string[], type: 'OverallQ' | 'OverallC') => {
  const totals = data.reduce<OverallQCTotals>(
    (accumulator, currentItem) => {
      const currentNumber = parseInt(currentItem)
      if ([2,3].includes(currentNumber)) {
        accumulator.good += 1
      }

      if (currentNumber === 4) {
        accumulator.average += 1
      }

      if (currentNumber === 5) {
        accumulator.fair += 1
      }

      if (
        [6,7].includes(currentNumber) 
        || (currentNumber === 1 && type === 'OverallC')
      ) {
        accumulator.poor += 1
      }

      return accumulator
    }, {
      good: 0,
      average: 0,
      fair: 0,
      poor: 0
    }
  )
   
  return totals 
}

export const getOverallQualityConditionLegend = (value: string, type: string) => {  
  if (!Boolean(value))
    return ''

  const currentNumber = parseInt(value)

  if ([2,3].includes(currentNumber)) {
    return 'Good'
  }

  if (currentNumber === 4) {
    return 'Average'
  }

  if (currentNumber === 5) {
    return 'Fair'
  }

  if (
    [6,7].includes(currentNumber) 
    || (currentNumber === 1 && type === 'OverallC')
  ) {
    return 'Poor'
  }

  return ''
}