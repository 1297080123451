import React from 'react'
import { Box, Typography } from "@mui/material"
import { useAuth } from "react-oidc-context"

type Props = {
  children?: React.ReactNode | undefined
}

export const ConstructionOverlay = ({children}: Props) => {
  const auth = useAuth()
  const [demoEnabled, setDemoEnabled] = React.useState(false)

  const handleBlur = (e) => {
    if (e.target.value === 'demo2023') {
      localStorage.setItem('demo', '1')
      setDemoEnabled(true)
    }
  }

  React.useEffect(() => {
    if (localStorage.getItem('demo') === '1') {
      setDemoEnabled(true)
    }
  }, [])

  if (auth.isAuthenticated || demoEnabled)
  {
    return (
      <div>
        {children}
      </div>
    )
  }

  return (      
      <Box
        sx={{
          height: '30rem'
        }}
        p={6}
      >
        <Typography 
          sx={{
            fontSize: '2rem', 
            lineHeight: '1', 
            fontWeight: '900',             
            paddingBottom: '2rem'
          }}          
        >
          Produce Services of America, Inc.
        </Typography>  
        
        <a href='https://www.psaconnection.com'>Please click here to visit PSA's website</a>

        <Box
          sx={{
            paddingTop: '30rem'
          }}          
        >
          <input onBlur={handleBlur} />
          

        </Box>
      </Box>
  )
}