/*
*** AUTO-GENERATED ***

This file has been generated by a code generator. Please do not manually modify this file.
To make changes, modify the templates in the code generator.

*/

import { Box, LinearProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useAppState } from 'src/hooks/useAppState'
import { useGetInspectionsAvocadoByIdsQuery } from 'src/utils/__generated__/graphql'

interface Props {
  inspectionIds: number[]
}

export const InspectionsAvocado = ({
  inspectionIds
}: Props) => {
  const { t } = useTranslation()
  const { seasonId } = useAppState()  
  const { loading, error, data } = useGetInspectionsAvocadoByIdsQuery({
    variables: {
      seasonId: seasonId,
      where: {or:
        inspectionIds.map((id) => ({id: {eq: id}}) )
      }
    }
  })

  const renderDetail = (value, key) => (
    <Box 
      key={key}
      sx={{minHeight:'1rem', 
        overflow: 'hidden',
        borderBottom: '1px solid',
        borderColor: 'rowDivider.main'
      }}
    >
      {value}
    </Box>
  )

  if (loading) return (<div><LinearProgress /></div>)
  if (error) return (<p>Error: {JSON.stringify(error)}</p>)

  return (          
    <Box>            
      <Box 
        sx={{display: 'grid',
          minWidth: '16rem',
          //width: 'max-content',
          width: `${(inspectionIds?.length * 6 ?? 1) + 10}rem`,                            
          gridAutoFlow: 'column',
          gridTemplateRows: `repeat(51, min-content)`,
          gridGap: '0px'
        }}
      >                
        {renderDetail(t('InspGrowerCode', 'InspGrowerCode'), 'InspGrowerCode')}
        {renderDetail(t('InspPackDate', 'InspPackDate'), 'InspPackDate')}
        {renderDetail(t('InspSize', 'InspSize'), 'InspSize')}
        {renderDetail(t('InspPackCode', 'InspPackCode'), 'InspPackCode')}
        {renderDetail(t('Count', 'Count'), 'Count')}
        {renderDetail(t('PLU', 'PLU'), 'PLU')}
        {renderDetail(t('PLUPER', 'PLUPER'), 'PLUPER')}
        {renderDetail(t('CountryOfOrigin', 'CountryOfOrigin'), 'CountryOfOrigin')}
        {renderDetail(t('Weight', 'Weight'), 'Weight')}
        {renderDetail(t('UnderweightMin', 'UnderweightMin'), 'UnderweightMin')}
        {renderDetail(t('UnderweightMax', 'UnderweightMax'), 'UnderweightMax')}
        {renderDetail(t('WeighedUnits', 'WeighedUnits'), 'WeighedUnits')}
        {renderDetail(t('UnderweightUnits', 'UnderweightUnits'), 'UnderweightUnits')}
        {renderDetail(t('UnderweightPER', 'UnderweightPER'), 'UnderweightPER')}
        {renderDetail(t('PulpTemp', 'PulpTemp'), 'PulpTemp')}
        {renderDetail(t('Opening', 'Opening'), 'Opening')}
        {renderDetail(t('Color', 'Color'), 'Color')}
        {renderDetail(t('TurningColorPieces', 'TurningColorPieces'), 'TurningColorPieces')}
        {renderDetail(t('TurningColorPER', 'TurningColorPER'), 'TurningColorPER')}
        {renderDetail(t('ScarsPieces', 'ScarsPieces'), 'ScarsPieces')}
        {renderDetail(t('ScarsPER', 'ScarsPER'), 'ScarsPER')}
        {renderDetail(t('ScarsDEG', 'ScarsDEG'), 'ScarsDEG')}
        {renderDetail(t('CutsPieces', 'CutsPieces'), 'CutsPieces')}
        {renderDetail(t('CutsPER', 'CutsPER'), 'CutsPER')}
        {renderDetail(t('CutsDEG', 'CutsDEG'), 'CutsDEG')}
        {renderDetail(t('BruisingPieces', 'BruisingPieces'), 'BruisingPieces')}
        {renderDetail(t('BruisingPER', 'BruisingPER'), 'BruisingPER')}
        {renderDetail(t('BruisingDEG', 'BruisingDEG'), 'BruisingDEG')}
        {renderDetail(t('LenticelBreakdownPieces', 'LenticelBreakdownPieces'), 'LenticelBreakdownPieces')}
        {renderDetail(t('LenticelBreakdownPER', 'LenticelBreakdownPER'), 'LenticelBreakdownPER')}
        {renderDetail(t('LenticelBreakdownDEG', 'LenticelBreakdownDEG'), 'LenticelBreakdownDEG')}
        {renderDetail(t('ExternalDiscolorationPieces', 'ExternalDiscolorationPieces'), 'ExternalDiscolorationPieces')}
        {renderDetail(t('ExternalDiscolorationPER', 'ExternalDiscolorationPER'), 'ExternalDiscolorationPER')}
        {renderDetail(t('ExternalDiscolorationDEG', 'ExternalDiscolorationDEG'), 'ExternalDiscolorationDEG')}
        {renderDetail(t('DecayPieces', 'DecayPieces'), 'DecayPieces')}
        {renderDetail(t('DecayPER', 'DecayPER'), 'DecayPER')}
        {renderDetail(t('DecayDEG', 'DecayDEG'), 'DecayDEG')}
        {renderDetail(t('MoldPieces', 'MoldPieces'), 'MoldPieces')}
        {renderDetail(t('MoldPER', 'MoldPER'), 'MoldPER')}
        {renderDetail(t('Firmness', 'Firmness'), 'Firmness')}
        {renderDetail(t('Pressure1', 'Pressure1'), 'Pressure1')}
        {renderDetail(t('Pressure2', 'Pressure2'), 'Pressure2')}
        {renderDetail(t('Pressure3', 'Pressure3'), 'Pressure3')}
        {renderDetail(t('Pressure4', 'Pressure4'), 'Pressure4')}
        {renderDetail(t('Pressure5', 'Pressure5'), 'Pressure5')}
        {renderDetail(t('Pressure6', 'Pressure6'), 'Pressure6')}
        {renderDetail(t('PressuresMin', 'PressuresMin'), 'PressuresMin')}
        {renderDetail(t('PressuresMax', 'PressuresMax'), 'PressuresMax')}
        {renderDetail(t('PressuresAvg', 'PressuresAvg'), 'PressuresAvg')}
        {renderDetail(t('OverallQ', 'OverallQ'), 'OverallQ')}
        {renderDetail(t('OverallC', 'OverallC'), 'OverallC')}

        {data?.inspections?.nodes?.map((inspection, i) => {
          return (
            <>
              {renderDetail(inspection.inspectionDetail['inspGrowerCode'], `${inspection.id}-inspGrowerCode`)}
              {renderDetail(inspection.inspectionDetail['inspPackDate'], `${inspection.id}-inspPackDate`)}
              {renderDetail(inspection.inspectionDetail['inspSize'], `${inspection.id}-inspSize`)}
              {renderDetail(inspection.inspectionDetail['inspPackCode'], `${inspection.id}-inspPackCode`)}
              {renderDetail(inspection.inspectionDetail['count'], `${inspection.id}-count`)}
              {renderDetail(inspection.inspectionDetail['pLU'], `${inspection.id}-pLU`)}
              {renderDetail(inspection.inspectionDetail['pLUPER'], `${inspection.id}-pLUPER`)}
              {renderDetail(inspection.inspectionDetail['countryOfOrigin'], `${inspection.id}-countryOfOrigin`)}
              {renderDetail(inspection.inspectionDetail['weight'], `${inspection.id}-weight`)}
              {renderDetail(inspection.inspectionDetail['underweightMin'], `${inspection.id}-underweightMin`)}
              {renderDetail(inspection.inspectionDetail['underweightMax'], `${inspection.id}-underweightMax`)}
              {renderDetail(inspection.inspectionDetail['weighedUnits'], `${inspection.id}-weighedUnits`)}
              {renderDetail(inspection.inspectionDetail['underweightUnits'], `${inspection.id}-underweightUnits`)}
              {renderDetail(inspection.inspectionDetail['underweightPER'], `${inspection.id}-underweightPER`)}
              {renderDetail(inspection.inspectionDetail['pulpTemp'], `${inspection.id}-pulpTemp`)}
              {renderDetail(inspection.inspectionDetail['opening'], `${inspection.id}-opening`)}
              {renderDetail(inspection.inspectionDetail['color'], `${inspection.id}-color`)}
              {renderDetail(inspection.inspectionDetail['turningColorPieces'], `${inspection.id}-turningColorPieces`)}
              {renderDetail(inspection.inspectionDetail['turningColorPER'], `${inspection.id}-turningColorPER`)}
              {renderDetail(inspection.inspectionDetail['scarsPieces'], `${inspection.id}-scarsPieces`)}
              {renderDetail(inspection.inspectionDetail['scarsPER'], `${inspection.id}-scarsPER`)}
              {renderDetail(inspection.inspectionDetail['scarsDEG'], `${inspection.id}-scarsDEG`)}
              {renderDetail(inspection.inspectionDetail['cutsPieces'], `${inspection.id}-cutsPieces`)}
              {renderDetail(inspection.inspectionDetail['cutsPER'], `${inspection.id}-cutsPER`)}
              {renderDetail(inspection.inspectionDetail['cutsDEG'], `${inspection.id}-cutsDEG`)}
              {renderDetail(inspection.inspectionDetail['bruisingPieces'], `${inspection.id}-bruisingPieces`)}
              {renderDetail(inspection.inspectionDetail['bruisingPER'], `${inspection.id}-bruisingPER`)}
              {renderDetail(inspection.inspectionDetail['bruisingDEG'], `${inspection.id}-bruisingDEG`)}
              {renderDetail(inspection.inspectionDetail['lenticelBreakdownPieces'], `${inspection.id}-lenticelBreakdownPieces`)}
              {renderDetail(inspection.inspectionDetail['lenticelBreakdownPER'], `${inspection.id}-lenticelBreakdownPER`)}
              {renderDetail(inspection.inspectionDetail['lenticelBreakdownDEG'], `${inspection.id}-lenticelBreakdownDEG`)}
              {renderDetail(inspection.inspectionDetail['externalDiscolorationPieces'], `${inspection.id}-externalDiscolorationPieces`)}
              {renderDetail(inspection.inspectionDetail['externalDiscolorationPER'], `${inspection.id}-externalDiscolorationPER`)}
              {renderDetail(inspection.inspectionDetail['externalDiscolorationDEG'], `${inspection.id}-externalDiscolorationDEG`)}
              {renderDetail(inspection.inspectionDetail['decayPieces'], `${inspection.id}-decayPieces`)}
              {renderDetail(inspection.inspectionDetail['decayPER'], `${inspection.id}-decayPER`)}
              {renderDetail(inspection.inspectionDetail['decayDEG'], `${inspection.id}-decayDEG`)}
              {renderDetail(inspection.inspectionDetail['moldPieces'], `${inspection.id}-moldPieces`)}
              {renderDetail(inspection.inspectionDetail['moldPER'], `${inspection.id}-moldPER`)}
              {renderDetail(inspection.inspectionDetail['firmness'], `${inspection.id}-firmness`)}
              {renderDetail(inspection.inspectionDetail['pressure1'], `${inspection.id}-pressure1`)}
              {renderDetail(inspection.inspectionDetail['pressure2'], `${inspection.id}-pressure2`)}
              {renderDetail(inspection.inspectionDetail['pressure3'], `${inspection.id}-pressure3`)}
              {renderDetail(inspection.inspectionDetail['pressure4'], `${inspection.id}-pressure4`)}
              {renderDetail(inspection.inspectionDetail['pressure5'], `${inspection.id}-pressure5`)}
              {renderDetail(inspection.inspectionDetail['pressure6'], `${inspection.id}-pressure6`)}
              {renderDetail(inspection.inspectionDetail['pressuresMin'], `${inspection.id}-pressuresMin`)}
              {renderDetail(inspection.inspectionDetail['pressuresMax'], `${inspection.id}-pressuresMax`)}
              {renderDetail(inspection.inspectionDetail['pressuresAvg'], `${inspection.id}-pressuresAvg`)}
              {renderDetail(inspection.inspectionDetail['overallQ'], `${inspection.id}-overallQ`)}
              {renderDetail(inspection.inspectionDetail['overallC'], `${inspection.id}-overallC`)}
            </>
          )
        })}
      </Box>

    </Box>    
  )
}