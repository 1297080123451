import * as React from 'react'
import Box from '@mui/material/Box'
import { Alert, Button, LinearProgress, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { Download } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { SelectedFilters } from 'src/typeDefinitions/appTypes'
import { Filters } from 'src/components/Filters'
import { Pagination } from 'src/components/Pagination'
import { useGetHostedReportFilterOptionsQuery, useGetAllHostedReportsCutiesQuery, useDownloadFileMutation, FileType } from 'src/utils/__generated__/graphql'
import { useAppState } from 'src/hooks/useAppState'
import { useHostedReportQueryFilter } from '../useHostedReportQueryFilter'
import { useConvertRawHostedReportFiltersToFilterOptions } from '../useConvertRawHostedReportFiltersToFilterOptions'
import { ErrorAlert } from 'src/components/ErrorAlert'


export const HostedReportsInspectionDetailsRoute = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { seasonId } = useAppState()
  const [selectedFilters, setSelectedFilters] = React.useState<SelectedFilters>({
    locations: [],
    importers: [],
    exporters: [],
    arrivalNames: [],
    containers: [],
    lotCodes: [],
    pos: [],
    growers: [],
    statuses: [],
    programs: [],
  })   
  const { variables, filterOptionsVariables, maxPageSize, setPageFilter } = useHostedReportQueryFilter(selectedFilters, seasonId, 12, true)
  const { loading, error, data } = useGetAllHostedReportsCutiesQuery({
    variables: variables
  })
  const { loading: loadingFilters, error: filterError, data: filterData } = useGetHostedReportFilterOptionsQuery({
    variables: filterOptionsVariables
  })
  const filtersOptions = useConvertRawHostedReportFiltersToFilterOptions(filterData)  
  const [downloadFile, {loading: loadingDownloadFile, error: downloadFileError}] = useDownloadFileMutation()

  const handleFiltersSelected = (newSelectedFilters) => {
    setPageFilter({
      last: null,
      before: null,
      first: maxPageSize,
      after: null
    })
    setSelectedFilters(newSelectedFilters)
  }

  const handleDownloadSpreadsheetClick = async (fileType: FileType) => {
    const result = 
      await downloadFile({variables: {
        input: {
          fileType,
          filter: { hostedReportFilters: filterOptionsVariables.filterInput }
        }
      }})
      
      if (!result?.data?.downloadFile?.downloadRequest?.id) {
        // Request failed, we don't need to do anything here as error will be available on hook.
      }
      else {
        navigate('/account/my-downloads')
      }
  }
  
  return (
    <>
    
      <Paper
        elevation={0}
        sx={{ p: '1rem' }}
      >
        <Stack sx={{background: 'mainBackground.main'}}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent='space-between' sx={{paddingBottom: 2}} >
          
            <Typography variant='h6' sx={{flexGrow: 2}}>
              { t('HostedReportsInspectionDetails', 'Hosted reports - Inspection details') }
            </Typography>
            
          </Stack>

          <Filters 
            loading={loadingFilters}
            filtersOptions={filtersOptions} 
            filterUiData={[{
              title: t('Location', 'Location'),
              filterType: 'locations',
              filterComponentType: 'Standard'
            }, {
              title: t('Arrival', 'Arrival'),
              filterType: 'arrivals',
              filterComponentType: 'Standard'
            }, {
              title: t('Importer', 'Importer'),
              filterType: 'importers',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Exporter', 'Exporter'),
              filterType: 'exporters',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Container', 'Container'),
              filterType: 'containers',
              screenSize: 'sm',
              filterComponentType: 'Standard'            
            }, {
              title: t('PO', 'PO'),
              filterType: 'po',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Grower', 'Grower'),
              filterType: 'grower',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }, {
              title: t('Status', 'Status'),
              filterType: 'statuses',
              screenSize: 'sm',
              filterComponentType: 'Standard'
            }]}        
            selectedFilters={selectedFilters}
            onFiltersSelected={handleFiltersSelected}
          />
        </Stack>

      </Paper>
      
      <Paper
        elevation={0}
        sx={{ p: '1rem', backgroundColor: 'baseBackground.main' }}
      >
        
        { loading ? <LinearProgress />
        : !!(error || filterError) ? <ErrorAlert error={error || filterError} /> :

        <>
          <Box sx={{paddingBottom: '2rem'}}>
            <Typography variant='caption'> { t('Results', 'Results') }: {data?.hostedReports?.totalCount ?? 0}</Typography>
          </Box>
          
          
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{ t('Date', 'Date') }</TableCell>
                  <TableCell>{ t('CountryOfOrigin', 'CountryOfOrigin') }</TableCell>
                  <TableCell>{ t('Facility', 'Facility') }</TableCell>
                  <TableCell>{ t('Exporter', 'Exporter') }</TableCell>
                  <TableCell>{ t('PO', 'PO') }</TableCell>
                  <TableCell>{ t('Arrival', 'Arrival') }</TableCell>
                  <TableCell>{ t('Container', 'Container') }</TableCell>
                  <TableCell>{ t('Importer', 'Importer') }</TableCell>
                  <TableCell>{ t('Grower', 'Grower') }</TableCell>
                  <TableCell>{ t('Qty', 'Qty') }</TableCell>
                  <TableCell>{ t('Product', 'Product') }</TableCell>
                  <TableCell>{ t('Status', 'Status') }</TableCell>
                  <TableCell>{ t('Brix', 'Brix') }</TableCell>
                  <TableCell>{ t('Acid', 'Acid') }</TableCell>
                  <TableCell>{ t('Ratio', 'Ratio') }</TableCell>
                  <TableCell>{ t('OneSeedPCT', 'OneSeedPCT') }</TableCell>
                  <TableCell>{ t('TwoPlusSeedPCT', 'TwoPlusSeedPCT') }</TableCell>
                  <TableCell>{ t('InternalDefectsPCT', 'InternalDefectsPCT') }</TableCell>
                  <TableCell>{ t('ExternalDefectsPCT', 'ExternalDefectsPCT') }</TableCell>
                  <TableCell>{ t('TotalDefectsPCT', 'TotalDefectsPCT') }</TableCell>
                  <TableCell>{ t('US1Grade', 'US1Grade') }</TableCell>
                  <TableCell>{ t('RejectionReason1', 'RejectionReason1') }</TableCell>
                  <TableCell>{ t('RejectionReason2', 'RejectionReason2') }</TableCell>
                  <TableCell>{ t('RejectionReason3', 'RejectionReason3') }</TableCell>
                  <TableCell>{ t('RejectionReason4', 'RejectionReason4') }</TableCell>
                  <TableCell>{ t('RejectionReason5', 'RejectionReason5') }</TableCell>
                  <TableCell>{ t('RejectionReason6', 'RejectionReason6') }</TableCell>
                  <TableCell>{ t('RejectionReason7', 'RejectionReason7') }</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.hostedReports?.nodes?.map((row) => (
                  <TableRow
                    key={row?.hostedReportLinkId}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.createdOn?.slice(0, 10)}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.countryOfOrigin}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.facility?.facilityName}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.impExp.exporter.exporterName}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.po}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.arrivalName}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.container}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.impExp?.importer.importerName}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.grower}</TableCell>
                    <TableCell align='right'  sx={{whiteSpace: 'nowrap'}}>{row?.totalQty}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.product}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.hostedReportStatus}</TableCell>
                    <TableCell align='right'  sx={{whiteSpace: 'nowrap'}}>{row?.brix}</TableCell>
                    <TableCell align='right'  sx={{whiteSpace: 'nowrap'}}>{row?.acid}</TableCell>
                    <TableCell align='right'  sx={{whiteSpace: 'nowrap'}}>{row?.ratio}</TableCell>
                    <TableCell align='right'  sx={{whiteSpace: 'nowrap'}}>{row?.oneSeedPieces}</TableCell>
                    <TableCell align='right'  sx={{whiteSpace: 'nowrap'}}>{row?.twoPlusSeedPieces}</TableCell>
                    <TableCell align='right'  sx={{whiteSpace: 'nowrap'}}>{row?.internalDefectsPer}</TableCell>
                    <TableCell align='right'  sx={{whiteSpace: 'nowrap'}}>{row?.externalDefectsPer}</TableCell>
                    <TableCell align='right'  sx={{whiteSpace: 'nowrap'}}>{row?.totalDefects}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row.us1Grade ? 'Yes' : 'No'}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.rejectionDefects && 'Defects'}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.rejectionTotalSeeds && 'Total Seeds'}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.rejectionTwoPlusSeeds && 'Two Plus Seeds'}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.rejectionLowBrix && 'Low Brix'}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.rejectionHighAcidity && 'High Acidity'}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.rejectionLowAcidity && 'Low Acidity'}</TableCell>
                    <TableCell  sx={{whiteSpace: 'nowrap'}}>{row?.rejectionLowRatio && 'Low Ratio'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Pagination 
            maxPageSize={maxPageSize}
            totalPages={Math.ceil((data?.hostedReports?.totalCount ?? 0) / maxPageSize)}
            pageInfo={data?.hostedReports?.pageInfo}
            setPageFilter={setPageFilter}
          />

          { downloadFileError?.graphQLErrors != null &&
            <Alert severity="error">
              { t('Error', 'Error') }: {downloadFileError?.graphQLErrors?.map(e=>e.message)}
            </Alert>
          }

          <Stack mt={6} mb={6} direction={'row'} spacing={2}>
            <Button 
              variant='outlined'                 
              startIcon={<Download />} 
              size='large'
              disabled={loadingDownloadFile || (data?.hostedReports?.totalCount ?? 0) === 0}
              onClick={()=>{handleDownloadSpreadsheetClick(FileType.HostedReportInspectionDetailsSpreadsheet)}}
            >            
              Download spreadsheet (XLSX)            
            </Button>
          </Stack>
          
          
        </>
        }  
      </Paper>
    </>
  )
}